import { Box, Slider, sliderClasses, Typography } from "@mui/material";

interface Props {
  sections: Array<{ name: string; path: string }>;
  activePath: string;
}

function SectionNavigation({ sections, activePath }: Props) {
  const marks = sections.map((section, index) => ({
    label: section.name,
    value: index,
  }));

  const valuetext = (value: number) => marks[value]?.label ?? "";

  const activeSection = sections.findIndex((section) =>
    activePath.startsWith(section.path)
  );
  const activeLabel = sections[activeSection]?.name;

  return (
    <Box display="initial" data-testid="section navigation container">
      <Slider
        value={activeSection}
        onChange={() => {}}
        disabled={true}
        aria-label="Section Navigation"
        valueLabelDisplay="off"
        getAriaValueText={valuetext}
        step={null}
        marks={marks}
        min={0}
        max={marks.length - 1}
        sx={(theme) => ({
          mb: theme.spacing(8),
          [theme.breakpoints.down("lg")]: {
            mb: 0,
          },
          [`& .${sliderClasses.thumb}`]: {
            backgroundColor: "common.white",
            border: theme.spacing(0.625, "solid"),
            borderColor: "primary.main",
          },
          [`& .${sliderClasses.mark}`]: {
            borderRadius: theme.spacing(1.75),
            color: "common.blue",
            width: theme.spacing(1.25),
            height: theme.spacing(1.25),
          },
          [`& .${sliderClasses.markActive}`]: {
            width: theme.spacing(1.25),
            height: theme.spacing(1.25),
            backgroundColor: "primary.main",
          },
          [`& .${sliderClasses.rail}`]: {
            backgroundColor: "common.blue",
            opacity: "1.0",
            borderBlock: theme.spacing(1.25),
          },
          [`& .${sliderClasses.track}`]: {
            backgroundColor: "primary.main",
            borderBlock: theme.spacing(1.25),
          },
          [`& .${sliderClasses.markLabel}`]: {
            textTransform: "capitalize",
            whiteSpace: "normal",
            overflowWrap: "break-word",
            textAlign: "center",
            transform: "rotate(-35deg) translate(-50%, -50%)",
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          },
        })}
      />
      <Typography
        variant="body2"
        color="primary.main"
        sx={(theme) => ({ [theme.breakpoints.up("lg")]: { display: "none" } })}
      >
        {activeLabel}
      </Typography>
    </Box>
  );
}

export default SectionNavigation;
