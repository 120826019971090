import type { AxiosRequestConfig, CancelToken } from "axios";
import axios from "../libs/axios/axios-surveyresponses";
import type {
  CreateFunction,
  QueryFunction,
  QueryResult,
  ReadFunction,
  UpdateFunction,
} from "../types/API";
import { Control } from "../types/documents/Control";
import type {
  CreateSurveyResponse,
  EmailSurveyResponse,
  SurveyCompletionChartData,
  SurveyHistory,
  SurveyResponse,
} from "../types/documents/SurveyResponse";

type AnswerBody = { controls: Partial<Control>[] };

type QueryCompletionChartResult<D extends object> = {
  items: D[];
};

type QueryCompletionChartFunction<D extends object, B = {}> = (
  body: B,
  cancelToken?: CancelToken
) => Promise<QueryCompletionChartResult<D>>;

const userHistory: QueryFunction<SurveyHistory, { size: number }> = async (
  { size },
  cancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
    params: {
      size,
    },
  };
  const { data } = await axios.get<SurveyHistory[]>("/history", config);
  return {
    items: data,
    totalCount: data.length,
  };
};

const read: ReadFunction<SurveyResponse> = async (
  id,
  _childId,
  cancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}`;
  const { data } = await axios.get<SurveyResponse>(path, config);
  return {
    item: data,
  };
};

const getUserResponse: ReadFunction<SurveyResponse> = async (
  _id,
  _childId,
  cancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.get<SurveyResponse>("/", config);
  return {
    item: data,
  };
};

const update: UpdateFunction<SurveyResponse> = async () => {
  return Promise.resolve({
    id: "123",
  });
};

const submitResponse = async (id: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const completedDate = new Date().toISOString();
  const { data } = await axios.put<SurveyResponse>(
    `${id}`,
    { id, completedDate },
    config
  );
  return {
    item: data,
  };
};

const answerSubsection = async (
  id: string,
  sectionId: string,
  subsectionId: string,
  body: AnswerBody,
  cancelToken?: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/answer/${id}/${sectionId}/${subsectionId}`;
  const { data } = await axios.put<SurveyResponse>(path, body, config);
  return {
    item: data,
  };
};

const answerPart = async (
  id: string,
  sectionId: string,
  subsectionId: string,
  partId: string,
  body: any,
  cancelToken?: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/answer/${id}/${sectionId}/${subsectionId}/${partId}`;
  const { data } = await axios.put<SurveyResponse>(path, body, config);
  return {
    item: data,
  };
};

const create: CreateFunction<SurveyResponse> = async (_body, cancelToken?) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.post<CreateSurveyResponse>("/", {}, config);
  return {
    id: data.id,
    item: data.payload,
  };
};

const query: QueryFunction<SurveyResponse> = async (body, cancelToken?) => {
  const { data } = await axios.post<QueryResult<SurveyResponse>>(
    "/search",
    body,
    {
      cancelToken,
    }
  );
  return data;
};

const queryCompletionChart: QueryCompletionChartFunction<
  SurveyCompletionChartData
> = async (body, cancelToken?) => {
  const { data } = await axios.post<
    QueryCompletionChartResult<SurveyCompletionChartData>
  >("/charts/completion", body, {
    cancelToken,
  });
  return data;
};

const emailSurvey = async (id: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/send/${id}`;
  const { data } = await axios.get<EmailSurveyResponse>(path, config);
  return {
    item: data,
  };
};

export const api = {
  userHistory,
  read,
  update,
  create,
  getUserResponse,
  answerPart,
  answerSubsection,
  submitResponse,
  query,
  emailSurvey,
  queryCompletionChart,
};
