import type { AxiosRequestConfig, CancelToken } from "axios";
import axios from "../libs/axios/axios-userfeedback";
import { userFeedbackStrings } from "../resources/strings/userFeedback";
import type {
  CreateFunction,
  QueryFunction,
  QueryResult,
  ReadFunction,
  ReadResult,
  UpdateFunction,
} from "../types/API";
import {
  SendSupportOrFeedbackResponse,
  SupportOrFeedback,
} from "../types/documents/SupportOrFeedback";
import {
  CreateUserFeedbackResponse,
  UserFeedback,
} from "../types/documents/UserFeedback";

const read: ReadFunction<UserFeedback> = async (id, _childId, cancelToken?) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}`;
  const { data } = await axios.get<ReadResult<UserFeedback>>(path, config);
  const feedback: UserFeedback = {
    ...data.item,
    wantToBeContactedAnswer: data.item.wantToBeContacted
      ? userFeedbackStrings.contact.yesString
      : userFeedbackStrings.contact.noString,
  };
  return {
    ...data,
    item: feedback,
  };
};

const create: CreateFunction<UserFeedback> = async (
  { wantToBeContactedAnswer, ...body },
  cancelToken?
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const payload: UserFeedback = {
    ...body,
    wantToBeContacted:
      wantToBeContactedAnswer === userFeedbackStrings.contact.yesString,
  };
  const { data } = await axios.post<CreateUserFeedbackResponse>(
    "/",
    payload,
    config
  );
  return {
    id: data.id,
    item: data.payload,
  };
};

const query: QueryFunction<UserFeedback> = async (body, cancelToken?) => {
  const { data } = await axios.post<QueryResult<UserFeedback>>(
    "/search",
    body,
    {
      cancelToken,
    }
  );
  return data;
};

const update: UpdateFunction<UserFeedback> = async () =>
  Promise.resolve({
    id: "",
  });

const sendSupportOrFeedback = async (
  body: SupportOrFeedback,
  cancelToken?: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.post<SendSupportOrFeedbackResponse>(
    "/send",
    body,
    config
  );
  return {
    item: data,
  };
};

export const api = {
  read,
  create,
  query,
  update,
  sendSupportOrFeedback,
};
