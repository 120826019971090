import LinkIcon from "@mui/icons-material/Link";
import { Box, Stack, Typography } from "@mui/material";
import ActionCardContainer from "./ActionCardContainer";

import { SuggestionModal } from "../editModals";
import type { ActionCardType } from "./ActionCard";
import { ChangeHandler } from "./types/Modify";

export type ACProps = {
  cards: ActionCardType[];
  sectionTitle: string;
  collapsible?: boolean;
  icon?: boolean;
  path?: string;
  editable?: boolean;
  handleChange?: ChangeHandler;
};

type Component = (props: ACProps) => JSX.Element;

const actionsBoxStyling = {
  backgroundColor: "common.lightGrey",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const ActionsBox: Component = ({
  cards,
  sectionTitle,
  collapsible = false,
  icon = true,
  path,
  editable,
  handleChange,
}) => {
  return (
    <Box role="listbox" aria-label={`${sectionTitle}`} sx={[actionsBoxStyling]}>
      {icon && (
        <LinkIcon
          fontSize="large"
          sx={[{ pt: 2, color: "common.green" }]}
          role="img"
        />
      )}
      <Stack direction="row" spacing={1} sx={{ py: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{ color: "primary.main", fontWeight: "bold" }}
        >
          {sectionTitle}
        </Typography>
        {editable && handleChange && (
          <SuggestionModal
            key={cards.length}
            index={cards.length}
            path={path}
            handleChange={handleChange}
            addNew
          />
        )}
      </Stack>
      <ActionCardContainer
        cards={cards}
        collapsible={collapsible}
        path={path}
        editable={editable}
        handleChange={handleChange}
      />
    </Box>
  );
};

export default ActionsBox;
