import "@fontsource/lato";
import { colors, createTheme, responsiveFontSizes } from "@mui/material";

// Colours from TDL branding guide
const purple = "#380554"; // "Deep Purple"
const lightGrey = "#E7E7E7"; // "Soft Grey"
const pink = "#D02C6C"; // "Fushia" (sic)
const yellow = "#F4BA07"; // "Warm Yellow"
const grey = "#222222"; // "Soft Black"
const blue = "#3BC5BD"; // "Turqoiuse"

// Other colours
const green = "#3a9243";
const red = "#e41d25";
const midGrey = "#d8d8d8";

const primary = purple;
const secondary = pink;

// typography
const fontFamily = "'Lato', sans-serif";

let theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: colors.common.white,
    },
    secondary: {
      main: secondary,
      contrastText: colors.common.white,
    },
    common: {
      purple,
      yellow,
      pink,
      green,
      blue,
      red,
      grey,
      lightGrey,
      midGrey,
    },
    text: {
      secondary: grey,
    },
  },
  typography: {
    fontFamily,
    fontSize: 16,
    htmlFontSize: 16,
    fontWeightLight: 100,
    fontWeightMedium: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
      "@media (min-width:600px)": {
        minHeight: 80,
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };
