import { validateNotEmpty } from "../../helpers/validation-helpers";
import { questionOptionModalStrings as strings } from "../../resources/strings/editModal";
import { QuestionControl, QuestionOption } from "../../types/documents/Control";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { EditModal, EditModalControl } from "../general/EditModal";
import { IconButtonAdd, IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

interface Props {
  controlId: string;
  options: QuestionOption[];
  option?: QuestionOption;
  path: string;
  addNew?: boolean;
  handleChange: ChangeHandler;
}

type Component = (props: Props) => JSX.Element;

export const QuestionOptionModal: Component = ({
  controlId,
  options,
  option: initOption = {
    id: `${options.length + 1}`,
    value: `${options.length + 1}`,
    label: "",
    feedback: [],
  },
  path,
  handleChange,
  addNew,
}) => {
  const modalTitle = addNew ? strings.modalTitle.add : strings.modalTitle.edit;
  const trigger = addNew ? (
    <IconButtonAdd entity={strings.entity} />
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const handleEditQuestion = (updatedQuestion: Partial<QuestionControl>) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Control,
        actionType: ActionType.Update,
        value: updatedQuestion,
      },
    });
  };

  const handleEditAnswer = (option: QuestionOption) => {
    let newOptions;

    if (addNew) {
      newOptions = [...options, option];
    } else {
      newOptions = options.map((oldOption) => {
        if (oldOption.id !== option.id) return oldOption;
        return { ...oldOption, ...option };
      });
    }

    handleEditQuestion({ controlId, options: newOptions });
  };

  const handleDeleteAnswer = (option: QuestionOption) => {
    const newOptions = options
      .filter(({ id }) => id !== option.id)
      .map((option, index) => ({
        ...option,
        id: `${index + 1}`,
        value: `${index + 1}`,
      }));

    handleEditQuestion({ controlId, options: newOptions });
  };

  return (
    <EditModal<QuestionOption>
      title={modalTitle}
      controls={[
        {
          label: strings.answer,
          id: "label",
          validationFunction: validateNotEmpty,
          multiline: true,
        },
        {
          title: strings.feedback,
          baseId: "feedback",
          deletable: true,
          newInstance: {
            title: "",
            text: "",
            selected: false,
            suggestion: { title: "", description: "", image: "", url: "" },
          },
          fields: [
            {
              label: strings.title,
              id: "title",
            },
            {
              label: strings.text,
              id: "text",
              multiline: true,
            },
            {
              label: strings.selected,
              type: EditModalControl.Checkbox,
              id: "selected",
            },
            {
              label: strings.suggestionTitle,
              id: "suggestion.title",
            },
            {
              label: strings.suggestionDescription,
              id: "suggestion.description",
              multiline: true,
            },
            {
              label: strings.image,
              id: "suggestion.image",
              type: EditModalControl.ImageUpload,
            },
            {
              label: strings.url,
              id: "suggestion.url",
            },
          ],
        },
      ]}
      data={initOption}
      handleConfirm={handleEditAnswer}
      handleDelete={!addNew ? handleDeleteAnswer : undefined}
      trigger={trigger}
    />
  );
};
