import {
  ChevronLeft as ArrowBackIcon,
  ChevronRight as ArrowForwardIcon,
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionCardType } from "../../components/general/ActionCard";
import ActionsBox from "../../components/general/ActionsBox";
import { Button } from "../../components/general/Button";
import { Header } from "../../components/general/Header";
import { HomeButton } from "../../components/general/HomeButton";
import Modal from "../../components/general/Modal";
import SectionNavigation from "../../components/general/SectionNavigation";
import type {
  ComponentConfiguration,
  CustomProps,
  Mode,
} from "../../components/general/types/Modify";
import { PageLayoutBox } from "../../components/layout/PageLayoutBox";
import { logger } from "../../helpers/log-helpers";
import { changeState } from "../../helpers/surveyresponse-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useSectionJson } from "../../hooks/general/useSectionJson";
import { paths } from "../../navigation/paths";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import { api } from "../../services/surveyresponses.service";
import type { RouteProps } from "../../types";
import { Part, Subsection } from "../../types/documents";
import { SectionType } from "../../types/documents/BasePart";
import { Control, ControlType } from "../../types/documents/Control";
import { Suggestion } from "../../types/documents/Suggestion";
import {
  SurveyResponse,
  SurveyResponseStatus,
} from "../../types/documents/SurveyResponse";
import { ModifyContainer } from "../general/ModifyContainer";

const responsesApi = { ...api, read: api.getUserResponse };

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

const SectionHeader = ({ data: { sections } }: CustomProps<SurveyResponse>) => {
  const {
    longName: sectionHeader,
    name: pageHeader,
    description,
  } = useSectionJson(sections);
  let caption = "";
  if (description) caption = description;
  return (
    <Header
      sectionHeader={sectionHeader}
      pageHeader={pageHeader}
      caption={caption}
    />
  );
};

const Actions = ({
  data: { sections },
  currentFeedbackTitle,
}: CustomProps<SurveyResponse>) => {
  const { type, subsection, part } = useSectionJson(sections);

  if (!subsection) return null;

  const parseSuggestion = (suggestion: Suggestion) => ({
    title: suggestion.title,
    description: suggestion.description,
    url: suggestion.url,
    image: { src: suggestion.image, alt: suggestion.title },
  });
  let suggestions: ActionCardType[] = [];
  let current = part ? part : subsection;

  const getFeedbackSuggestion = (current: Subsection | Part) => {
    // require a different method of handling feedback suggestions as they're
    // not currently stored in subsection/part.suggestion but are attached to
    // feedback controls
    let feedback = current.controls.find(
      (control) => control.type === ControlType.Feedback
    );
    if (!feedback || feedback.type !== ControlType.Feedback) return;
    let allSuggestions = feedback.suggestions?.find((suggestionObj) =>
      currentFeedbackTitle?.includes(suggestionObj.title)
    );
    if (!allSuggestions || allSuggestions.suggestions?.length === 0) return;
    allSuggestions.suggestions.forEach((suggestion) =>
      suggestions.push(parseSuggestion(suggestion))
    );
  };

  let collapsible = true;
  let hasIcon = false;
  let sectionTitle = strings.actionsBox.questionTitle;

  switch (type) {
    case SectionType.Feedback:
      collapsible = false;
      hasIcon = true;
      sectionTitle = strings.actionsBox.feedbackTitle;
      getFeedbackSuggestion(current);
      break;
    case SectionType.Question:
      current.suggestions.forEach((suggestion) =>
        suggestions.push(parseSuggestion(suggestion))
      );
      break;
  }

  if (suggestions.length <= 0) return null;

  return (
    <ActionsBox
      sectionTitle={sectionTitle}
      cards={suggestions}
      collapsible={collapsible}
      icon={hasIcon}
      key={sectionTitle}
    />
  );
};

const Navigation = ({
  data: { id = "", sections },
  mode,
}: CustomProps<SurveyResponse>) => {
  const [isQuestionSkipped, setSkipped] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    next,
    back,
    canMoveBack,
    canMoveForward,
    isEndOfSurvey,
    uuids: { sectionId, subsectionId, partId },
    sectionStrings,
    controls,
    type,
    path,
  } = useSectionJson(sections);

  const isViewMode = mode === "view";

  const skipTrigger = <Button label={strings.labels.skip} color="secondary" />;
  const cancelToken = useCancelToken();

  const updatedControls: Partial<Control>[] = useMemo(() => {
    let tmpControls: Partial<Control>[] = [];
    controls.forEach((control) => {
      if (control.type === ControlType.Question) {
        tmpControls.push({
          controlId: control.controlId,
          type: control.type,
          value: control.value,
          skipped: control.value?.length === 0,
        });
      }
      if (control.type === ControlType.Feedback) {
        tmpControls.push({
          controlId: control.controlId,
          type: control.type,
          items: control.items,
        });
      }
    });
    return tmpControls;
    // eslint-disable-next-line
  }, [controls, isQuestionSkipped]);

  const handleSavePart = useCallback(async () => {
    return api.answerPart(
      id,
      sectionId,
      subsectionId,
      partId,
      { controls: updatedControls },
      cancelToken
    );
  }, [cancelToken, id, partId, sectionId, subsectionId, updatedControls]);

  const handleSaveSubsection = useCallback(async () => {
    return api.answerSubsection(
      id,
      sectionId,
      subsectionId,
      { controls: updatedControls },
      cancelToken
    );
  }, [cancelToken, id, sectionId, subsectionId, updatedControls]);

  const handleSave = async () => {
    next();

    setSkipped(!isQuestionSkipped);

    if (isViewMode) return;

    try {
      if (partId) {
        await handleSavePart();
      } else {
        await handleSaveSubsection();
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const handleSkip = async () => {
    next();

    setSkipped(!isQuestionSkipped);

    try {
      if (partId) {
        await handleSavePart();
      } else {
        await handleSaveSubsection();
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const handleSubmit = async () => {
    try {
      await api.submitResponse(id, cancelToken);
      next();
    } catch (e) {
      logger.error(e);
    }
  };

  const handlePause = async () => {
    try {
      if (partId) {
        await handleSavePart();
      } else {
        await handleSaveSubsection();
      }
      navigate(paths.landingPage);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleEnd = () => {
    navigate(paths.userFeedback.create(id));
  };

  useEffect(() => {
    if (type !== SectionType.Feedback || isViewMode) return;

    const saveFeedback = async () => {
      try {
        if (partId) {
          await handleSavePart();
        } else {
          await handleSaveSubsection();
        }
      } catch (e) {
        logger.error(e);
      }
    };
    saveFeedback();
  }, [type, isViewMode, partId, handleSavePart, handleSaveSubsection]);

  const backButton = (
    <Button
      aria-label={strings.ariaLabels.back}
      label={strings.labels.back}
      color="secondary"
      onClick={back}
      disabled={!canMoveBack}
      startIcon={<ArrowBackIcon />}
    />
  );
  const nextButton = (
    <Button
      aria-label={strings.ariaLabels.next}
      label={strings.labels.next}
      color="primary"
      onClick={handleSave}
      disabled={!canMoveForward}
      endIcon={<ArrowForwardIcon />}
    />
  );
  const submitSurveyButton = (
    <Button
      aria-label={strings.ariaLabels.submit}
      label={strings.labels.submit}
      color="primary"
      onClick={handleSubmit}
      endIcon={<ArrowForwardIcon />}
    />
  );
  const pauseButton = (
    <Button
      aria-label={strings.ariaLabels.pause}
      label={strings.labels.pause}
      color="secondary"
      onClick={handlePause}
    />
  );

  const finishButton = (
    <Button
      aria-label={strings.ariaLabels.finish}
      label={strings.labels.finish}
      color="primary"
      onClick={handleEnd}
      disabled={canMoveForward}
    />
  );

  const nav = <SectionNavigation sections={sectionStrings} activePath={path} />;

  const skip = (
    <Modal
      title={strings.skipModal.title}
      description={strings.skipModal.content}
      trigger={skipTrigger}
      handleConfirm={handleSkip}
      buttons={{
        confirmText: strings.skipModal.confirmText,
        declineText: strings.skipModal.declineText,
        props: { color: "secondary", variant: "outlined" },
      }}
    />
  );

  const homeButton = isViewMode ? <HomeButton /> : null;

  const showAnswerActions = !isViewMode && canMoveForward && !isEndOfSurvey;
  const showSkip = showAnswerActions;
  const showPause = showAnswerActions;

  let actionButton = nextButton;

  if (!isViewMode) {
    if (!canMoveForward) {
      actionButton = finishButton;
    } else if (isEndOfSurvey) {
      actionButton = submitSurveyButton;
    }
  }
  return (
    <Stack direction="column" spacing={4}>
      <Box sx={{ px: 4 }}>{nav}</Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={1}
      >
        <Stack direction="row" spacing={1}>
          {homeButton}
          {backButton}
        </Stack>
        <Stack direction="row" spacing={1}>
          {showPause && pauseButton}
          {showSkip && skip}
          {actionButton}
        </Stack>
      </Box>
    </Stack>
  );
};

// TODO: resolve type error
//@ts-ignore
const Configuration: ComponentConfiguration<SurveyResponse> = ({
  data: { id, sections },
}) => {
  const { controls, path } = useSectionJson(sections);
  // TODO: think about if it would be possible to add height to these rendered elements
  // so that any scrollbar is on the `question` part rather than the entire page
  const handleEmail = useCallback(async () => {
    if (!id) return;
    return api.emailSurvey(id);
  }, [id]);

  return [
    {
      key: "header",
      content: [
        {
          controltype: ControlType.Custom,
          Component: SectionHeader,
          md: 12,
        },
      ],
    },
    {
      key: "question",
      content: controls
        .filter((control) =>
          [
            ControlType.Question,
            ControlType.Feedback,
            ControlType.Video,
          ].includes(control.type)
        )
        .map(({ type, ...props }) => ({
          controltype: type,
          ...props,
          path,
          md: 12,
        })),
    },
    {
      key: "endpage",
      content: controls
        .filter((control) => [ControlType.EndPanel].includes(control.type))
        .map(({ type, ...props }) => {
          return {
            controltype: type,
            ...props,
            handleEmail,
            id,
            path,
            md: 12,
          };
        }),
    },
    {
      key: "actions",
      content: [
        {
          controltype: ControlType.Custom,
          Component: Actions,
          md: 12,
        },
      ],
    },
    {
      key: "navigation",
      content: [
        {
          controltype: ControlType.Custom,
          Component: Navigation,
          md: 12,
        },
      ],
    },
  ];
};

export const SurveyResponsePage: Component = ({ mode, ...routeProps }) => {
  const isUpdateMode = mode === "update";
  return (
    <PageLayoutBox>
      <ModifyContainer<SurveyResponse>
        {...routeProps}
        api={isUpdateMode ? responsesApi : api}
        componentConfiguration={Configuration}
        constraints={{}}
        initialData={{
          sections: [],
          surveyId: "",
          userId: "",
          user: {
            id: "",
            created: "",
            updated: "",
            forename: "",
            surname: "",
            cognitoId: "",
            roleId: "",
            email: "",
            username: "",
          },
          status: SurveyResponseStatus.InProgress,
        }}
        mode={mode}
        redirectPath=""
        id={isUpdateMode ? "user" : undefined}
        changeState={changeState}
      />
    </PageLayoutBox>
  );
};
