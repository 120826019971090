import type { ChartData, ChartOptions } from "chart.js";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { surveyResponseStrings } from "../../resources/strings/surveyResponses";
import { api } from "../../services/surveyresponses.service";
import { theme } from "../../styles/theme";
import { SurveyChartFilters } from "../../types/documents/SurveyResponse";
import { Loader } from "../general/Loader";
import { SearchData, SurveyChartSearchBar } from "./SurveyChartSearchBar";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options: ChartOptions<"bar"> = {
  indexAxis: "y" as const,
  responsive: true,
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: surveyResponseStrings.completionChart.title,
      align: "start",
      font: { size: 16 },
    },
    legend: {
      display: false,
    },
  },
};

export const parseSearchFilters = (
  filters: Partial<SearchData>
): Partial<SurveyChartFilters> => {
  let result: Partial<SurveyChartFilters> = {};

  if (filters.createdFrom || filters.createdTo) {
    result.created = {
      from: filters.createdFrom,
      to: filters.createdTo,
    };
  }

  return result;
};

interface Props {}

type Component = (props: Props) => JSX.Element;

export const SurveyCompletionChart: Component = () => {
  const [chartData, setChartData] = useState<
    ChartData<"bar", number[], string>
  >({
    labels: [],
    datasets: [],
  });
  const cancelToken = useCancelToken();
  const [loading, setLoading] = useState(() => false);
  const [filters, setFilters] = useState<Partial<SearchData>>(() => ({}));

  const clearFilters = () => setFilters({});

  const onFilterChange = (searchData: Partial<SearchData>) => {
    setFilters(searchData);
  };

  useEffect(() => {
    const body: {
      query?: object;
    } = {};

    const query = async () => {
      setLoading(true);
      try {
        if (filters) {
          const parsedFilters = parseSearchFilters(filters);
          body.query = { ...parsedFilters };
        }
        const { items } = await api.queryCompletionChart(body, cancelToken);

        const graphData = {
          labels: items.map((obj) => obj.label),
          datasets: [
            {
              data: items.map((obj) => obj.value),
              backgroundColor: theme.palette.common.pink,
              barThickness: 45,
            },
          ],
        };

        setChartData(graphData);
        setLoading(false);
      } catch (e) {
        if (cancelToken.reason) return;
        setLoading(false);
      }
    };

    query();
  }, [cancelToken, filters]);

  return (
    <Loader active={loading}>
      <>
        <SurveyChartSearchBar
          filters={filters}
          onFilterChange={onFilterChange}
          onReset={clearFilters}
        />
        <Bar options={options} data={chartData} />
      </>
    </Loader>
  );
};
