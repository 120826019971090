import { Box } from "@mui/material";
import { Loader } from "../components/general/Loader";
import { useAuth } from "../hooks/authentication/useAuth";
import { useUserPermissions } from "../hooks/permissions/queries/useUserPermissions";
import { Routes } from "../navigation/routes";

const Wrapper: React.FC = ({ children }) => (
  <Box
    sx={[
      (theme) => ({
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          height: "100vh",
          width: "100vw",
        },
      }),
    ]}
  >
    {children}
  </Box>
);

const App = () => {
  const { user, checkedAuth } = useAuth();
  const { permissions, loading: loadingPermissions } = useUserPermissions(user);

  const isLoading = [!checkedAuth, loadingPermissions].some(
    (condition) => condition
  );
  if (isLoading) {
    return (
      <Wrapper>
        <Loader active />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Routes user={user} permissions={permissions} />
    </Wrapper>
  );
};

export default App;
