// Cognito config
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
export const COGNITO_SIGNIN_URL = process.env.REACT_APP_COGNITO_SIGNIN_URL;
export const COGNITO_SIGNOUT_URL = process.env.REACT_APP_COGNITO_SIGNOUT_URL;

// Endpoints
export const PERMISSIONS_ENDPOINT = `${process.env.REACT_APP_PERMISSIONS_ENDPOINT}/permissions`;
export const USERS_ENDPOINT = `${process.env.REACT_APP_USERS_ENDPOINT}/users`;
export const SURVEY_RESPONSES_ENDPOINT = `${process.env.REACT_APP_SURVEY_RESPONSES_ENDPOINT}/responses`;
export const SURVEYS_ENDPOINT = `${process.env.REACT_APP_SURVEYS_ENDPOINT}/surveys`;
export const FEEDBACK_ENDPOINT = `${process.env.REACT_APP_FEEDBACK_ENDPOINT}/feedback`;
export const WEBSOCKET_ENDPOINT = process.env.REACT_APP_WEBSOCKET_ENDPOINT;

// App config
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
