export const userStrings = {
  header: {
    users: "Users",
    userDetails: "User Details",
    signInInfo: "Sign-In Information",
    userInfo: "User Information",
  },
  labels: {
    username: "Username",
    email: "Email address",
    roleId: "User Role",
    forename: "Forename",
    surname: "Surname",
    created: "Created",
    updated: "Updated",
    createUser: "New User",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
  },
  ariaLabels: {
    usersTable: "users table",
  },
};
