import { Grid, GridProps, Stack } from "@mui/material";
import { useForm } from "../../hooks/general/useForm";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { DatePicker } from "../general/controls/DatePicker";
import { Link } from "../general/controls/Link";
import { SearchBar } from "../general/SearchBar";

export interface Props {
  filters: Partial<SearchData>;
  onFilterChange: (data: Partial<SearchData>) => void;
  onReset: () => void;
}
type Component = (props: Props) => JSX.Element;

export type SearchData = {
  createdFrom: string;
  createdTo: string;
};

const SearchField = ({ children, ...props }: GridProps) => {
  const { xs = true } = props;
  return (
    <Grid xs={xs} {...props} item>
      <BaseControl control={false}>
        <>{children}</>
      </BaseControl>
    </Grid>
  );
};

export const SurveyChartSearchBar: Component = ({
  filters,
  onFilterChange,
  onReset,
}) => {
  const [formData, handleChange, reset] = useForm<Partial<SearchData>>(filters);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onFilterChange(formData);
  };

  const handleReset = () => {
    reset();
    onReset();
  };

  return (
    <SearchBar onSubmit={handleSubmit}>
      <SearchField>
        <Stack
          direction="row"
          spacing={1.5}
          justifyContent="flex-end"
          alignItems="center"
        >
          <DatePicker<SearchData>
            config={{
              name: "createdFrom",
              label: strings.labels.createdFrom,
              value: formData.createdFrom ?? "",
            }}
            handleChange={handleChange}
          />
          <DatePicker<SearchData>
            config={{
              name: "createdTo",
              label: strings.labels.createdTo,
              value: formData.createdTo ?? "",
            }}
            handleChange={handleChange}
          />
          <Button type="submit">{strings.labels.search}</Button>
          <Link
            config={{
              text: strings.labels.reset,
              variant: "body2",
              onClick: handleReset,
            }}
          />
        </Stack>
      </SearchField>
    </SearchBar>
  );
};
