import type { SxProps, Theme } from "@mui/material";
import { FormControl } from "@mui/material";

interface Props {
  children: JSX.Element | null;
  control?: boolean;
}
type Component = (props: Props) => JSX.Element;

const controlStyles: SxProps<Theme> = {
  my: "0.5rem",
};

export const BaseControl: Component = ({ children, control = true }) => {
  return (
    <FormControl sx={[control && controlStyles]} fullWidth color="primary">
      {children}
    </FormControl>
  );
};
