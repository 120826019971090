import { Grid, GridProps, Stack } from "@mui/material";
import { useForm } from "../../hooks/general/useForm";
import { statusOptions } from "../../resources/dropdownOptions/surveyResponse";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { DatePicker } from "../general/controls/DatePicker";
import { Dropdown } from "../general/controls/Dropdown";
import { Input } from "../general/controls/Input";
import { Link } from "../general/controls/Link";
import { SearchBar } from "../general/SearchBar";

export interface Props {
  filters: Partial<SearchData>;
  onFilterChange: (data: Partial<SearchData>) => void;
  onReset: () => void;
}
type Component = (props: Props) => JSX.Element;

export type SearchData = {
  status: string;
  createdFrom: string;
  createdTo: string;
  updatedFrom: string;
  updatedTo: string;
  completedFrom: string;
  completedTo: string;
  email: string;
};

const SearchField = ({ children, ...props }: GridProps) => {
  const { xs = true } = props;
  return (
    <Grid xs={xs} {...props} item>
      <BaseControl control={false}>
        <>{children}</>
      </BaseControl>
    </Grid>
  );
};

export const SurveyResponseSearchBar: Component = ({
  filters,
  onFilterChange,
  onReset,
}) => {
  const [formData, handleChange, reset] = useForm<Partial<SearchData>>(filters);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onFilterChange(formData);
  };

  const handleReset = () => {
    reset();
    onReset();
  };

  return (
    <SearchBar
      onSubmit={handleSubmit}
      ariaLabel={strings.ariaLabels.responseListSearchBar}
    >
      <SearchField>
        <Stack spacing={1.5}>
          <Dropdown<SearchData>
            config={{
              name: "status",
              label: "Status",
              options: statusOptions,
              value: formData.status ?? "",
            }}
            handleChange={handleChange}
          />
          <Input<SearchData>
            config={{
              name: "email",
              label: strings.labels.user,
              value: formData.email ?? "",
            }}
            handleChange={handleChange}
          />
        </Stack>
      </SearchField>
      <SearchField>
        <Stack spacing={1.5}>
          <DatePicker<SearchData>
            config={{
              name: "createdFrom",
              label: strings.labels.createdFrom,
              value: formData.createdFrom ?? "",
            }}
            handleChange={handleChange}
          />
          <DatePicker<SearchData>
            config={{
              name: "createdTo",
              label: strings.labels.createdTo,
              value: formData.createdTo ?? "",
            }}
            handleChange={handleChange}
          />
        </Stack>
      </SearchField>
      <SearchField>
        <Stack spacing={1.5}>
          <DatePicker<SearchData>
            config={{
              name: "updatedFrom",
              label: strings.labels.updatedFrom,
              value: formData.updatedFrom ?? "",
            }}
            handleChange={handleChange}
          />
          <DatePicker<SearchData>
            config={{
              name: "updatedTo",
              label: strings.labels.updatedTo,
              value: formData.updatedTo ?? "",
            }}
            handleChange={handleChange}
          />
        </Stack>
      </SearchField>
      <SearchField>
        <Stack spacing={1.5}>
          <DatePicker<SearchData>
            config={{
              name: "completedFrom",
              label: strings.labels.completedFrom,
              value: formData.completedFrom ?? "",
            }}
            handleChange={handleChange}
          />
          <DatePicker<SearchData>
            config={{
              name: "completedTo",
              label: strings.labels.completedTo,
              value: formData.completedTo ?? "",
            }}
            handleChange={handleChange}
          />
        </Stack>
      </SearchField>
      <SearchField xs={1}>
        <Button type="submit">{strings.labels.search}</Button>
      </SearchField>
      <SearchField xs={0.5}>
        <Link
          config={{
            text: strings.labels.reset,
            variant: "body2",
            onClick: handleReset,
          }}
        />
      </SearchField>
    </SearchBar>
  );
};
