export const appStrings = {
  menuLabels: {
    dashboard: "Dashboard",
    users: "Users",
    settings: "Settings",
    help: "Help",
  },
  labels: {
    signOut: "Sign Out",
    signIn: "Login",
    loading: "Loading...",
    adminPanel: "Admin Panel",
    surveyResponses: "Self-Assessment Responses",
    users: "Manage Users",
    feedback: "User Feedback",
    defaultSelect: "- select -",
    home: "Home",
  },
  headers: {
    administration: "Administration",
  },
  ariaLabels: {
    itemNavigation: "Side navigation",
    firstPage: "first page",
    prevPage: "previous page",
    nextPage: "next page",
    lastPage: "last page",
    home: "home",
  },
};
