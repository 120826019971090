import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, IconButton, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import ActionCard, { ActionCardType } from "./ActionCard";
import { ChangeHandler } from "./types/Modify";

type ActionContainerProps = {
  cards: ActionCardType[];
  collapsible: boolean;
  path?: string;
  editable?: boolean;
  handleChange?: ChangeHandler;
};

type Component = (props: ActionContainerProps) => JSX.Element;

const gridStyles: SxProps = {
  width: "90%",
  alignItems: "start",
  display: "flex",
};

const expandIconStyles: SxProps = {
  color: "common.pink",
};
const ActionCardContainer: Component = ({
  cards,
  collapsible,
  path,
  editable,
  handleChange,
}) => {
  const [expanded, setExpanded] = useState(!collapsible || editable);

  const handleClick = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      if (collapsible) setExpanded(false);
    };
  }, [cards, collapsible]);

  return (
    <Grid
      container
      role="listbox"
      aria-label="cards container"
      justifyContent="space-around"
      sx={[gridStyles]}
    >
      {collapsible && (
        <Grid item xs={12} sx={[{ pb: 1 }]}>
          {!expanded ? (
            <IconButton
              role="button"
              aria-label="expand more"
              onClick={handleClick}
            >
              <ExpandMoreIcon sx={[expandIconStyles]} fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
              role="button"
              aria-label="expand less"
              onClick={handleClick}
            >
              <ExpandLessIcon sx={[expandIconStyles]} fontSize="large" />
            </IconButton>
          )}
        </Grid>
      )}
      {expanded &&
        cards.map((card, index) => (
          <Grid
            item
            key={(card.title ?? "") + index}
            role="listitem"
            xs={12}
            sm={6}
            md={3}
            sx={[{ py: 1 }]}
          >
            <ActionCard
              {...card}
              index={index}
              path={path}
              editable={editable}
              handleChange={handleChange}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default ActionCardContainer;
