import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { UserAccess } from "../../types";

type IconType =
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
  | (() => JSX.Element);

export interface MenuItem {
  label: string;
  Icon: IconType;
  itemPermission?: UserAccess;
  link: string;
}

export const menuItems: MenuItem[] = [];
