import { ButtonProps, Button as MuiButton, buttonClasses } from "@mui/material";

import type { SxProps, Theme } from "@mui/material";

const rootStyles: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up("md")]: {
    px: 3,
    py: 1.5,
  },
  fontWeight: "bold",
  borderRadius: 0,
  [`&.${buttonClasses.outlined}`]: {
    border: theme.spacing(0.25, "solid"),
  },
  [`&.${buttonClasses.disabled}`]: {
    opacity: "50%",
  },
  textTransform: "initial",
});

const disabledPrimaryStyles: SxProps<Theme> = {
  [`&.${buttonClasses.disabled}`]: {
    [`&.${buttonClasses.outlined}`]: {
      backgroundColor: "common.white",
      color: "primary.main",
    },
    [`&.${buttonClasses.contained}, .${buttonClasses.text}`]: {
      color: "common.white",
      backgroundColor: "primary.main",
    },
  },
};

const disabledSecondaryStyles: SxProps<Theme> = {
  [`&.${buttonClasses.disabled}`]: {
    [`&.${buttonClasses.outlined}`]: {
      backgroundColor: "common.white",
      color: "secondary.main",
    },
    [`&.${buttonClasses.contained}, .${buttonClasses.text}`]: {
      color: "common.white",
      backgroundColor: "secondary.main",
    },
  },
};

interface Props extends ButtonProps {
  label?: string;
  // even though these are properties of MuiButton, if not included here a TS error is thrown
  // https://github.com/mui/material-ui/issues/15827
  component?: React.ElementType;
  to?: string;
}

type Component = (props: Props) => JSX.Element;

export const Button: Component = ({ label, ...props }) => {
  const buttonProps: ButtonProps = {
    color: "primary",
    variant: "contained",
    disableElevation: true,
    children: label,
    ...props,
  };
  return (
    <MuiButton
      {...buttonProps}
      sx={[
        rootStyles,
        buttonProps.color === "primary" && disabledPrimaryStyles,
        buttonProps.color === "secondary" && disabledSecondaryStyles,
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};
