import type { SxProps, Theme } from "@mui/material";
import { TextField } from "@mui/material";
import type { ChangeHandler, FormInput } from "../types/Modify";

interface Props<D extends object> {
  config: Omit<FormInput<D>, "controltype">;
  handleChange: ChangeHandler;
}

const selectStyles: SxProps<Theme> = {
  textAlign: "left",
};

export const Input = <D extends object>({
  config,
  handleChange,
}: Props<D>): JSX.Element => {
  const inputConfig = Object.assign({}, config);
  return (
    <TextField
      size="small"
      {...inputConfig}
      onChange={handleChange}
      InputLabelProps={{
        htmlFor: config.name,
      }}
      InputProps={{
        id: config.name,
        title: config.name,
      }}
      SelectProps={{
        ...inputConfig.SelectProps,
        sx: [selectStyles],
      }}
    />
  );
};
