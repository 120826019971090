import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { logger } from "../../helpers/log-helpers";
import { useWebsocket } from "../../hooks/general/useWebsocket";
import { WEBSOCKET_ENDPOINT } from "../../libs/config";
import { ControlType } from "../../types/documents/Control";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { EndPanelModal } from "../editModals";
import { Button } from "./Button";
import { Loader } from "./Loader";
import { ChangeHandler } from "./types/Modify";

const headingProps: SxProps<Theme> = () => {
  return {
    color: "primary.main",
    fontWeight: "bold",
  };
};

const textProps: SxProps<Theme> = () => {
  return {
    color: "primary.main",
  };
};

type EmailHandler = () => void;

export type endPanelProps = {
  controlId: string;
  heading: string;
  body: string;
  emailButtonLabel: string;
  emailSuccess: string;
  downloadButtonLabel: string;
  path: string;
  handleChange: ChangeHandler;
  handleEmail: EmailHandler;
  id: string;
  editable?: boolean;
};

type Component = (props: endPanelProps) => JSX.Element;

export const EndPanel: Component = ({
  controlId,
  heading,
  body,
  emailButtonLabel,
  emailSuccess,
  downloadButtonLabel,
  handleChange,
  handleEmail,
  id,
  path,
  editable,
}) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  const [downloadResponse] = useWebsocket<{ url: string }>(
    WEBSOCKET_ENDPOINT,
    () => {
      setLinkLoading(true);
      return {
        action: "subscribe",
        data: {
          entity: "surveyResponses",
          service: "websocket",
          eventType: "download",
          payload: { id: "" },
          Authorization: "",
        },
      };
    },
    (message) => {
      setLinkLoading(false);

      const { url } = message;
      if (!url) return;

      window.open(url, "_parent");
    },
    (error) => {
      setLinkLoading(false);

      console.error(error);
    }
  );

  const handleButton = async () => {
    try {
      handleEmail();
      setEmailSubmitted(true);
    } catch (e) {
      logger.error(e);
    }
  };

  const handleDownloadButton = (id: string) => {
    downloadResponse(id);
  };

  return (
    <>
      <Box
        display="flex"
        alignContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" sx={[headingProps]}>
            {heading}
          </Typography>
          <ShowIf show={editable}>
            <EndPanelModal
              path={path}
              endPanel={{
                controlId,
                type: ControlType.EndPanel,
                body,
                heading,
                emailButtonLabel,
                emailSuccess,
                downloadButtonLabel,
              }}
              handleChange={handleChange}
            />
          </ShowIf>
        </Stack>
        <Typography variant="body2" sx={[textProps]}>
          {body}
        </Typography>
        <Stack sx={[{ my: 2 }]} direction="row" spacing={1}>
          <Button
            variant="contained"
            label={emailButtonLabel}
            aria-label="email button"
            onClick={handleButton}
            disabled={emailSubmitted}
          />
          <Button
            variant="contained"
            aria-label="download button"
            onClick={() => {
              handleDownloadButton(id);
            }}
          >
            <Loader active={linkLoading} inline>
              <>{downloadButtonLabel}</>
            </Loader>
          </Button>
        </Stack>
      </Box>
      {(emailSubmitted || editable) && (
        <Typography sx={[textProps]}>{emailSuccess}</Typography>
      )}
    </>
  );
};
