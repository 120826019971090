import type { GridProps } from "@mui/material";
import { Grid } from "@mui/material";
import { useForm } from "../../hooks/general/useForm";
import {
  contactOptions,
  ratingOptions,
} from "../../resources/dropdownOptions/userFeedback";
import { userFeedbackStrings as strings } from "../../resources/strings/userFeedback";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { DatePicker } from "../general/controls/DatePicker";
import { Dropdown } from "../general/controls/Dropdown";
import { Input } from "../general/controls/Input";
import { Link } from "../general/controls/Link";
import { SearchBar } from "../general/SearchBar";

export interface Props {
  filters: Partial<SearchData>;
  onFilterChange: (data: Partial<SearchData>) => void;
  onReset: () => void;
}
type Component = (props: Props) => JSX.Element;

export type SearchData = {
  rating: string;
  wantToBeContacted: string;
  from: string;
  to: string;
  email: string;
};

const SearchField = ({ children, ...props }: GridProps) => {
  const { xs = true } = props;
  return (
    <Grid xs={xs} {...props} item>
      <BaseControl control={false}>
        <>{children}</>
      </BaseControl>
    </Grid>
  );
};

export const UserFeedbackSearchBar: Component = ({
  filters,
  onFilterChange,
  onReset,
}) => {
  const [formData, handleChange, reset] = useForm<Partial<SearchData>>(filters);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onFilterChange(formData);
  };

  const handleReset = () => {
    reset();
    onReset();
  };

  return (
    <SearchBar onSubmit={handleSubmit}>
      <SearchField>
        <Dropdown<SearchData>
          config={{
            name: "rating",
            label: strings.labels.rating,
            options: ratingOptions,
            value: formData.rating ?? "",
          }}
          handleChange={handleChange}
        />
      </SearchField>
      <SearchField>
        <Input<SearchData>
          config={{
            name: "email",
            label: strings.labels.user,
            value: formData.email ?? "",
          }}
          handleChange={handleChange}
        />
      </SearchField>
      <SearchField>
        <Dropdown<SearchData>
          config={{
            name: "wantToBeContacted",
            label: strings.labels.wantToBeContacted,
            options: contactOptions,
            value: formData.wantToBeContacted ?? "",
          }}
          handleChange={handleChange}
        />
      </SearchField>
      <SearchField>
        <DatePicker<SearchData>
          config={{
            name: "from",
            label: strings.labels.from,
            value: formData.from ?? "",
          }}
          handleChange={handleChange}
        />
      </SearchField>
      <SearchField>
        <DatePicker<SearchData>
          config={{
            name: "to",
            label: strings.labels.to,
            value: formData.to ?? "",
          }}
          handleChange={handleChange}
        />
      </SearchField>
      <SearchField xs={1}>
        <Button type="submit">{strings.labels.search}</Button>
      </SearchField>
      <SearchField xs={0.5}>
        <Link
          config={{
            text: strings.labels.reset,
            variant: "body2",
            onClick: handleReset,
          }}
        />
      </SearchField>
    </SearchBar>
  );
};
