import type { Mode } from "../../components/general/types/Modify";
import { useRoles } from "../../hooks/permissions/queries/useRoles";
import { paths } from "../../navigation/paths";
import { userStrings as strings } from "../../resources/strings/users";
import { api } from "../../services/users.service";
import { RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { ControlType } from "../../types/documents/Control";
import { Entities } from "../../types/enums/Entities";
import { Permissions } from "../../types/enums/Permissions";
import { ModifyContainer } from "../general/ModifyContainer";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

const constraints = {
  username: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.username} is required`,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.email} is required`,
    },
  },
  roleId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.roleId} is required`,
    },
  },
};

export const UserModify: Component = ({ mode, ...routeProps }) => {
  const [roles, loading] = useRoles();

  return (
    <ModifyContainer<UserData>
      {...routeProps}
      mode={mode}
      api={api}
      initialData={{
        id: "",
        username: "",
        email: "",
        roleId: "",
        forename: "",
        surname: "",
        created: "",
        updated: "",
        cognitoId: "",
      }}
      componentConfiguration={[
        {
          key: "details",
          content: [
            {
              controltype: ControlType.Header,
              text: strings.header.userDetails,
              align: "left",
              variant: "h4",
            },
            {
              controltype: ControlType.Header,
              text: strings.header.signInInfo,
              align: "left",
              variant: "h6",
            },
            {
              controltype: ControlType.Input,
              name: "username",
              label: strings.labels.username,
              required: true,
              disabled: mode !== "create",
            },
            {
              controltype: ControlType.Input,
              name: "email",
              label: strings.labels.email,
              required: true,
            },
            {
              controltype: ControlType.Header,
              text: strings.header.userInfo,
              align: "left",
              variant: "h6",
            },
            {
              controltype: ControlType.Dropdown,
              name: "roleId",
              label: strings.labels.roleId,
              options: roles,
              required: true,
            },
            {
              controltype: ControlType.Input,
              name: "forename",
              label: strings.labels.forename,
            },
            {
              controltype: ControlType.Input,
              name: "surname",
              label: strings.labels.surname,
            },
          ],
        },
        {
          key: "submitButtons",
          modes: ["create", "update"],
          content: [
            {
              controltype: ControlType.Button,
              label: strings.labels.save,
              color: "primary",
              variant: "contained",
            },
            {
              controltype: ControlType.Button,
              label: strings.labels.reset,
              color: "primary",
              variant: "outlined",
              type: "reset",
            },
          ],
        },
        {
          key: "editButton",
          userAccess: {
            entity: Entities.Users,
            permission: Permissions.Update,
          },
          content: [
            {
              controltype: ControlType.Button,
              modes: ["view"],
              label: strings.labels.edit,
              color: "primary",
              variant: "contained",
              type: "button",
              buttonaction: "switch",
            },
          ],
        },
      ]}
      constraints={constraints}
      redirectPath={paths.users.view}
      loading={loading}
    />
  );
};
