import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageLayoutBox } from "../../components/layout/PageLayoutBox";
import type { SearchData } from "../../components/userFeedback/UserFeedbackSearchBar";
import { UserFeedbackSearchBar } from "../../components/userFeedback/UserFeedbackSearchBar";
import { formatDateTime } from "../../helpers/date-helpers";
import { findValue } from "../../helpers/dropdown-helpers";
import { paths } from "../../navigation/paths";
import { ratingOptions } from "../../resources/dropdownOptions/userFeedback";
import { userFeedbackStrings as strings } from "../../resources/strings/userFeedback";
import { api } from "../../services/userfeedback.service";
import type { RouteProps } from "../../types";
import { UserData } from "../../types/documents";
import { UserFeedback } from "../../types/documents/UserFeedback";
import { TableContainer } from "../general/TableContainer";

export interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

type UserFeedbackFilters = {
  user: Pick<UserData, "email">;
  created: { from?: string; to?: string };
  rating: string;
  wantToBeContacted: boolean;
};

export const parseSearchFilters = (
  filters: Partial<SearchData>
): Partial<UserFeedbackFilters> => {
  let result: Partial<UserFeedbackFilters> = {};

  if (filters.from || filters.to) {
    let from;
    let to;
    if (filters.from) from = filters.from;
    if (filters.to) to = filters.to;
    result.created = { from, to };
  }
  if (filters.email) {
    result.user = { email: filters.email };
  }
  if (filters.rating) {
    result.rating = filters.rating;
  }
  if (filters.wantToBeContacted !== undefined) {
    result.wantToBeContacted =
      filters.wantToBeContacted === strings.contact.yesString;
  }

  return result;
};

export const UserFeedbackList: Component = ({ ...routeProps }) => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Partial<SearchData>>(() => ({}));
  const parsedFilters = parseSearchFilters(filters);

  const clickHandler = ({ id }: UserFeedback) => {
    if (!id) return;
    navigate(paths.userFeedback.view(id));
  };

  const clearFilters = () => setFilters({});

  const onFilterChange = (searchData: Partial<SearchData>) => {
    setFilters(searchData);
  };

  return (
    <PageLayoutBox>
      <TableContainer<UserFeedback>
        {...routeProps}
        manualPagination
        api={api}
        tableColumns={[
          {
            id: "userId",
            Header: strings.labels.user,
            accessor: ({ user = {} }) => user.email,
          },
          {
            id: "created",
            Header: strings.labels.created,
            accessor: ({ created }) => formatDateTime(created || ""),
          },
          {
            id: "wantToBeContacted",
            Header: strings.labels.wantToBeContacted,
            accessor: ({ wantToBeContacted }) =>
              wantToBeContacted
                ? strings.contact.yesString
                : strings.contact.noString,
          },
          {
            id: "rating",
            Header: strings.labels.rating,
            accessor: ({ rating }) => findValue(rating, ratingOptions),
          },
        ]}
        header={strings.headers.userFeedback}
        hasHomeButton
        clickHandler={clickHandler}
        filters={parsedFilters}
        ariaLabel={strings.ariaLabels.userFeedbackTable}
        defaultSort={[{ id: "created", desc: true }]}
      >
        {[
          <UserFeedbackSearchBar
            filters={filters}
            onFilterChange={onFilterChange}
            onReset={clearFilters}
          />,
        ]}
      </TableContainer>
    </PageLayoutBox>
  );
};
