export const authStrings = {
  challenges: {
    newPassword: "NEW_PASSWORD_REQUIRED",
  },
  errors: {
    signIn: "Could not sign in.",
    signUp: "Could not sign up.",
    changePassword: "Could not update password.",
  },
  headers: {
    login: "Log in",
    changePassword: "Change password",
    completePassword: "Complete password reset",
    signUp: "Sign up",
  },
  labels: {
    username: "Username",
    email: "Email",
    password: "Password",
    login: "Log in",
    resetPassword: "Reset your password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    confirm: "Confirm",
    signingIn: "Signing in...",
    signingUp: "Signing up...",
    code: "Code",
    submit: "Submit",
    signUp: "Sign up",
    returnToLogin: "Return to login",
    termsAndConditions: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
  },
  text: {
    receiveCode: "Enter your username below to receive a confirmation code.",
    signUpCodeSent:
      "We have sent a code to your inbox. Enter it below to confirm your account.",
    legal: `\u00A9 ${new Date().getFullYear()} The Data Lab. All rights reserved.`,
  },
};
