import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { Button } from "../../components/general/Button";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { appStrings } from "../../resources/strings/app";
import { api } from "../../services/surveys.service";
import { Survey } from "../../types/documents/Survey";
import { SectionModal } from "../editModals/SectionModal";
import { HomeButton } from "./HomeButton";
import { Loader } from "./Loader";
import { NavItem, NavStepper } from "./NavStepper";
import { ChangeHandler } from "./types/Modify";

export type ComponentProps = {
  items: NavItem[];
  activePath: string;
  handleActivePathChange: (path: string) => void;
  loading?: boolean;
  handleChange: ChangeHandler;
};

type Component = (props: ComponentProps) => JSX.Element;

export const SideNav: Component = ({
  items,
  activePath,
  handleActivePathChange,
  loading,
  handleChange,
}) => {
  const cancelToken = useCancelToken();
  const [publishing, setPublishing] = useState(false);

  const publish = async () => {
    setPublishing(true);
    await api.publish({} as Survey, cancelToken);
    setPublishing(false);
  };
  const listOfPaths = items.map((item) => item.path);

  return (
    <Loader active={loading}>
      <Box
        sx={{ backgroundColor: "common.lightGrey", minHeight: "100vh", p: 2 }}
        role="complementary"
        aria-label={appStrings.ariaLabels.itemNavigation}
      >
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ mb: 2 }}
        >
          <HomeButton />
          <Loader active={publishing} inline>
            <Button variant="contained" onClick={publish}>
              Publish
            </Button>
          </Loader>
        </Stack>
        <SectionModal
          path=""
          handleChange={handleChange}
          addNew
          listOfPaths={listOfPaths}
        />
        <NavStepper
          items={items}
          activePath={activePath}
          handleActivePathChange={handleActivePathChange}
          handleChange={handleChange}
        />
      </Box>
    </Loader>
  );
};
