export const editModalStrings = {
  buttons: {
    save: "Save",
    cancel: "Cancel",
    delete: "Delete",
  },
};

export const pageModalStrings = {
  entity: "page",
  modalTitle: { add: "Add Page", edit: "Edit Page" },
  title: "Title",
  description: "Description",
  id: "Page ID",
};

export const subsectionModalStrings = {
  entity: "subsection",
  modalTitle: { add: "Add Subsection", edit: "Edit Subsection" },
  name: "Name",
  id: "Subsection ID",
};

export const sectionModalStrings = {
  entity: "section",
  modalTitle: { add: "Add Section", edit: "Edit Section" },
  longName: "Full Name",
  name: "Abbreviated Name",
  id: "Section ID",
};

export const videoModalStrings = {
  entity: "video",
  modalTitle: { add: "Add Video", edit: "Edit Video" },
  videoId: "Youtube Video ID",
};

export const questionModalStrings = {
  entity: "question",
  modalTitle: { add: "Add Question", edit: "Edit Question" },
  label: "Question",
  description: "Description",
  multiple: "Select Many",
};

export const questionOptionModalStrings = {
  entity: "answer",
  modalTitle: { add: "Add Answer", edit: "Edit Answer" },
  answer: "Answer",
  feedback: "Feedback",
  title: "Title",
  text: "Text",
  selected: "Selected",
  suggestionTitle: "Suggestion Title",
  suggestionDescription: "Suggestion Description",
  image: "Image",
  url: "Destination URL",
};

export const suggestionModalStrings = {
  entity: "suggestion",
  modalTitle: { add: "Add Suggestion", edit: "Edit Suggestion" },
  title: "Title",
  description: "Description",
  image: "Image",
  url: "Destination URL",
};

export const endPanelModalStrings = {
  modalTitle: "Edit End Panel",
  heading: "Heading",
  body: "Body Content",
  emailButtonLabel: "Email Button Label",
  downloadButtonLabel: "Download Button Label",
  successMessage: "Success Message",
};

export const validationStrings = {
  required: "Required",
  unique: "The ID must be unique, please pick another value. Existing IDs: ",
  length: (max: number) => `The value must be no more than ${max} characters`,
};

export const confirmDeleteModalStrings = {
  title: "Delete Confirmation",
  description: "Are you sure you want to delete this item?",
  confirmText: "Yes",
  declineText: "No",
};
