export enum ActionType {
  Add,
  Update,
  Delete,
}

export enum ActionTarget {
  Section,
  Subsection,
  Part,
  Control,
  Suggestion,
}
