import { Typography } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { PrivateRoute } from "../components/authentication/PrivateRoute";
import { LandingPage } from "../components/landingPage";
import { DataAndPrivacyAgreement } from "../components/landingPage/DataAndPrivacyAgreement";
import { AppLayout } from "../components/layout";
import { PageLayoutContainer } from "../components/layout/PageLayoutContainer";
import { SupportOrFeedbackPage } from "../components/userFeedback/SupportOrFeedbackPage";
import { UserFeedbackPage } from "../components/userFeedback/UserFeedbackPage";
import { AuthWrapper } from "../containers/authentication/AuthWrapper";
import { CustomLogin as Login } from "../containers/authentication/CustomLogin";
import { PasswordReset } from "../containers/authentication/PasswordReset";
import { SurveyResponseList } from "../containers/surveyResponses/SurveyResponseList";
import { SurveyResponsePage } from "../containers/surveyResponses/SurveyResponsePage";
import { SurveyAdminPanel } from "../containers/surveys/SurveyAdminPanel";
import { UserFeedbackList } from "../containers/userFeedback/UserFeedbackList";
import { UserList } from "../containers/users/UserList";
import { UserModify } from "../containers/users/UserModify";
import type { RouteProps } from "../types";
import { Entities } from "../types/enums/Entities";
import { Permissions } from "../types/enums/Permissions";
import { paths } from "./paths";

type Component = (props: RouteProps) => ReturnType<typeof useRoutes>;

export const Routes: Component = (props) =>
  useRoutes([
    // authentication routes
    {
      path: "/",
      element: <AuthWrapper />,
      children: [
        {
          path: paths.auth.login,
          element: <Login user={props.user} />,
        },
        {
          path: paths.auth.passwordReset,
          element: <PasswordReset user={props.user} />,
        },
      ],
    },
    // private routes which will use the main app layout
    {
      path: "/",
      element: <AppLayout {...props} />,
      children: [
        // user routes
        {
          path: paths.users.create,
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Users,
                permission: Permissions.Create,
              }}
            >
              <UserModify {...props} mode="create" />
            </PrivateRoute>
          ),
        },
        {
          path: paths.users.view(":id"),
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Users,
                permission: Permissions.Read,
              }}
            >
              <UserModify {...props} mode="view" />
            </PrivateRoute>
          ),
        },
        {
          path: paths.users.list,
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Users,
                permission: Permissions.Read,
              }}
            >
              <UserList {...props} />
            </PrivateRoute>
          ),
        },
        // page layout container for wrapping any child components which will use
        // the shared page layout
        {
          path: "/",
          element: <PageLayoutContainer />,
          children: [
            {
              index: true,
              element: (
                <PrivateRoute {...props}>
                  <LandingPage {...props} />
                </PrivateRoute>
              ),
            },
            {
              path: paths.privacyAgreement,
              element: (
                <PrivateRoute {...props}>
                  <DataAndPrivacyAgreement />
                </PrivateRoute>
              ),
            },
            {
              path: `${paths.surveyResponses.answer}/*`,
              element: (
                <PrivateRoute
                  {...props}
                  userAccess={{
                    entity: Entities.Responses,
                    permission: Permissions.Create,
                  }}
                >
                  <SurveyResponsePage {...props} mode="update" />
                </PrivateRoute>
              ),
            },
            {
              path: `${paths.surveyResponses.view(":id")}/*`,
              element: (
                <PrivateRoute
                  {...props}
                  userAccess={{
                    entity: Entities.Responses,
                    permission: Permissions.Read,
                  }}
                >
                  <SurveyResponsePage {...props} mode="view" />
                </PrivateRoute>
              ),
            },
            {
              path: `${paths.surveyResponses.update(":id")}/*`,
              element: (
                <PrivateRoute
                  {...props}
                  userAccess={{
                    entity: Entities.Responses,
                    permission: Permissions.Update,
                  }}
                >
                  <SurveyResponsePage {...props} mode="update" />
                </PrivateRoute>
              ),
            },
            {
              path: paths.help,
              element: (
                <PrivateRoute {...props}>
                  <SupportOrFeedbackPage {...props} mode="create" />
                </PrivateRoute>
              ),
            },
            {
              path: `${paths.userFeedback.create(":id")}`,
              element: (
                <PrivateRoute {...props}>
                  <UserFeedbackPage {...props} mode="create" />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${paths.surveys.adminPanel}/*`,
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Surveys,
                permission: Permissions.Read,
              }}
            >
              <SurveyAdminPanel {...props} />
            </PrivateRoute>
          ),
        },
        {
          path: paths.surveyResponses.list,
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Responses,
                permission: Permissions.Read,
              }}
            >
              <SurveyResponseList {...props} />
            </PrivateRoute>
          ),
        },
        {
          path: paths.userFeedback.list,
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Feedback,
                permission: Permissions.Read,
              }}
            >
              <UserFeedbackList {...props} />
            </PrivateRoute>
          ),
        },
        {
          path: paths.userFeedback.view(":id"),
          element: (
            <PrivateRoute
              {...props}
              userAccess={{
                entity: Entities.Feedback,
                permission: Permissions.Read,
              }}
            >
              <UserFeedbackPage {...props} mode="view" />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: paths.notFound,
      element: <Typography>page not found</Typography>,
    },
  ]);
