import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { QuestionModal, VideoModal } from "../../components/editModals";
import { ActionCardType } from "../../components/general/ActionCard";
import ActionsBox from "../../components/general/ActionsBox";
import { Header } from "../../components/general/Header";
import { SideNav } from "../../components/general/SideNav";
import {
  ComponentConfiguration,
  CustomProps,
  EventType,
} from "../../components/general/types/Modify";
import { PageLayoutBox } from "../../components/layout/PageLayoutBox";
import { changeState } from "../../helpers/survey-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useSectionJson } from "../../hooks/general/useSectionJson";
import { useAdminPanelSideItems } from "../../hooks/surveys/useAdminPanelSideItems";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import { api } from "../../services/surveys.service";
import { RouteProps } from "../../types";
import { SectionType } from "../../types/documents/BasePart";
import { ControlType } from "../../types/documents/Control";
import { Survey } from "../../types/documents/Survey";
import { ModifyContainer } from "../general/ModifyContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const surveyApi = { ...api, read: api.getUnpublishedSurvey };

export const SurveyAdminPanel: Component = (props) => {
  const [path, setPath] = useState("");
  const cancelToken = useCancelToken();

  const [data, setData] = useState<Survey>();
  const { item, navItems, activePath, loading, updateNavItems } =
    useAdminPanelSideItems();

  const navigate = useNavigate();

  const handleActivePathChange = (pathFromNavItem: string) => {
    setPath(pathFromNavItem);
  };

  useEffect(() => {
    setPath(activePath);
  }, [activePath]);

  useEffect(() => {
    setData(item);
  }, [item]);

  const SectionHeader = ({
    data: { sections },
    handleChange,
  }: CustomProps<Survey>) => {
    const {
      longName: sectionHeader,
      name: pageHeader,
      description,
      type,
      part,
    } = useSectionJson(sections);

    return (
      <Header
        sectionHeader={sectionHeader}
        pageHeader={pageHeader}
        caption={description ?? undefined}
        handleChange={handleChange}
        path={path}
        type={type}
        isSubsection={!part}
        editable
      />
    );
  };

  const Actions = ({
    data: { sections },
    handleChange,
  }: CustomProps<Survey>) => {
    const { type, subsection, part } = useSectionJson(sections);

    if (type === SectionType.Question) {
      let suggestions: ActionCardType[] = [];
      if (subsection) {
        let current = part ? part : subsection;
        current.suggestions.forEach((suggestion) => {
          suggestions.push({
            title: suggestion.title,
            description: suggestion.description,
            image: { src: suggestion.image, alt: suggestion.title },
            url: suggestion.url,
          });
        });
      }

      return (
        <ActionsBox
          sectionTitle={strings.actionsBox.questionTitle}
          cards={suggestions}
          collapsible={true}
          icon={false}
          path={path}
          editable={true}
          handleChange={handleChange}
        />
      );
    } else return null;
  };

  const AddQuestionAndVideoButtons = ({
    data: { sections },
    handleChange,
  }: CustomProps<Survey>) => {
    const { type, path } = useSectionJson(sections);
    if (type === SectionType.Feedback || type === SectionType.End) return null;
    const newQuestionId = uuid();
    const newVideoId = uuid();

    return (
      <Stack direction="row" justifyContent="center" spacing={2}>
        {type === SectionType.Question && (
          <QuestionModal
            key={newQuestionId}
            controlId={newQuestionId}
            path={path}
            handleChange={handleChange}
            addNew
          />
        )}
        <VideoModal
          key={newVideoId}
          controlId={newVideoId}
          path={path}
          handleChange={handleChange}
          addNew
        />
      </Stack>
    );
  };

  // TODO: resolve type error
  //@ts-ignore
  const Configuration: ComponentConfiguration<Survey> = ({
    data: { sections },
  }) => {
    const { controls, path } = useSectionJson(sections);
    return [
      {
        key: "header",
        content: [
          {
            controltype: ControlType.Custom,
            Component: SectionHeader,
            md: 12,
          },
        ],
      },
      {
        key: "question",
        content: controls
          .filter((control) =>
            [
              ControlType.Question,
              ControlType.Feedback,
              ControlType.Video,
              ControlType.EndPanel,
            ].includes(control.type)
          )
          .map(({ type, ...props }) => ({
            controltype: type,
            ...props,
            path,
            md: 12,
            editable: true,
            disabled: true,
          })),
      },
      {
        key: "addControl",
        content: [
          {
            controltype: ControlType.Custom,
            Component: AddQuestionAndVideoButtons,
            md: 12,
          },
        ],
      },
      {
        key: "actions",
        content: [
          {
            controltype: ControlType.Custom,
            Component: Actions,
            md: 12,
          },
        ],
      },
    ];
  };

  const updateSurvey = (prev: Survey, event: EventType) => {
    const [newState, redirect] = changeState(prev, event);

    delete newState.created;
    delete newState.updated;

    setData(newState);
    updateNavItems(newState);

    api.update(newState, cancelToken);

    if (redirect) {
      setPath(redirect);
      navigate(redirect);
    }

    return newState;
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <SideNav
          items={navItems}
          activePath={path}
          handleActivePathChange={handleActivePathChange}
          loading={loading}
          handleChange={(event: EventType) => {
            setData((prev) => (prev ? updateSurvey(prev, event) : undefined));
          }}
        />
      </Grid>
      {!loading && data && (
        <Grid item xs={10}>
          <PageLayoutBox>
            <ModifyContainer<Survey>
              {...props}
              api={surveyApi}
              componentConfiguration={Configuration}
              constraints={{}}
              initialData={data}
              mode={"update"}
              redirectPath=""
              changeState={(prev, event) => updateSurvey(prev, event)}
              parentControlled
            />
          </PageLayoutBox>
        </Grid>
      )}
    </Grid>
  );
};
