export const modalImageUploadStrings = {
  noImage: "No image uploaded",
  buttons: {
    upload: "Upload a new image",
    clear: "Clear",
  },
  ariaLabels: {
    clearButton: "clear image",
  },
};
