import { FiberManualRecord as Dot } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useState } from "react";

interface Props {
  panels: Array<JSX.Element | boolean>;
}
type Component = (props: Props) => JSX.Element;

export const SteppedPanels: Component = ({ panels }) => {
  const [activePanel, setActivePanel] = useState(0);
  const panelsToDisplay = panels.filter(Boolean);
  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      {panelsToDisplay.map((content, index) => (
        <div
          key={index}
          role="tabpanel"
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          hidden={activePanel !== index}
        >
          {content}
        </div>
      ))}
      <Box
        role="tablist"
        aria-label="tabs"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        {panelsToDisplay.map((_, index) => (
          <div
            key={index}
            role="tab"
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            onClick={() => setActivePanel(index)}
          >
            {panelsToDisplay.length > 1 && (
              <Dot
                sx={[
                  {
                    color: "common.midGrey",
                    fontSize: (theme) => theme.typography.fontSize,
                  },
                  activePanel === index && { color: "primary.main" },
                ]}
              />
            )}
          </div>
        ))}
      </Box>
    </Box>
  );
};
