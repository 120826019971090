import axios from "../libs/axios/axios-permissions";
import type { Permissions } from "../types";
import type { ReadFunction } from "../types/API";

type Response = {
  userPermissions: Permissions;
};

const read: ReadFunction<Permissions> = async (_id, _childId, cancelToken?) => {
  const { data } = await axios.get<Response>("", {
    cancelToken,
  });
  return {
    item: data.userPermissions,
  };
};

export const api = {
  read,
};
