import { Grid, SxProps, Theme } from "@mui/material";
import { SurveyCompletionChart } from "../../components/surveyResponses/SurveyCompletionChart";
import { SurveySkipRatesChart } from "../../components/surveyResponses/SurveySkipRatesChart";
import { RouteProps } from "../../types";

const surveyChartsStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.lightGrey,
  borderStyle: "solid none",
  borderColor: theme.palette.common.midGrey,
  borderWidth: "0.1em",
});

const surveyChartsItemStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  borderStyle: "solid",
  borderColor: theme.palette.common.midGrey,
  borderWidth: "0.1em",
});

interface Props extends RouteProps {}

type Component = (props: Props) => JSX.Element;

export const SurveyCharts: Component = ({ ...routeProps }) => {
  return (
    <Grid sx={[surveyChartsStyles]} container padding={2}>
      <Grid sx={[surveyChartsItemStyles]} item xs={12} md={6} padding={2}>
        <SurveyCompletionChart />
      </Grid>
      <Grid sx={[surveyChartsItemStyles]} item xs={12} md={6} padding={2}>
        <SurveySkipRatesChart {...routeProps} />
      </Grid>
    </Grid>
  );
};
