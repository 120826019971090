import { useState } from "react";
import { TableContainer } from "../../containers/general/TableContainer";
import { surveyResponseStrings } from "../../resources/strings/surveyResponses";
import { api } from "../../services/surveyskiprates.service";
import { RouteProps } from "../../types";
import {
  SurveyChartFilters,
  SurveySkipRatesChartData,
} from "../../types/documents/SurveyResponse";
import { SearchData, SurveyChartSearchBar } from "./SurveyChartSearchBar";

export const parseSearchFilters = (
  filters: Partial<SearchData>
): Partial<SurveyChartFilters> => {
  let result: Partial<SurveyChartFilters> = {};

  if (filters.createdFrom || filters.createdTo) {
    result.created = {
      from: filters.createdFrom,
      to: filters.createdTo,
    };
  }

  return result;
};

interface Props extends RouteProps {}

type Component = (props: Props) => JSX.Element;

export const SurveySkipRatesChart: Component = ({ ...routeProps }) => {
  const [filters, setFilters] = useState<Partial<SearchData>>(() => ({}));
  const parsedFilters = parseSearchFilters(filters);

  const clearFilters = () => setFilters({});

  const onFilterChange = (searchData: Partial<SearchData>) => {
    setFilters(searchData);
  };

  const tableChildren = () => {
    return [
      <SurveyChartSearchBar
        filters={filters}
        onFilterChange={onFilterChange}
        onReset={clearFilters}
      />,
    ];
  };

  return (
    <>
      <TableContainer<SurveySkipRatesChartData>
        {...routeProps}
        api={api}
        header={surveyResponseStrings.skipRatesChart.header}
        tableColumns={[
          {
            id: "controlId",
            Header: surveyResponseStrings.skipRatesChart.question,
            accessor: ({ controlId }) => controlId,
          },
          {
            id: "section",
            Header: surveyResponseStrings.skipRatesChart.section,
            accessor: ({ section }) => section,
          },
          {
            id: "rate",
            Header: surveyResponseStrings.skipRatesChart.rate,
            accessor: ({ rate }) => {
              return rate;
            },
          },
        ]}
        filters={parsedFilters}
        progressRow
        progressValueHeader={surveyResponseStrings.skipRatesChart.rate}
        ariaLabel={surveyResponseStrings.ariaLabels.skipRatesListTable}
      >
        {tableChildren()}
      </TableContainer>
    </>
  );
};
