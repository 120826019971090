import { useCallback, useEffect } from "react";
import { getToken } from "../../libs/auth";
export type WebsocketOpenEvent = {
  action: string;
  data: {
    entity: string;
    service: string;
    eventType: string;
    payload: { [key: string]: unknown };
    Authorization: string;
  };
};

let wss: WebSocket | null = null;

export const useWebsocket = <M extends object>(
  endpoint: string,
  handleOpen: () => WebsocketOpenEvent,
  handleMessage: (message: M) => void,
  handleError: (error: Event) => void
) => {
  const onOpen = useCallback(handleOpen, [handleOpen]);
  const onMessage = useCallback(handleMessage, [handleMessage]);
  const onError = useCallback(handleError, [handleError]);

  useEffect(() => {
    return () => {
      wss?.close();
    };
  }, []);

  const websocketHandler = useCallback(
    (id: string) => {
      wss = new WebSocket(endpoint);

      wss.onopen = async () => {
        const openEvent = onOpen();
        const token = await getToken();
        // replace input token to make sure its as up to date as possible
        openEvent.data.Authorization = token;
        openEvent.data.payload.id = id;
        wss?.send(JSON.stringify(openEvent));
      };
      wss.onmessage = ({ data }) => {
        const message = JSON.parse(data) as M;
        onMessage(message);
        wss?.close();
      };
      wss.onerror = (error) => {
        onError(error);
        wss?.close();
      };
    },
    [endpoint, onOpen, onMessage, onError]
  );

  return [websocketHandler] as const;
};
