import type { EventType } from "../components/general/types/Modify";
import { SectionType } from "../types/documents/BasePart";
import { Control } from "../types/documents/Control";
import { Part } from "../types/documents/Part";
import { Subsection } from "../types/documents/Subsection";
import type { SurveyResponse } from "../types/documents/SurveyResponse";
import {
  extractFeedbackBasedOnSelectedValue,
  parseFeedback,
  parseFeedbackFromSections,
} from "./feedback-helpers";

const parsePath = (path: string) => {
  const [sectionId, subsectionId, partId] = path.split("/");
  return { sectionId, subsectionId, partId };
};

const updateControls = (
  controls: Control[],
  changedControl: { controlId: string; values: string[] }
) => {
  return controls.map((control) => {
    if (control.controlId !== changedControl.controlId) return control;
    return {
      ...control,
      value: changedControl.values,
    };
  });
};

export const changeState = (
  state: SurveyResponse,
  e: EventType
): SurveyResponse => {
  const { name = "" } = e.target;
  const { sectionId, subsectionId, partId } = parsePath(name);

  const changedControl = e.target.value as {
    controlId: string;
    values: string[];
    feedback: any[];
  };

  const newSections = state.sections.map((currentSection) => {
    if (currentSection.sectionId !== sectionId) {
      return currentSection;
    }

    const updatedSubsections = currentSection.subSections.map(
      (currentSubsection) => {
        if (currentSubsection.subsectionId !== subsectionId) {
          return currentSubsection;
        }

        if (partId && currentSubsection.parts) {
          const newParts = currentSubsection.parts.map((currentPart) => {
            if (currentPart.partId !== partId) return currentPart;

            return {
              ...currentPart,
              controls: updateControls(currentPart.controls, changedControl),
            };
          });

          return {
            ...currentSubsection,
            parts: newParts,
          };
        }

        return {
          ...currentSubsection,
          controls: updateControls(currentSubsection.controls, changedControl),
        };
      }
    );

    const currentSubSection = updatedSubsections.find(
      (subsection) => subsection.subsectionId === subsectionId
    ) as Subsection;
    const finalSubsections = updatedSubsections.map((subsection) => {
      if (subsection.type === SectionType.Feedback) {
        const feedbackSubsection = updatedSubsections.find(
          (subsection) => subsection.type === SectionType.Feedback
        ) as Subsection;

        if (currentSubSection?.controls.length) {
          const feedbackArrayToFormat = extractFeedbackBasedOnSelectedValue(
            currentSubSection?.controls
          );

          return parseFeedback(
            feedbackSubsection,
            currentSubSection?.name ?? "",
            feedbackArrayToFormat
          );
        } else {
          const previousSubsections = updatedSubsections.filter(
            (updatedSubsection) =>
              updatedSubsection.id !== feedbackSubsection?.id
          );
          const newFeedback = parseFeedbackFromSections(
            feedbackSubsection,
            previousSubsections
          );
          return newFeedback;
        }
      } else if (subsection.parts && subsection.parts.length > 0) {
        const currentPart = currentSubSection?.parts?.find(
          (part) => part.partId === partId
        );
        const feedbackPart = currentSubSection?.parts?.find(
          (part) =>
            part.type === SectionType.Feedback &&
            part.path.includes(currentSubSection.path)
        ) as Part;
        const feedbackArrayToFormat = extractFeedbackBasedOnSelectedValue(
          currentPart?.controls
        );
        const newFeedbackPart = parseFeedback(
          feedbackPart,
          currentPart?.name ?? "",
          feedbackArrayToFormat
        );
        const newParts = subsection.parts.map((part) => {
          if (
            part.type === SectionType.Feedback &&
            part.path.includes(currentSubSection?.path ?? "")
          ) {
            return newFeedbackPart;
          } else {
            return part;
          }
        });
        return { ...subsection, parts: newParts };
      } else {
        return subsection;
      }
    });

    return {
      ...currentSection,
      subSections: finalSubsections,
    };
  });

  return { ...state, sections: newSections };
};
