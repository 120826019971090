import { useState } from "react";
import { Button } from "../../components/general/Button";
import { Loader } from "../../components/general/Loader";
import { PageLayoutBox } from "../../components/layout/PageLayoutBox";
import {
  SearchData,
  SurveyResponseSearchBar,
} from "../../components/surveyResponses/SurveyResponseSearchBar";
import { formatDateTime } from "../../helpers/date-helpers";
import { useWebsocket } from "../../hooks/general/useWebsocket";
import { WEBSOCKET_ENDPOINT } from "../../libs/config";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import { api } from "../../services/surveyresponses.service";
import { RouteProps } from "../../types/";
import { UserData } from "../../types/documents";
import { SurveyResponse } from "../../types/documents/SurveyResponse";
import { TableContainer } from "../general/TableContainer";
import { SurveyCharts } from "./SurveyCharts";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

type SurveyResponseFilters = {
  status: string;
  user: Pick<UserData, "email">;
  created: { from?: string; to?: string };
  updated: { from?: string; to?: string };
  completedDate: { from?: string; to?: string };
};

export const parseSearchFilters = (
  filters: Partial<SearchData>
): Partial<SurveyResponseFilters> => {
  let result: Partial<SurveyResponseFilters> = {};

  if (filters.createdFrom || filters.createdTo) {
    result.created = {
      from: filters.createdFrom || undefined,
      to: filters.createdTo || undefined,
    };
  }
  if (filters.updatedFrom || filters.updatedTo) {
    result.updated = {
      from: filters.updatedFrom || undefined,
      to: filters.updatedTo || undefined,
    };
  }
  if (filters.completedFrom || filters.completedTo) {
    result.completedDate = {
      from: filters.completedFrom || undefined,
      to: filters.completedTo || undefined,
    };
  }
  if (filters.email) {
    result.user = { email: filters.email };
  }
  if (filters.status) {
    result.status = filters.status;
  }

  return result;
};

export const SurveyResponseList: Component = ({ ...routeProps }) => {
  const [linkLoading, setLinkLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [filters, setFilters] = useState<Partial<SearchData>>(() => ({}));
  const parsedFilters = parseSearchFilters(filters);

  const handleClick = (id: string) => {
    setLoadingId(id);
    downloadResponse(id);
  };

  const clearFilters = () => setFilters({});

  const onFilterChange = (searchData: Partial<SearchData>) => {
    setFilters(searchData);
  };

  const [downloadResponse] = useWebsocket<{ url: string }>(
    WEBSOCKET_ENDPOINT,
    () => {
      setLinkLoading(true);
      return {
        action: "subscribe",
        data: {
          entity: "surveyResponses",
          service: "websocket",
          eventType: "download",
          payload: { id: "" },
          Authorization: "",
        },
      };
    },
    (message) => {
      setLinkLoading(false);

      const { url } = message;
      if (!url) return;

      window.open(url, "_parent");
    },
    (error) => {
      setLinkLoading(false);

      console.error(error);
    }
  );

  const tableChildren = () => {
    return [
      <SurveyCharts {...routeProps} />,
      <SurveyResponseSearchBar
        filters={filters}
        onFilterChange={onFilterChange}
        onReset={clearFilters}
      />,
    ];
  };

  return (
    <PageLayoutBox>
      <TableContainer<SurveyResponse>
        {...routeProps}
        manualPagination
        api={api}
        header={strings.header.surveyResponses}
        tableColumns={[
          {
            id: "userId",
            Header: strings.labels.user,
            accessor: ({ user = {} }) => {
              return user.email;
            },
          },
          {
            id: "created",
            Header: strings.labels.created,
            accessor: ({ created }) => formatDateTime(created || ""),
          },
          {
            id: "updated",
            Header: strings.labels.updated,
            accessor: ({ updated }) => formatDateTime(updated || ""),
          },
          {
            id: "status",
            Header: strings.labels.status,
            accessor: ({ status }) => status,
          },
          {
            id: "completedDate",
            Header: strings.labels.completedDate,
            accessor: ({ completedDate }) =>
              completedDate && formatDateTime(completedDate || ""),
          },
          {
            id: "Download",
            accessor: (cellValues) => {
              return (
                <Button
                  aria-label={strings.ariaLabels.download}
                  color="secondary"
                  onClick={() => {
                    handleClick(cellValues.id || "");
                  }}
                >
                  <Loader
                    active={linkLoading && loadingId === cellValues.id}
                    inline
                  >
                    <>{strings.labels.download}</>
                  </Loader>
                </Button>
              );
            },
          },
        ]}
        hasHomeButton
        filters={parsedFilters}
        ariaLabel={strings.ariaLabels.responseListTable}
        defaultSort={[{ id: "completedDate", desc: true }]}
      >
        {tableChildren()}
      </TableContainer>
    </PageLayoutBox>
  );
};
