import { EventNote as CalendarIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { toDateFormat } from "../../../helpers/date-helpers";
import { appStrings as strings } from "../../../resources/strings/app";
import type { ChangeHandler, DatePicker as TDatePicker } from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TDatePicker<D>, "controltype">;
  handleChange: ChangeHandler;
}

export const DatePicker = <D extends object>({
  config,
  handleChange,
}: Props<D>) => {
  const { name, label, required, disabled, value, error, helperText } = config;
  const dateValue =
    typeof value === "string" && value ? DateTime.fromISO(value) : null;

  return (
    <>
      <MuiDatePicker
        renderInput={(props) => (
          <TextField
            {...props}
            variant="outlined"
            size="small"
            placeholder={strings.labels.defaultSelect}
            required={required}
            InputLabelProps={{ htmlFor: name }}
            helperText={helperText}
            error={error}
          />
        )}
        value={dateValue}
        disabled={disabled}
        InputProps={{
          title: name,
          id: name,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          ),
        }}
        onChange={(date) => {
          const dateString = toDateFormat(date);
          handleChange({
            target: {
              name,
              value: dateString,
            },
          });
        }}
        label={label}
      />
    </>
  );
};
