import { Grid } from "@mui/material";

interface Props {
  children: JSX.Element | JSX.Element[];
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  ariaLabel?: string;
}
type Component = (props: Props) => JSX.Element;

export const SearchBar: Component = ({ children, onSubmit, ariaLabel }) => (
  <Grid
    container
    spacing={1}
    role="searchbox"
    paddingTop={1}
    aria-label={ariaLabel}
  >
    <Grid item xs>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} alignContent="center" alignItems="center">
          {children}
        </Grid>
      </form>
    </Grid>
  </Grid>
);
