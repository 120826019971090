import { ChevronLeft as ArrowBackIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { paths } from "../../navigation/paths";
import { appStrings as strings } from "../../resources/strings/app";
import { Button } from "./Button";

interface Props {
  sx?: SxProps<Theme>;
}
type Component = (props: Props) => JSX.Element;

export const HomeButton: Component = ({ sx }) => {
  return (
    <Button
      color="primary"
      variant="contained"
      aria-label={strings.ariaLabels.home}
      component={RouterLink}
      to={paths.landingPage}
      startIcon={<ArrowBackIcon />}
      sx={sx}
    >
      {strings.labels.home}
    </Button>
  );
};
