import axios from "../libs/axios/axios-users";
import type {
  CreateFunction,
  QueryFunction,
  QueryResult,
  ReadFunction,
  ReadResult,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import type { UserData } from "../types/documents/User";

const query: QueryFunction<UserData> = async (body, cancelToken?) => {
  const { data } = await axios.post<QueryResult<UserData>>("/search", body, {
    cancelToken,
  });
  return data;
};

const read: ReadFunction<UserData> = async (id, _childId, cancelToken?) => {
  const path = `/${id}`;
  const { data } = await axios.get<ReadResult<UserData>>(path, {
    cancelToken,
  });
  return data;
};

const update: UpdateFunction<UserData> = async (body, cancelToken?) => {
  const id = body.id;
  const { data } = await axios.put<UpdateResult>(`/${id}`, body, {
    cancelToken,
  });
  return data;
};

const create: CreateFunction<UserData> = async (body, cancelToken?) => {
  const { data } = await axios.post<UpdateResult>("", body, {
    cancelToken,
  });
  return {
    id: data.id,
  };
};

export const api = {
  query,
  read,
  update,
  create,
};
