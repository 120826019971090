import { useNavigate } from "react-router-dom";
import { PageLayoutBox } from "../../components/layout/PageLayoutBox";
import { formatDateTime } from "../../helpers/date-helpers";
import { paths } from "../../navigation/paths";
import { userStrings as strings } from "../../resources/strings/users";
import { api } from "../../services/users.service";
import { RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { Entities } from "../../types/enums/Entities";
import { Permissions } from "../../types/enums/Permissions";
import { TableContainer } from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const UserList: Component = ({ ...routeProps }) => {
  const navigate = useNavigate();
  const clickHandler = ({ id }: UserData) => {
    navigate(paths.users.view(id));
  };
  return (
    <PageLayoutBox>
      <TableContainer<UserData>
        {...routeProps}
        manualPagination
        api={api}
        header={strings.header.users}
        tableColumns={[
          {
            id: "username",
            Header: strings.labels.username,
            accessor: "username",
          },
          {
            id: "email",
            Header: strings.labels.email,
            accessor: "email",
          },
          {
            id: "forename",
            Header: strings.labels.forename,
            accessor: "forename",
          },
          {
            id: "surname",
            Header: strings.labels.surname,
            accessor: "surname",
          },
          {
            id: "created",
            Header: strings.labels.created,
            accessor: ({ created }) => formatDateTime(created),
          },
          {
            id: "updated",
            Header: strings.labels.updated,
            accessor: ({ updated }) => formatDateTime(updated),
          },
        ]}
        createButtonConfig={{
          label: strings.labels.createUser,
          userAccess: {
            entity: Entities.Users,
            permission: Permissions.Create,
          },
          path: paths.users.create,
        }}
        clickHandler={clickHandler}
        hasHomeButton
        ariaLabel={strings.ariaLabels.usersTable}
      />
    </PageLayoutBox>
  );
};
