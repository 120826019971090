import { useCallback, useEffect, useMemo, useState } from "react";
import type { Column, SortingRule } from "react-table";
import { Table } from "../../components/general/Table";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { HooksLogger } from "../../hooks/hooks-logger";
import type { RouteProps, UserAccess } from "../../types";
import type { QueryFunction } from "../../types/API";

const hooksLogger = new HooksLogger("TableContainer/useEffect");

export interface Props<D extends object> extends RouteProps {
  api: {
    query: QueryFunction<D>;
  };
  header: string;
  tableColumns: Column<D>[];
  ariaLabel: string;
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  clickHandler?: (data: D) => void;
  manualPagination?: boolean;
  filters?: object;
  defaultSort?: SortingRule<D>[];
  hasHomeButton?: boolean;
  children?: JSX.Element[];
  progressRow?: boolean;
  progressValueHeader?: string;
}

export const TableContainer = <D extends Record<string, unknown>>({
  api,
  permissions,
  header,
  tableColumns,
  createButtonConfig,
  clickHandler,
  manualPagination,
  filters,
  defaultSort = [],
  ariaLabel,
  hasHomeButton = false,
  children,
  progressRow = false,
  progressValueHeader = "",
}: Props<D>) => {
  const [tableData, setTableData] = useState<D[]>(() => []);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const cancelToken = useCancelToken();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState<SortingRule<D>[]>(defaultSort);

  const sortObject = useMemo(() => sort, [sort]);

  const searchCritera = useMemo(() => {
    const orderBy: { [key: string]: "ASC" | "DESC" } = {};
    sortObject?.forEach(({ id, desc }) => {
      orderBy[id] = desc ? "DESC" : "ASC";
    });

    return {
      page,
      size,
      sort: orderBy,
    };
  }, [page, size, sortObject]);

  useEffect(() => {
    const body: {
      page?: number;
      size?: number;
      query?: object;
      sort?: { [key: string]: "ASC" | "DESC" };
    } = manualPagination ? searchCritera : {};
    if (filters) {
      body.query = filters;
    }

    const query = async () => {
      hooksLogger.request("Getting table data");

      setLoading(true);
      try {
        const { items = [], totalCount: count } = await api.query(
          body,
          cancelToken
        );

        setTableData(items);
        setTotalCount(count);
        setLoading(false);
        hooksLogger.success(items, count);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = logger.error(e);
        setError(error);
        setLoading(false);
        hooksLogger.error(error);
      }
    };

    query();
  }, [manualPagination, cancelToken, api, searchCritera, filters]);

  const onPageChange = useCallback((pageIndex: number) => {
    setPage(pageIndex);
  }, []);

  const onSizeChange = useCallback((pageSize: number) => {
    setSize(pageSize);
  }, []);

  const onSortChange = useCallback(
    (sortBy: SortingRule<D>[]) => {
      setSort(sortBy);
    },
    [setSort]
  );

  return (
    <div>
      <Table<D>
        // custom props
        permissions={permissions}
        header={header}
        createButtonConfig={createButtonConfig}
        loading={loading}
        clickHandler={clickHandler}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        onSortChange={onSortChange}
        sortObject={sortObject}
        hasHomeButton={hasHomeButton}
        children={children}
        progressRow={progressRow}
        progressValueHeader={progressValueHeader}
        ariaLabel={ariaLabel}
        // react table props
        data={tableData}
        columns={tableColumns}
        size={size}
        manualPagination={manualPagination}
        totalCount={totalCount}
      />
    </div>
  );
};
