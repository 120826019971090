import { Suggestion } from "./Suggestion";

export enum ControlType {
  Video = "video",
  Question = "question",
  Feedback = "feedback",
  Input = "input",
  Dropdown = "dropdown",
  RadioGroup = "radio",
  Header = "header",
  Link = "link",
  Button = "button",
  Checkbox = "checkbox",
  SectionHeader = "sectionheader",
  EndPanel = "endpanel",
  Custom = "custom",
}

type BaseControl = {
  controlId: string;
};

export type VideoControl = BaseControl & {
  type: ControlType.Video;
  videoId: string;
};

export type Feedback = {
  text: string;
  title: string;
  selected: boolean;
  suggestion?: {
    title: string;
    description: string;
    url: string;
    image: string;
  };
};

export type QuestionControl = BaseControl & {
  type: ControlType.Question;
  multiple?: boolean;
  skipped: boolean;
  label: string;
  description: string;
  value?: string[];
  options: QuestionOption[];
  path: string;
};

export type QuestionOption = {
  id: string;
  label: string;
  value: string;
  feedback: Feedback[];
};

type FeedbackControl = BaseControl & {
  type: ControlType.Feedback;
  items: Array<{
    title: string;
    image: { src: string; alt: string };
    content: Array<{
      heading: string;
      body: string;
    }>;
  }>;
  suggestions?: Array<{ title: string; suggestions: Suggestion[] }>;
};

export type EndControl = BaseControl & {
  type: ControlType.EndPanel;
  heading: string;
  body: string;
  emailSuccess: string;
  emailButtonLabel: string;
  downloadButtonLabel: string;
};

export type Control =
  | VideoControl
  | QuestionControl
  | FeedbackControl
  | EndControl;
