import { PlayCircleOutline } from "@mui/icons-material";
import {
  Box,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Subsection } from "../../types/documents";
import { PageModal } from "../editModals/PageModal";
import { SectionModal } from "../editModals/SectionModal";
import { SubsectionModal } from "../editModals/SubsectionModal";
import { NavStepperIcon } from "./NavStepperIcon";
import { ChangeHandler } from "./types/Modify";

type MuiIconType = typeof PlayCircleOutline;

export type NavItem = {
  name: string;
  longName?: string;
  icon?: {
    Active: MuiIconType;
    Inactive: MuiIconType;
    color: string;
  };
  subItems?: NavItem[];
  subsection?: Subsection;
  path: string;
};

type ComponentProps = {
  items: NavItem[];
  activePath: string;
  handleActivePathChange: (path: string) => void;
  handleChange: ChangeHandler;
  child?: boolean;
};

type Component = (props: ComponentProps) => JSX.Element;

export const NavStepper: Component = (props) => {
  const activeStepIdx = (items: NavItem[]) => {
    return items.findIndex((item) => item.path === props.activePath);
  };
  const listOfSectionLevelPaths = props.items.map((item) => item.path);

  return (
    <Box>
      {props.items.map((item) => {
        const showAddPage = item.path !== "finish";
        const showEditSection = showAddPage && !props.child;
        const showEditSubsection = showAddPage && props.child;

        const navList: NavItem[] = [];
        const stepList: NavItem[] = [];
        const listOfSubsectionLevelPaths: string[] = [];
        const listOfPageLevelPaths: string[] = [];

        if (item.subItems) {
          item.subItems.forEach((part) => {
            part.subItems ? navList.push(part) : stepList.push(part);
            if (item.subsection) {
              listOfPageLevelPaths.push(part.path.split("/")[2]);
            } else {
              listOfSubsectionLevelPaths.push(part.path.split("/")[1]);
            }
          });
        }

        return (
          <Box
            key={item.path}
            sx={{ ml: 2 }}
            role="navigation"
            aria-label={`${item.name} navigation`}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{ my: 2 }}
              alignItems="center"
            >
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "left",
                  color: "secondary.main",
                  fontWeight: "bold",
                }}
              >
                {item.longName || item.name || "<blank>"}
              </Typography>
              {showEditSection && (
                <>
                  <SectionModal
                    path={item.path}
                    handleChange={props.handleChange}
                    section={{
                      name: item.name,
                      longName: item.longName ?? item.name,
                      sectionId: item.path,
                    }}
                    listOfPaths={listOfSectionLevelPaths}
                  />
                  <SubsectionModal
                    path={item.path}
                    handleChange={props.handleChange}
                    addNew
                    listOfPaths={listOfSubsectionLevelPaths}
                  />
                </>
              )}
              {showEditSubsection && (
                <SubsectionModal
                  path={item.path}
                  handleChange={props.handleChange}
                  subsection={{
                    name: item.name,
                    subsectionId: item.path.split("/").pop() ?? "",
                  }}
                  listOfPaths={listOfSubsectionLevelPaths}
                />
              )}
              {showAddPage && (
                <PageModal
                  path={item.path}
                  handleChange={props.handleChange}
                  page={{
                    name: "",
                    description: "",
                    pageId: "",
                  }}
                  isSubsection={!props.child}
                  addNew
                  listOfPaths={
                    !props.child
                      ? listOfSubsectionLevelPaths
                      : listOfPageLevelPaths
                  }
                />
              )}
            </Stack>
            {stepList.length > 0 && (
              <Stepper
                orientation="vertical"
                role="slider"
                sx={{ mb: 4 }}
                activeStep={activeStepIdx(stepList)}
                aria-label={`${item.name} steps`}
              >
                {stepList.map((step) => {
                  const isActiveStep = () => {
                    const activeStep = activeStepIdx(stepList);
                    return (
                      activeStep >= 0 && stepList[activeStep].name === step.name
                    );
                  };
                  const handleClick = () => {
                    props.handleActivePathChange(step.path);
                  };
                  return (
                    <Step key={step.name}>
                      <StepLabel
                        StepIconComponent={({ active }) =>
                          step.icon ? (
                            <NavStepperIcon
                              Active={step.icon.Active}
                              Inactive={step.icon.Inactive}
                              active={active}
                              color={step.icon.color}
                            />
                          ) : null
                        }
                        sx={{ py: 0 }}
                      >
                        <Link
                          component={RouterLink}
                          to={step.path}
                          underline="hover"
                          onClick={handleClick}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "bold",
                              ...(isActiveStep() && {
                                color: "common.pink",
                              }),
                            }}
                          >
                            {step.name || "<blank>"}
                          </Typography>
                        </Link>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            {navList.length > 0 && (
              <NavStepper
                key={item.name}
                items={navList}
                activePath={props.activePath}
                handleActivePathChange={props.handleActivePathChange}
                handleChange={props.handleChange}
                child
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
