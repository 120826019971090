import { formatDetailedDate } from "../../helpers/date-helpers";
import { paths } from "../../navigation/paths";
import {
  SurveyHistory,
  SurveyResponseStatus,
} from "../../types/documents/SurveyResponse";
import { CardList } from "../general/CardList";

interface Props {
  surveyHistoryItems: SurveyHistory[];
}
type Component = (props: Props) => JSX.Element;

export const SurveyHistoryCardList: Component = ({ surveyHistoryItems }) => {
  return (
    <CardList
      items={surveyHistoryItems.map(({ id, created, status }) => ({
        id,
        text: formatDetailedDate(created) + ` - ${status}`,
        path:
          status === SurveyResponseStatus.InProgress
            ? paths.surveyResponses.update(id)
            : paths.surveyResponses.view(id),
        status,
      }))}
    />
  );
};
