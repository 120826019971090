import { ChevronLeft as ArrowBackIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ModifyContainer } from "../../containers/general/ModifyContainer";
import { formatDateTime } from "../../helpers/date-helpers";
import { paths } from "../../navigation/paths";
import {
  contactOptions,
  ratingOptions,
} from "../../resources/dropdownOptions/userFeedback";
import { userFeedbackStrings } from "../../resources/strings/userFeedback";
import { api } from "../../services/userfeedback.service";
import { RouteProps } from "../../types";
import { ControlType } from "../../types/documents/Control";
import { UserFeedback } from "../../types/documents/UserFeedback";
import { Button } from "../general/Button";
import { Header } from "../general/Header";
import { Input } from "../general/controls/Input";
import type { ContentConfig, CustomProps, Mode } from "../general/types/Modify";
import { PageLayoutBox } from "../layout/PageLayoutBox";

export interface Props extends RouteProps {
  mode: Mode;
}

const constraints = {
  rating: {
    presence: {
      allowEmpty: false,
      message: `^${userFeedbackStrings.rating.question} is required`,
    },
  },
};

const MAX_LENGTH = 255;

const ratingConfig: ContentConfig<UserFeedback> = {
  controltype: ControlType.Dropdown,
  name: "rating",
  label: userFeedbackStrings.rating.question,
  options: ratingOptions,
};

const userFeedbackLabelConfig: ContentConfig<UserFeedback> = {
  controltype: ControlType.Header,
  text: userFeedbackStrings.contact.question,
  align: "left",
  variant: "body2",
};

const contactConfig: ContentConfig<UserFeedback> = {
  controltype: ControlType.RadioGroup,
  name: "wantToBeContactedAnswer",
  label: "",
  row: true,
  options: contactOptions,
  sx: [
    {
      justifyContent: "center",
    },
  ],
};

const Comments = ({
  disabled,
  handleChange,
  data: { comments },
}: CustomProps<UserFeedback>) => {
  let currentLength = 0;
  if (comments) currentLength = comments.length;
  return (
    <Input
      config={{
        name: "comments",
        label: userFeedbackStrings.furtherComments,
        multiline: true,
        rows: 5,
        inputProps: { maxLength: MAX_LENGTH },
        "aria-label": userFeedbackStrings.ariaLabels.commentsField,
        value: comments,
        helperText: `${currentLength}/${MAX_LENGTH}`,
        disabled,
      }}
      handleChange={handleChange}
    />
  );
};

const FeedbackHeader = ({ mode }: CustomProps<UserFeedback>) => (
  <Header
    sectionHeader={userFeedbackStrings.shortTitle}
    pageHeader={userFeedbackStrings.longTitle}
    caption={mode !== "view" ? userFeedbackStrings.pageDescription : ""}
  />
);

const SubmitButton = () => (
  <Button
    type="submit"
    aria-label={userFeedbackStrings.ariaLabels.submitButton}
    fullWidth
  >
    {userFeedbackStrings.submitButtonText}
  </Button>
);

const UserDetails = ({
  data: { created = "", user },
}: CustomProps<UserFeedback>) => (
  <Box m={3}>
    <Typography
      variant="body2"
      aria-label={userFeedbackStrings.ariaLabels.created}
    >{`${userFeedbackStrings.labels.created}: ${formatDateTime(
      created
    )}`}</Typography>
    <Typography
      variant="body2"
      aria-label={userFeedbackStrings.ariaLabels.user}
    >{`${userFeedbackStrings.labels.user}: ${user?.email}`}</Typography>
  </Box>
);

const BackButton = () => (
  <Button
    type="button"
    startIcon={<ArrowBackIcon />}
    aria-label={userFeedbackStrings.ariaLabels.back}
    component={Link}
    to={paths.userFeedback.list}
  >
    {userFeedbackStrings.labels.back}
  </Button>
);

export const UserFeedbackPage = ({ mode, ...routeProps }: Props) => {
  const { id } = useParams();

  return (
    <PageLayoutBox>
      <ModifyContainer<UserFeedback>
        {...routeProps}
        api={api}
        initialData={{
          responseId: id,
          rating: "",
          wantToBeContacted: false,
          wantToBeContactedAnswer: userFeedbackStrings.contact.noString,
        }}
        componentConfiguration={[
          {
            key: "header",
            direction: "column",
            content: [
              {
                controltype: ControlType.Custom,
                Component: FeedbackHeader,
              },
            ],
          },
          {
            key: "userInfo",
            direction: "column",
            modes: ["view"],
            content: [
              {
                controltype: ControlType.Custom,
                Component: UserDetails,
              },
            ],
          },
          {
            key: "userFields",
            direction: "column",
            content: [
              ratingConfig,
              userFeedbackLabelConfig,
              contactConfig,
              {
                controltype: ControlType.Custom,
                Component: Comments,
              },
              {
                controltype: ControlType.Custom,
                Component: SubmitButton,
                modes: ["create"],
              },
            ],
          },
          {
            key: "backButton",
            direction: "column",
            modes: ["view"],
            content: [
              {
                controltype: ControlType.Custom,
                Component: BackButton,
              },
            ],
          },
        ]}
        constraints={constraints}
        mode={mode}
        redirectPath={paths.landingPage}
        changeState={(state, e) => {
          const { name, value } = e.target;
          if (!name) return state;
          return {
            ...state,
            [name]: value,
          };
        }}
      />
    </PageLayoutBox>
  );
};
