import { Box, Stack, SxProps } from "@mui/material";
import YouTube, { YouTubeProps } from "react-youtube";
import { ControlType } from "../../types/documents/Control";
import { VideoModal } from "../editModals";
import { ChangeHandler } from "./types/Modify";

export type Props = {
  videoId: string;
  editable?: boolean;
  handleChange: ChangeHandler;
  path: string;
  controlId: string;
};

type Component = (props: Props) => JSX.Element;

const videoStyle: SxProps = {
  position: "relative",
  width: "90%",
  aspectRatio: "16/9",
  backgroundColor: "text.secondary",
  overflow: "hidden",
  "& iframe": {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "block",
    top: "0",
    left: "0",
  },
};

export const VideoComponent: Component = ({
  videoId,
  controlId,
  editable,
  handleChange,
  path,
}) => {
  const vidOpts: YouTubeProps["opts"] = {
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Stack direction="row" justifyContent="center">
      <Box role="application" aria-label="video" sx={videoStyle}>
        <YouTube videoId={videoId} opts={vidOpts} />
      </Box>
      {editable && (
        <VideoModal
          key={controlId}
          controlId={controlId}
          video={{ controlId, type: ControlType.Video, videoId }}
          path={path}
          handleChange={handleChange}
        />
      )}
    </Stack>
  );
};
