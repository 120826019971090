import { ChevronLeft as ArrowBackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ModifyContainer } from "../../containers/general/ModifyContainer";
import { paths } from "../../navigation/paths";
import { supportOrFeedbackStrings } from "../../resources/strings/supportOrFeedback";
import { api } from "../../services/supportOrFeedback.service";
import { DropdownOptions, RouteProps } from "../../types";
import { ControlType } from "../../types/documents/Control";
import {
  Area,
  SupportOrFeedback,
} from "../../types/documents/SupportOrFeedback";
import { Button } from "../general/Button";
import { Input } from "../general/controls/Input";
import { Header } from "../general/Header";
import { ContentConfig, CustomProps, Mode } from "../general/types/Modify";
import { PageLayoutBox } from "../layout/PageLayoutBox";

export interface Props extends RouteProps {
  mode: Mode;
}

const areaOptions: DropdownOptions = [
  { value: Area.Feedback, label: supportOrFeedbackStrings.feedback },
  { value: Area.Support, label: supportOrFeedbackStrings.support },
];

const constraints = {
  area: {
    presence: {
      allowEmpty: false,
      message: `^${supportOrFeedbackStrings.area} is required`,
    },
  },
  details: {
    presence: {
      allowEmpty: false,
      message: `^${supportOrFeedbackStrings.details} is required`,
    },
  },
};

const areaConfig: ContentConfig<SupportOrFeedback> = {
  controltype: ControlType.Dropdown,
  name: "area",
  label: supportOrFeedbackStrings.area,
  options: areaOptions,
  "aria-label": supportOrFeedbackStrings.ariaLabels.area,
};

const MAX_LENGTH = 255;

const Details = ({
  handleChange,
  data: { details },
  validationResults,
}: CustomProps<SupportOrFeedback>) => {
  let currentLength = 0;
  if (details) currentLength = details.length;
  return (
    <Input
      config={{
        name: "details",
        label: supportOrFeedbackStrings.details,
        multiline: true,
        rows: 10,
        inputProps: { maxLength: MAX_LENGTH },
        "aria-label": supportOrFeedbackStrings.ariaLabels.details,
        value: details,
        helperText:
          `${currentLength}/${MAX_LENGTH}` +
          `${
            !!validationResults?.details
              ? " " + validationResults?.details[0]
              : ""
          }`,
        error: !!validationResults?.details ?? false,
      }}
      handleChange={handleChange}
    />
  );
};

const SupportOrFeedbackHeader = () => (
  <Header
    sectionHeader={supportOrFeedbackStrings.shortTitle}
    pageHeader={supportOrFeedbackStrings.longTitle}
    caption={supportOrFeedbackStrings.pageDescription}
  />
);

const SubmitButton = () => (
  <Button
    type="submit"
    aria-label={supportOrFeedbackStrings.ariaLabels.send}
    fullWidth
  >
    {supportOrFeedbackStrings.send}
  </Button>
);

const BackButton = () => (
  <Button
    type="button"
    startIcon={<ArrowBackIcon />}
    aria-label={supportOrFeedbackStrings.ariaLabels.back}
    component={Link}
    color="secondary"
    to={paths.landingPage}
  >
    {supportOrFeedbackStrings.back}
  </Button>
);

export const SupportOrFeedbackPage = ({ mode, ...routeProps }: Props) => {
  return (
    <PageLayoutBox>
      <ModifyContainer<SupportOrFeedback>
        {...routeProps}
        api={api}
        initialData={{ area: "", details: "" }}
        componentConfiguration={[
          {
            key: "header",
            direction: "column",
            content: [
              {
                controltype: ControlType.Custom,
                Component: SupportOrFeedbackHeader,
              },
            ],
          },
          {
            key: "supportOrFeedbackFields",
            direction: "column",
            content: [
              areaConfig,
              {
                controltype: ControlType.Custom,
                Component: Details,
              },
              {
                controltype: ControlType.Custom,
                Component: SubmitButton,
                modes: ["create"],
              },
            ],
          },
          {
            key: "backButton",
            direction: "column",
            modes: ["create"],
            content: [
              {
                controltype: ControlType.Custom,
                Component: BackButton,
              },
            ],
          },
        ]}
        constraints={constraints}
        mode={mode}
        redirectPath={paths.landingPage}
        changeState={(state, e) => {
          const { name, value } = e.target;
          if (!name) return state;
          return {
            ...state,
            [name]: value,
          };
        }}
      />
    </PageLayoutBox>
  );
};
