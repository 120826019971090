import { Section } from "./Section";
import { UserData } from "./User";

export enum SurveyResponseStatus {
  InProgress = "In progress",
  Abandoned = "Abandoned",
  Completed = "Completed",
}

export type SurveyHistory = {
  id: string;
  created: string;
  status: SurveyResponseStatus;
};

export type SurveyResponse = {
  id?: string;
  created?: string;
  updated?: string;
  userId: string;
  surveyId: string;
  completedDate?: string;
  sections: Section[];
  user: UserData;
  status: SurveyResponseStatus;
};

export type CreateSurveyResponse = {
  id: string;
  payload: SurveyResponse;
};

export type EmailSurveyResponse = {
  id: string;
};

export type SurveyCompletionChartData = {
  label: string;
  value: number;
};

export type SurveySkipRatesChartData = {
  controlId: string;
  section: string;
  rate: number;
};

export type SurveyChartFilters = {
  created: { from?: string; to?: string };
};
