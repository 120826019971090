import { useNavigate } from "react-router-dom";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import type { Section } from "../../types/documents";
import type { BasePart } from "../../types/documents/BasePart";
import { SectionType } from "../../types/documents/BasePart";
import { useRouterWildcard } from "./useRouterWildcard";

const flattenSectionData = (sections: Section[]) => {
  const flatData: Array<
    | {
        type: "part";
        partId: string;
        path: string;
      }
    | {
        type: "subsection";
        subsectionId: string;
        path: string;
      }
  > = [];

  for (const currentSection of sections) {
    for (const currentSubsection of currentSection.subSections) {
      if (!currentSubsection.parts || !currentSubsection.parts.length) {
        flatData.push({
          type: "subsection",
          subsectionId: currentSubsection.subsectionId,
          path: currentSubsection.path,
        });
      } else {
        for (const currentPart of currentSubsection.parts) {
          flatData.push({
            type: "part",
            partId: currentPart.partId,
            path: currentPart.path,
          });
        }
      }
    }
  }

  return flatData;
};

const useExtractedData = (sections: Section[]) => {
  // take the ID arguments from the url path
  let [sectionId, subsectionId, partId] = useRouterWildcard();

  // set default section ID if one has not been provided
  if (!sectionId && sections[0]) sectionId = sections[0].sectionId;
  let section = sections.find((s) => s.sectionId === sectionId) || {
    id: "",
    longName: "",
    subSections: [],
    stage: 0,
  };
  const { subSections = [] } = section;

  // set default subsection ID if one has not been provided
  if (!subsectionId && subSections[0])
    subsectionId = subSections[0].subsectionId;
  let subsection = subSections.find((s) => s.subsectionId === subsectionId);
  const { parts = [] } = subsection || {};

  // set default part ID if one has not been provided
  if (!partId && parts[0]) partId = parts[0].partId;
  let part = parts.find((p) => p.partId === partId);

  return {
    section,
    subsection,
    part,
    sectionId,
    subsectionId,
    partId,
  };
};

const useSectionNavigation = (sections: Section[], path: string) => {
  const navigate = useNavigate();

  let canMoveBack = false;
  let canMoveForward = false;

  const flatData = flattenSectionData(sections);

  const currentLocation = flatData.findIndex((d) => d.path === path);

  const prevSection = flatData[currentLocation - 1];
  const nextSection = flatData[currentLocation + 1];
  canMoveBack = Boolean(prevSection);
  canMoveForward = Boolean(nextSection);
  const isEndOfSurvey = canMoveForward
    ? nextSection.path.split("/").includes("finish")
    : false;

  const back = () => {
    if (!canMoveBack) return;
    navigate(prevSection?.path ?? "");
  };

  const next = () => {
    if (!canMoveForward) return;
    navigate(nextSection?.path ?? "");
  };

  return { isEndOfSurvey, canMoveBack, canMoveForward, back, next };
};

const generateSectionStrings = (sections: Section[]) => {
  let secStrings: { name: string; path: string }[] = [];

  for (const section of sections) {
    // Section intro
    secStrings.push({ name: section.name, path: `${section.path}/intro` });

    // Ignore subsection intro and feedback sections
    for (const subsection of section.subSections.slice(1, -1)) {
      secStrings.push({ name: subsection.name, path: subsection.path });
    }

    // Add section feedback, but ignore finish section
    if (section.id !== sections[sections.length - 1].id) {
      secStrings.push({
        name: `${section.name} ${strings.sectionNav.secondaryStepLabel}`,
        path: `${section.path}/feedback`,
      });
    }
  }

  return secStrings;
};

export const useSectionJson = (sections: Section[]) => {
  const { section, subsection, part, subsectionId } =
    useExtractedData(sections);
  const { subSections = [], longName } = section;

  let childJson: Pick<
    BasePart,
    "controls" | "type" | "path" | "name" | "description" | "stage"
  > = {
    controls: [],
    type: SectionType.None,
    path: "",
    name: "",
    description: "",
    stage: -1,
  };

  if (part) {
    childJson = { ...part };
  } else if (subsection) {
    childJson = { ...subsection };
  }

  const nonFeedbackOrIntroSubsections = subSections.filter(
    (s) => s.type === SectionType.Question
  );

  const currentQuestion =
    nonFeedbackOrIntroSubsections.findIndex(
      (s) => s.subsectionId === subsectionId
    ) + 1;
  const totalQuestions = nonFeedbackOrIntroSubsections.length;

  const sectionNavProps = useSectionNavigation(sections, childJson.path);

  const sectionStrings = generateSectionStrings(sections);

  return {
    longName,
    currentQuestion,
    totalQuestions,
    subsection,
    part,
    sectionStrings,
    ...childJson,
    ...sectionNavProps,
    uuids: {
      sectionId: section.id ?? "",
      subsectionId: subsection?.id ?? "",
      partId: part?.id ?? "",
    },
  };
};
