import { api } from "../../../services/permissions.service";
import { CognitoUser, Permissions } from "../../../types";
import { useReadQuery } from "../../general/useReadQuery";

const { read } = api;

export const useUserPermissions = (user: CognitoUser | null) => {
  const [permissions, loading, error] = useReadQuery<Permissions>(
    {},
    read,
    user !== null ? "id" : ""
  );
  return { permissions, loading, error };
};
