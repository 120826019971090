import {
  validateId,
  validateLength,
  validateNotEmpty,
} from "../../helpers/validation-helpers";
import { sectionModalStrings as strings } from "../../resources/strings/editModal";
import { SectionType } from "../../types/documents/BasePart";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { Button } from "../general/Button";
import { EditModal } from "../general/EditModal";
import { IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

export type SectionFields = {
  name: string;
  longName: string;
  sectionId: string;
};

interface Props {
  path: string;
  section?: SectionFields;
  handleChange: ChangeHandler;
  addNew?: boolean;
  listOfPaths: string[];
}

type Component = (props: Props) => JSX.Element;

export const SectionModal: Component = ({
  path,
  section: initSection = {
    name: "",
    longName: "",
    sectionId: "",
  },
  handleChange,
  addNew,
  listOfPaths,
}) => {
  const modalTitle = addNew ? strings.modalTitle.add : strings.modalTitle.edit;
  const trigger = addNew ? (
    <Button variant="outlined" size="small" sx={{ lineHeight: 1 }}>
      {strings.modalTitle.add}
    </Button>
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const sectionTemplate = ({ name, longName, sectionId }: SectionFields) => ({
    name,
    longName,
    sectionId,
    path: sectionId,
    subSections: [
      {
        type: SectionType.Introduction,
        name: `Introduction to ${longName}`,
        order: 0,
        path: `${sectionId}/intro`,
        subsectionId: "intro",
        controls: [],
        suggestions: [],
      },
      {
        type: SectionType.Feedback,
        name: `${longName} Feedback`,
        order: 1,
        path: `${sectionId}/feedback`,
        subsectionId: "feedback",
        controls: [],
        suggestions: [],
      },
    ],
  });

  const handleAdd = (fields: SectionFields) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Section,
        actionType: ActionType.Add,
        value: sectionTemplate(fields),
      },
    });
  };

  const handleEdit = (fields: SectionFields) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Section,
        actionType: ActionType.Update,
        value: {
          ...fields,
          path: fields.sectionId,
        },
      },
    });
  };

  const handleDelete = (_: SectionFields) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Section,
        actionType: ActionType.Delete,
        value: {},
      },
    });
  };

  return (
    <EditModal<SectionFields>
      title={modalTitle}
      handleConfirm={addNew ? handleAdd : handleEdit}
      handleDelete={!addNew ? handleDelete : undefined}
      controls={[
        {
          label: strings.longName,
          id: "longName",
          validationFunction: (value) =>
            validateNotEmpty(value) || validateLength(255)(value),
        },
        {
          label: strings.name,
          id: "name",
          validationFunction: (value) =>
            validateNotEmpty(value) || validateLength(255)(value),
        },
        {
          label: strings.id,
          id: "sectionId",
          validationFunction: addNew
            ? (id: string) =>
                validateId(id, listOfPaths) || validateLength(80)(id)
            : undefined,
          disabled: !addNew,
        },
      ]}
      data={initSection}
      trigger={trigger}
    />
  );
};
