import { ChevronRight as ArrowForwardIcon } from "@mui/icons-material";
import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyHistoryList } from "../../containers/surveyResponses/SurveyHistoryList";
import { useIsAuthorised } from "../../hooks/authentication/useIsAuthorised";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useSurveyHistory } from "../../hooks/surveyResponses/useSurveyHistory";
import { paths } from "../../navigation/paths";
import { landingPageStrings as strings } from "../../resources/strings/landingPage";
import { api } from "../../services/surveyresponses.service";
import { RouteProps } from "../../types";
import { SurveyResponse } from "../../types/documents/SurveyResponse";
import { Entities } from "../../types/enums/Entities";
import { Permissions } from "../../types/enums/Permissions";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { AdministrationContent } from "../general/AdministrationContent";
import { Button } from "../general/Button";
import { Loader } from "../general/Loader";
import { SteppedPanels } from "../general/SteppedPanels";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

type IntroProps = {
  canStartSurvey: boolean;
  canGiveFeedbackOrAskForSupport: boolean;
  canViewAdminContent: boolean;
};

const introContainerStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 3,
  textAlign: "left",
  py: 7.5,
};

const purposeContainerStyles: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "left",
  backgroundColor: "common.white",
  color: "text.secondary",
  [theme.breakpoints.up("md")]: {
    height: "75%",
    overflowY: "auto",
  },
  p: 4,
  [theme.breakpoints.down("md")]: {
    px: 2,
  },
  gap: 2,
});

const rootStyles: SxProps<Theme> = (theme) => ({
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    p: 1,
  },
});

const { headers, actions } = strings;

const IntroText = ({
  canStartSurvey,
  canGiveFeedbackOrAskForSupport,
  canViewAdminContent,
}: IntroProps) => {
  const navigate = useNavigate();
  const cancelToken = useCancelToken();
  const [loading, setLoading] = useState(false);
  const onClickStartAssessment = async () => {
    setLoading(true);
    await api.create({} as SurveyResponse, cancelToken);

    navigate(paths.surveyResponses.answer);
  };
  return (
    <>
      <Typography
        aria-label="self-assessment name"
        variant="h6"
        fontWeight="bold"
      >
        {headers.surveyName}
      </Typography>
      <ShowIf show={canStartSurvey}>
        <Typography
          data-testid="main heading"
          aria-label="main heading"
          variant="body1"
          fontWeight="bold"
        >
          {headers.mainHeading}
        </Typography>
        <Typography
          data-testid="sub heading"
          aria-label="sub heading"
          variant="body1"
          fontWeight="bold"
        >
          {headers.subHeading}
        </Typography>
      </ShowIf>
      {headers.body.map((paragraph, index) => (
        <ShowIf show={canStartSurvey}>
          <Typography
            key={index}
            data-testid="body"
            aria-label="body"
            variant="subtitle2"
          >
            {paragraph}
          </Typography>
        </ShowIf>
      ))}
      {headers.adminBody.map((paragraph, index) => (
        <ShowIf show={canViewAdminContent}>
          <Typography
            key={index}
            data-testid="admin-body"
            aria-label="body"
            variant="subtitle2"
          >
            {paragraph}
          </Typography>
        </ShowIf>
      ))}

      <ShowIf show={canStartSurvey}>
        <Button
          color="primary"
          aria-label="start self-assessment"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={onClickStartAssessment}
        >
          <Loader active={loading} inline>
            <>{actions.startSurvey}</>
          </Loader>
        </Button>
      </ShowIf>
      <ShowIf show={canGiveFeedbackOrAskForSupport}>
        <Typography aria-label="sub heading" variant="subtitle2">
          {headers.feedbackAndSupportHeading}
        </Typography>
        <Button
          color="primary"
          aria-label="give feedback or ask for support"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          label={actions.sendFeedbackOrSupport}
          onClick={() => navigate(paths.help)}
        />
      </ShowIf>
    </>
  );
};

export const LandingPageContent: Component = ({ permissions }) => {
  const canViewHistory = useIsAuthorised(
    permissions,
    Entities.Responses,
    Permissions.History
  );

  const [items, loading] = useSurveyHistory(canViewHistory);
  const hasPreviousSurveys = items.length > 0;

  const canViewAdminContent = useIsAuthorised(
    permissions,
    Entities.System,
    Permissions.Admin
  );
  const canStartSurvey = useIsAuthorised(
    permissions,
    Entities.Responses,
    Permissions.Create
  );
  const canGiveFeedbackOrAskForSupport = useIsAuthorised(
    permissions,
    Entities.Feedback,
    Permissions.Send
  );

  return (
    <Grid container gap={1} sx={[rootStyles]}>
      <Grid item xs={12} md={5.5} sx={[introContainerStyles]}>
        <IntroText
          canStartSurvey={canStartSurvey}
          canGiveFeedbackOrAskForSupport={canGiveFeedbackOrAskForSupport}
          canViewAdminContent={canViewAdminContent}
        />
      </Grid>
      {(canViewAdminContent || (canViewHistory && hasPreviousSurveys)) && (
        <Grid item xs={12} md={5.5} sx={[purposeContainerStyles]}>
          <SteppedPanels
            panels={[
              canViewAdminContent && (
                <AdministrationContent permissions={permissions} />
              ),
              canViewHistory && hasPreviousSurveys && (
                <SurveyHistoryList items={items} loading={loading} />
              ),
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};
