type DropdownOptions = Array<{ label: string; value: string }>;

export const findValue = <V extends string | number>(
  value: V,
  options: { label: string; value: V }[]
) => {
  const option = options.find((op) => op.value === value);

  if (option) return option.label;
  return "";
};

export const createDropdownOptions = <
  D extends { [key: string]: any; order?: number }
>(
  options: Array<D>,
  idKey: keyof D,
  textKey: keyof D
): DropdownOptions => {
  let dropdownOptions: DropdownOptions = [];
  const mapDropdownOptions = (option: D) => ({
    label: option[textKey],
    value: option[idKey],
  });

  if (options.some((option) => option.order)) {
    dropdownOptions = options.sort(sortOptions).map(mapDropdownOptions);
  } else {
    dropdownOptions = options.map(mapDropdownOptions);
  }

  return dropdownOptions;
};

export const sortOptions = <D extends { order?: number }>(
  a: D,
  b: D
): number => {
  if (a.order === undefined || b.order === undefined) return 0;

  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

export const displayDropdownValue = <V extends string | number>(
  data: unknown,
  options: { label: string; value: V }[],
  text?: string
): string => {
  const input = Array.isArray(data) ? data : [data];
  return input
    .map((current) => {
      let value: string | number = "";
      if (typeof current === "string" || typeof current === "number") {
        value = current;
      }

      const displayValue = findValue(value, options);
      if (!displayValue && text) return text;
      return displayValue;
    })
    .join(", ");
};
