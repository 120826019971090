import { validateNotEmpty } from "../../helpers/validation-helpers";
import { questionModalStrings as strings } from "../../resources/strings/editModal";
import { ControlType, QuestionControl } from "../../types/documents/Control";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { Button } from "../general/Button";
import { EditModal, EditModalControl } from "../general/EditModal";
import { IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

interface Props {
  path: string;
  controlId: string;
  question?: QuestionControl;
  addNew?: boolean;
  handleChange: ChangeHandler;
}

type Component = (props: Props) => JSX.Element;

export const QuestionModal: Component = ({
  path,
  controlId,
  question: initQuestion = {
    controlId,
    type: ControlType.Question,
    path,
    options: [],
    label: "",
    description: "",
    multiple: false,
    skipped: false,
  },
  handleChange,
  addNew,
}) => {
  const title = addNew ? strings.modalTitle.add : strings.modalTitle.edit;

  const trigger = addNew ? (
    <Button>{strings.modalTitle.add}</Button>
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const handleEdit = (question: QuestionControl) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Control,
        actionType: addNew ? ActionType.Add : ActionType.Update,
        value: question,
      },
    });
  };

  const handleDelete = (question: QuestionControl) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Control,
        actionType: ActionType.Delete,
        value: { controlId: question.controlId },
      },
    });
  };

  return (
    <EditModal<QuestionControl>
      title={title}
      handleConfirm={handleEdit}
      handleDelete={!addNew ? handleDelete : undefined}
      controls={[
        {
          label: strings.label,
          id: "label",
          validationFunction: validateNotEmpty,
        },
        {
          label: strings.description,
          id: "description",
          multiline: true,
        },
        {
          label: strings.multiple,
          id: "multiple",
          type: EditModalControl.Checkbox,
        },
      ]}
      data={initQuestion}
      trigger={trigger}
    />
  );
};
