import { suggestionModalStrings as strings } from "../../resources/strings/editModal";
import { Suggestion } from "../../types/documents";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { EditModal, EditModalControl } from "../general/EditModal";
import { IconButtonAdd, IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

interface Props {
  index: number;
  path?: string;
  suggestion?: Suggestion;
  addNew?: boolean;
  handleChange: ChangeHandler;
}

type Component = (props: Props) => JSX.Element;

export const SuggestionModal: Component = ({
  index,
  path,
  suggestion: initSuggestion = {
    title: "",
    description: "",
    image: "",
    url: "",
  },
  addNew,
  handleChange,
}) => {
  const modalTitle = addNew ? strings.modalTitle.add : strings.modalTitle.edit;
  const trigger = addNew ? (
    <IconButtonAdd entity={strings.entity} />
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const handleEdit = (suggestion: Suggestion) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Suggestion,
        actionType: addNew ? ActionType.Add : ActionType.Update,
        value: { index, ...suggestion },
      },
    });
  };

  const handleDelete = (_suggestion: Suggestion) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Suggestion,
        actionType: ActionType.Delete,
        value: { index },
      },
    });
  };

  return (
    <EditModal<Suggestion>
      title={modalTitle}
      controls={[
        {
          label: strings.title,
          id: "title",
        },
        {
          label: strings.description,
          id: "description",
          multiline: true,
        },
        {
          label: strings.image,
          id: "image",
          type: EditModalControl.ImageUpload,
        },
        { label: strings.url, id: "url" },
      ]}
      data={initSuggestion}
      handleConfirm={handleEdit}
      handleDelete={!addNew ? handleDelete : undefined}
      trigger={trigger}
    />
  );
};
