import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import type { ChangeHandler, RadioGroup as TRadioGroup } from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TRadioGroup<D>, "controltype">;
  handleChange: ChangeHandler;
  errorMessage?: string;
}

export const RadioControl = <D extends object>({
  config: input,
  handleChange,
  errorMessage,
}: Props<D>): JSX.Element => {
  const { name, label, options = [], disabled, ...config } = input;

  const renderOptions = (radioOptions: typeof options) => {
    return radioOptions.map((option, index) => (
      <FormControlLabel
        control={
          <Radio
            required={option.required}
            disabled={disabled || option.disabled}
          />
        }
        key={`${option.value}-${index}`}
        value={option.value}
        label={option.label}
      />
    ));
  };

  return (
    <>
      <InputLabel id={name} shrink>
        {label}
      </InputLabel>
      <RadioGroup
        {...config}
        aria-labelledby={name}
        title={name}
        onChange={handleChange}
        name={name}
      >
        {renderOptions(options)}
      </RadioGroup>
      {errorMessage ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </>
  );
};
