import { Grid, Stack, Typography } from "@mui/material";
import { SectionType } from "../../types/documents/BasePart";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { PageModal } from "../editModals/PageModal";
import { ChangeHandler } from "./types/Modify";

export interface Props {
  sectionHeader: string;
  pageHeader?: string;
  caption?: string;
  editable?: boolean;
  handleChange?: ChangeHandler;
  type?: SectionType;
  isSubsection?: boolean;
  path?: string;
  listOfPaths?: string[];
}
type Component = (props: Props) => JSX.Element;

export const Header: Component = ({
  sectionHeader,
  pageHeader,
  caption,
  editable,
  handleChange,
  type,
  isSubsection,
  path,
  listOfPaths = [],
}) => {
  return (
    <Grid
      container
      flexDirection="column"
      alignContent="center"
      alignItems="center"
      gap={2}
    >
      <Typography
        aria-label="section header"
        role="heading"
        variant="caption"
        sx={[
          (theme) => ({
            textAlign: "center",
            backgroundColor: "primary.main",
            color: "common.white",
            minWidth: theme.spacing(16),
            p: 0.5,
            px: 1.5,
          }),
        ]}
        color="primary"
      >
        {sectionHeader}
      </Typography>
      <ShowIf show={pageHeader || editable}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h5"
            aria-label="page header"
            color="primary"
            fontWeight="bold"
            sx={[{ textAlign: "center" }]}
          >
            {pageHeader}
          </Typography>
          {editable && handleChange && (
            <PageModal
              path={path ?? ""}
              page={{
                name: pageHeader ?? "",
                description: caption ?? "",
                pageId: path?.split("/").pop() ?? "",
              }}
              type={type}
              isSubsection={isSubsection}
              handleChange={handleChange}
              listOfPaths={listOfPaths}
            />
          )}
        </Stack>
      </ShowIf>
      <Grid item xs={12} md={8}>
        <Typography
          variant="body2"
          aria-label="caption"
          sx={[{ textAlign: "center" }]}
          role="caption"
        >
          {caption}
        </Typography>
      </Grid>
    </Grid>
  );
};
