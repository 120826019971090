import { Box, SxProps, Theme } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "./Footer";

interface Props {}
type Component = (props: Props) => JSX.Element;

const layoutStyles: SxProps<Theme> = (_theme) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "common.pink",
  color: "common.white",
  overflow: "auto",
});

const containerStyles: SxProps<Theme> = (theme) => ({
  flexGrow: 1,
  [theme.breakpoints.up("md")]: {
    px: 38.75,
    py: 4.5,
  },
  display: "flex",
  flexDirection: "row",
  zIndex: 1,
});

export const PageLayoutContainer: Component = () => {
  const location = useLocation();

  useEffect(() => {
    return () => {
      window.scrollTo({ top: window.screenTop });
    };
  }, [location]);

  return (
    <Box sx={[layoutStyles]}>
      <Box sx={[containerStyles]}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};
