import { Menu as MenuIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  drawerClasses,
  listItemClasses,
  styled,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Permissions } from "../../types";
import { ShowIf, ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { MenuItem } from "./listItems";

export interface Props {
  children: JSX.Element;
  menuItems: MenuItem[];
  permissions: Permissions | null;
  logo: string;
  additionalContent?: JSX.Element;
  showSidebar?: boolean;
}
type Component = (props: Props) => JSX.Element;

interface MenuItemsProps {
  menuItems: MenuItem[];
  permissions: Permissions | null;
}
type MenuItemsComponent = (props: MenuItemsProps) => JSX.Element;

const drawerWidth = 240;
const smallDrawerWidth = 0;

const rootStyles: SxProps<Theme> = {
  display: "flex",
  width: "100%",
  height: "100%",
};

const toolbarStyles: SxProps<Theme> = {
  paddingRight: "1.5rem", // keep right padding when drawer closed
};

const toolbarHeaderStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 0.5rem",
  margin: "1.5rem",
};

const appBarStyles: SxProps<Theme> = (theme) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const appBarShiftStyles: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up("xs")]: {
    marginLeft: smallDrawerWidth,
    width: `calc(100% - ${smallDrawerWidth}px)`,
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const menuButtonStyles: SxProps<Theme> = {
  marginRight: "2.25rem",
};

const flexSpacerStyles: SxProps<Theme> = {
  flexGrow: 1,
};

const drawerPaperStyles: SxProps<Theme> = (theme) => ({
  [`& .${drawerClasses.paper}`]: {
    position: "relative",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("xs")]: {
      width: smallDrawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const drawerPaperCloseStyles: SxProps<Theme> = (theme) => ({
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("xs")]: {
    width: drawerWidth,
  },
  [`& .${drawerClasses.paper}`]: {
    [theme.breakpoints.up("xs")]: {
      width: drawerWidth,
    },
  },
  [theme.breakpoints.up("md")]: {
    width: theme.spacing(7),
  },
});

const appBarSpacerStyles: SxProps<Theme> = (theme) => theme.mixins.toolbar;

const contentStyles: SxProps<Theme> = {
  flexGrow: 1,
  height: "100%",
  width: "100%",
  overflow: "auto",
};

const containerStyles: SxProps<Theme> = (theme) => ({
  width: "100%",
  // offset the height by the height defined in the toolbar mixin
  height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  // the mixin contains nested media queries too accessing the `minHeight` property directly
  // isn't enough
  // having these hard coded isnt ideal but not sure how else to deal with it
  [theme.breakpoints.up("xs")]: {
    "@media (orientation: landscape)": {
      height: `calc(100% - 48px)`,
    },
  },
  [theme.breakpoints.up("sm")]: {
    height: `calc(100% - 80px)`,
  },
});

const mediaStyles: SxProps<Theme> = (theme) => ({
  backgroundSize: "contain",
  width: "192px",
  height: "64px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
});

const hideImageStyles: SxProps<Theme> = {
  display: "none",
};

const selectedItemStyles: SxProps<Theme> = (theme) => ({
  [`&.${listItemClasses.selected}`]: {
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const Div = styled("div")({});

// as of writing, there seems to be a bug with the toolbar mixin when used with sx
// apply it using the styled API instead
const ToolbarDiv = styled("div")(({ theme }) => theme.mixins.toolbar);

const Main = styled("main")({});

const MenuItems: MenuItemsComponent = ({ menuItems, permissions }) => {
  const [selected, setSelected] = useState(-1);
  const navigate = useNavigate();

  return (
    <List>
      <div>
        {menuItems.map(({ label, Icon, itemPermission, link }, index) => {
          const isSelected = selected === index;
          const handleClick = () => {
            setSelected(index);
            navigate(link);
          };
          const Content = (
            <ListItem
              sx={[selectedItemStyles]}
              button
              selected={isSelected}
              onClick={handleClick}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          );

          return (
            <Fragment key={`${label}-${index}`}>
              {itemPermission ? (
                <ShowIfAuthorised
                  userPermissions={permissions}
                  {...itemPermission}
                >
                  {Content}
                </ShowIfAuthorised>
              ) : (
                <>{Content}</>
              )}
            </Fragment>
          );
        })}
      </div>
    </List>
  );
};

export const MenuComponent: Component = ({
  children,
  menuItems,
  permissions,
  logo,
  additionalContent,
  showSidebar = false,
}) => {
  const [open, setOpen] = useState(true);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Div sx={[rootStyles]} role="menu">
      <AppBar
        position="absolute"
        color="inherit"
        sx={[appBarStyles, open && showSidebar && appBarShiftStyles]}
      >
        <Toolbar sx={[toolbarStyles]}>
          <CardMedia
            sx={[mediaStyles]}
            image={logo}
            component={Link}
            target="_blank"
            href="https://thedatalab.com"
          />
          <ShowIf show={showSidebar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggle}
              sx={[menuButtonStyles]}
              data-testid="toggle-button"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </ShowIf>
          <Box color="inherit" sx={[flexSpacerStyles]}></Box>
          {additionalContent ? additionalContent : null}
        </Toolbar>
      </AppBar>
      <ShowIf show={showSidebar}>
        <Drawer
          variant="permanent"
          sx={[drawerPaperStyles, !open && drawerPaperCloseStyles]}
          open={open}
          data-testid="drawer"
        >
          <ToolbarDiv sx={[toolbarHeaderStyles]}>
            <CardMedia
              sx={[mediaStyles, !open && hideImageStyles]}
              image={logo}
            />
          </ToolbarDiv>
          <Divider hidden />
          <MenuItems menuItems={menuItems} permissions={permissions} />
        </Drawer>
      </ShowIf>
      <Main sx={[contentStyles]}>
        <Div sx={[appBarSpacerStyles]} />
        <Container maxWidth={false} disableGutters sx={[containerStyles]}>
          {children}
        </Container>
      </Main>
    </Div>
  );
};
