import type { Props as FeedbackComp } from "../components/general/FeedbackComponent";
import { Part, Subsection } from "../types/documents";
import { SectionType } from "../types/documents/BasePart";
import { Control, ControlType, Feedback } from "../types/documents/Control";
import type { Suggestion } from "../types/documents/Suggestion";

type Item = FeedbackComp["items"][0];
type Content = Item["content"][0];

type InputFeedback = {
  title: string;
  text: string;
  suggestion?: Suggestion;
  selected: boolean;
}[];

const formatFeedback = (sectionName: string, feedback: InputFeedback) => {
  const content = [] as Content[];
  const suggestions = [] as Suggestion[];

  feedback.forEach((fb) => {
    content.push({ heading: fb.title, body: fb.text });
    if (fb.suggestion) {
      suggestions.push(fb.suggestion);
    }
  });

  const item: Item = {
    title: sectionName,
    content,
    // TODO: find where this image comes from
    image: { src: "", alt: "" },
  };
  const suggestionArray = {
    title: sectionName,
    suggestions,
  };
  return {
    item: item,
    suggestions: suggestionArray,
  };
};

export const parseFeedback = <T extends Part | Subsection>(
  current: T,
  sectionName: string,
  feedback: InputFeedback
): T => {
  const { item: newItem } = formatFeedback(sectionName, feedback);

  const { suggestions: newSuggestion } =
    formatFeedback(sectionName, feedback) || undefined;

  let items: Item[] = [newItem];
  let suggestions = [] as { title: string; suggestions: Suggestion[] }[];

  if (newSuggestion) {
    suggestions = [newSuggestion];
  }
  const feedbackControl = current?.controls.find(
    (control) => control.type === ControlType.Feedback
  );

  if (feedbackControl && feedbackControl.type === ControlType.Feedback) {
    const prevSuggestions = feedbackControl.suggestions;

    const prevItems = feedbackControl.items;

    if (prevItems.length > 0) {
      items = [
        ...prevItems.filter((item) => item.title !== newItem.title),
        newItem,
      ];
    }
    if (prevSuggestions && prevSuggestions.length > 0) {
      suggestions = [
        ...prevSuggestions.filter(
          (suggestion) => suggestion.title !== newSuggestion.title
        ),
        newSuggestion,
      ];
    }
  }
  return {
    ...current,
    controls: [
      {
        type: ControlType.Feedback,
        controlId: "feedback",
        items: items,
        suggestions: suggestions,
      },
    ],
  };
};

export const extractFeedbackBasedOnSelectedValue = (
  controls: Control[] = []
) => {
  let feedbackArrayToFormat: Feedback[] = [];
  controls.forEach((control) => {
    if (control.type === ControlType.Question) {
      const selectedValues = control.value;
      control.options.forEach((option) => {
        let feedback;
        if (selectedValues?.includes(option.value)) {
          feedback = option.feedback.filter((item) => item.selected);
        } else {
          feedback = option.feedback.filter((item) => !item.selected);
        }
        feedbackArrayToFormat.push(...feedback);
      });
    }
  });
  return feedbackArrayToFormat;
};

export const parseFeedbackFromSections = <T extends Subsection>(
  current: T,
  previous: T[]
): T => {
  let newItems: Item[] = [];
  let finalItems: Item[] = [];
  let suggestions = [] as { title: string; suggestions: Suggestion[] }[];
  let finalSuggestions = [] as { title: string; suggestions: Suggestion[] }[];

  previous.forEach((previousSubsection) => {
    const feedbackPart = previousSubsection.parts?.find(
      (part) => part.type === SectionType.Feedback
    );
    feedbackPart?.controls.forEach((control) => {
      if (control.type === ControlType.Feedback) {
        suggestions.push(
          formatFeedbackSuggestionFromPreviousParts(
            control,
            previousSubsection.name
          )
        );
        control.items.forEach((item) => {
          const index = newItems.findIndex((i) => i.title === item.title);
          if (index === -1)
            newItems.push(
              formatFeedbackContentFromPreviousParts(
                item,
                previousSubsection.name
              )
            );
        });
      }
    });
  });
  const feedbackControl = current.controls.find(
    (control) => control.type === ControlType.Feedback
  );
  if (feedbackControl && feedbackControl.type === ControlType.Feedback) {
    finalItems = newItems;
    finalSuggestions = suggestions;
  }
  return {
    ...current,
    controls: [
      {
        type: ControlType.Feedback,
        controlId: "feedback",
        items: finalItems,
        suggestions: finalSuggestions,
      },
    ],
  };
};

export const formatFeedbackContentFromPreviousParts = (
  feedback: Item,
  sectionName: string
) => {
  const newItem: Item = {
    title: `${sectionName} - ${feedback.title}`,
    content: [],
    image: { src: "", alt: "" },
  };
  feedback.content.forEach((fbC) =>
    newItem.content.push({
      heading: fbC.heading,
      body: fbC.body,
    })
  );
  return newItem;
};

export const formatFeedbackSuggestionFromPreviousParts = (
  control: Control,
  sectionName: string
) => {
  let suggestions = [] as Suggestion[];

  if (control.type === ControlType.Feedback) {
    control.suggestions?.forEach((s) => {
      if (s.suggestions) {
        suggestions = [...suggestions, ...s.suggestions];
      }
    });
  }
  const suggestionArray = {
    title: sectionName,
    suggestions,
  };
  return suggestionArray;
};
