import {
  Help,
  HelpOutline,
  Info,
  InfoOutlined,
  PlayCircleFilled,
  PlayCircleOutline,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { NavItem } from "../../components/general/NavStepper";
import { api } from "../../services/surveys.service";
import { SectionType } from "../../types/documents/BasePart";
import { Survey } from "../../types/documents/Survey";
import { useReadQuery } from "../general/useReadQuery";

const infoIcon = {
  Active: Info,
  Inactive: InfoOutlined,
  color: "common.blue",
};

const helpIcon = {
  Active: Help,
  Inactive: HelpOutline,
  color: "common.green",
};

const playIcon = {
  Active: PlayCircleFilled,
  Inactive: PlayCircleOutline,
  color: "common.pink",
};

const getIconForType = (type: string) => {
  let icon = infoIcon;

  switch (type) {
    case SectionType.Introduction:
      icon = playIcon;
      break;
    case SectionType.Question:
      icon = helpIcon;
      break;
    default:
      break;
  }
  return icon;
};

const buildNavItems = (survey: Survey) => {
  const navItems: NavItem[] = [];

  survey.sections.forEach((section) => {
    const navItem: NavItem = {
      name: section.name,
      longName: section.longName,
      path: section.path,
      subItems: [],
    };

    section.subSections.forEach((subsection) => {
      const subItem: NavItem = {
        name: subsection.name,
        path: subsection.path,
        icon: undefined,
        subsection,
        subItems: [],
      };

      if (subsection.type === SectionType.None) {
        subsection.parts?.forEach((part) => {
          const icon = getIconForType(part.type);

          subItem.subItems?.push({
            name: part.name,
            path: part.path,
            icon,
          });

          delete subItem.icon;
        });
      } else {
        subItem.icon = getIconForType(subsection.type);
        delete subItem.subItems;
      }

      navItem.subItems?.push(subItem);
    });
    navItems.push(navItem);
  });

  return navItems;
};

export const useAdminPanelSideItems = () => {
  const [item, loading, error] = useReadQuery<Survey>(
    {
      version: 0,
      userId: "system",
      name: "Default",
      sections: [],
      published: false,
    },
    api.getUnpublishedSurvey,
    "id"
  );

  const [navItems, setNavItems] = useState<NavItem[]>([]);

  useEffect(() => {
    setNavItems(buildNavItems(item));
  }, [item]);

  const updateNavItems = (newSurvey: Survey) => {
    setNavItems(buildNavItems(newSurvey));
  };

  const activePath =
    navItems[0] &&
    navItems[0].subItems &&
    navItems[0].subItems.length > 0 &&
    navItems[0].subItems[0].path !== undefined
      ? navItems[0].subItems[0].path
      : "";

  return { item, navItems, activePath, loading, error, updateNavItems };
};
