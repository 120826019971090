import { DateTime, DateTimeFormatOptions, LocaleOptions } from "luxon";

const parseDate = (dateString: string) => DateTime.fromISO(dateString);

const dateOptions: LocaleOptions & DateTimeFormatOptions = {
  locale: "en-gb",
  dateStyle: "short",
};

export const formatDateTime = (dateString: string) => {
  const formattedString = parseDate(dateString).toLocaleString({
    ...dateOptions,
    timeStyle: "short",
  });
  return formattedString;
};

export const formatDate = (dateString: string) => {
  const formattedString = parseDate(dateString).toLocaleString({
    ...dateOptions,
  });
  return formattedString;
};

export const formatDetailedDate = (dateString: string) => {
  const date = parseDate(dateString);
  return `${date.toFormat("cccc dd LLLL yyyy")} at ${date.toFormat("T")}`;
};

export const toDateFormat = (date: DateTime | null) =>
  date?.toISO({
    suppressMilliseconds: true,
  }) || "";
