import { ListItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { surveyResponseStrings as strings } from "../../resources/strings/surveyResponses";
import { SurveyResponseStatus } from "../../types/documents/SurveyResponse";
import { Button } from "../general/Button";

interface Props {
  text: string;
  path: string;
  status?: SurveyResponseStatus;
}
type Component = (props: Props) => JSX.Element;

export const Card: Component = ({ path, text, status }) => {
  const navigate = useNavigate();
  const inProgress = status && status === SurveyResponseStatus.InProgress;

  const handleClick = () => {
    navigate(path);
  };

  return (
    <ListItem
      sx={{
        backgroundColor: "common.lightGrey",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: 3,
      }}
      aria-label="card"
    >
      <Typography color="primary" fontWeight="bold">
        {text}
      </Typography>
      <Button
        label={inProgress ? strings.labels.resume : strings.labels.view}
        aria-label={
          inProgress ? strings.ariaLabels.resume : strings.ariaLabels.view
        }
        color="secondary"
        onClick={handleClick}
      />
    </ListItem>
  );
};
