import type { Control } from "./Control";
import type { Suggestion } from "./Suggestion";

export enum SectionType {
  Introduction = "Introduction",
  Question = "Question",
  Feedback = "Feedback",
  End = "End",
  None = "None",
}

export type BasePart = {
  id?: string;
  created?: string;
  updated?: string;
  type: SectionType;
  path: string;
  order: number;
  controls: Control[];
  name: string;
  description?: string | null;
  suggestions: Suggestion[];
  stage: number;
};
