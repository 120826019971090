export const surveyResponseStrings = {
  header: { surveyResponses: "Self-Assessment Responses" },
  labels: {
    view: "View",
    resume: "Resume",
    back: "Back",
    next: "Next",
    pause: "Pause",
    skip: "Skip",
    submit: "Submit Assessment",
    finish: "Finish Assessment",
    user: "User Email",
    created: "Created",
    updated: "Updated",
    completedDate: "Date of Completion",
    download: "Download PDF",
    status: "Status",
    search: "Search",
    reset: "Reset",
    completedFrom: "Completed From",
    completedTo: "Completed To",
    updatedFrom: "Updated From",
    updatedTo: "Updated To",
    createdFrom: "Created From",
    createdTo: "Created To",
  },
  skipModal: {
    title: "Are you sure?",
    content:
      "You can decide to skip questions at any time, but please note that this will impact your personalised feedback in both the individual section and final assessment reports.",
    confirmText: "Yes",
    declineText: "No",
  },
  sectionNav: {
    secondaryStepLabel: "Feedback",
    finalStepLabel: "Finish",
  },
  ariaLabels: {
    back: "back",
    next: "next",
    pause: "pause",
    submit: "submit",
    finish: "finish self-assessment",
    download: "download response pdf",
    view: "view",
    resume: "resume",
    descriptionSelect: "description select",
    responseListSearchBar: "self-assessment response list search bar",
    responseListTable: "self-assessment response list table",
    skipRatesListTable: "skip rates list table",
  },
  actionsBox: {
    questionTitle: "Help Me Frame My Answer",
    feedbackTitle: "Helpful Resources",
  },
  question: {
    selectAll: "Select all that apply",
    selectOne: "Select the one that best describes your organisation",
  },
  completionChart: {
    title: "Self-Assessment completion comparison",
    completed: "Completed",
    inProgress: "In progress",
    abandoned: "Abandoned",
  },
  skipRatesChart: {
    header: "Questions with the highest skip rates (>30%)",
    question: "QUESTION",
    section: "SECTION/SUB-SECTION",
    rate: "SKIP RATE (%)",
  },
};
