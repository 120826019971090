import { Header } from "../../components/general/Header";
import { Loader } from "../../components/general/Loader";
import { SurveyHistoryCardList } from "../../components/surveyResponses/SurveyHistoryCardList";
import { landingPageStrings as strings } from "../../resources/strings/landingPage";
import { SurveyHistory } from "../../types/documents/SurveyResponse";

interface Props {
  items: SurveyHistory[];
  loading: boolean;
}
type Component = (props: Props) => JSX.Element;

export const SurveyHistoryList: Component = ({ items, loading }) => {
  return (
    <>
      <Header
        sectionHeader={strings.headers.surveyHistory}
        caption={strings.surveyHistory.caption}
      />
      <Loader inline active={loading}>
        <SurveyHistoryCardList surveyHistoryItems={items} />
      </Loader>
    </>
  );
};
