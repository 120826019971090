import { useEffect, useState } from "react";
import { logger } from "../../helpers/log-helpers";
import type { QueryFunction } from "../../types/API";
import { HooksLogger } from "../hooks-logger";
import { useCancelToken } from "./useCancelToken";

const hooksLogger = new HooksLogger("useQuery");

export const useQuery = <D extends object, B = {}>(
  defaultValue: Array<D>,
  queryFunction: QueryFunction<D, B>,
  // be advised that if this body argument isnt from state or is defined
  // inside the scope of the component, it will likely cause an infinite loop
  body: B
) => {
  const [items, setItems] = useState<Array<D>>(() => defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const cancelToken = useCancelToken();

  useEffect(() => {
    const query = async () => {
      hooksLogger.request("Getting data");

      setLoading(true);
      try {
        const { items = [] } = await queryFunction(body, cancelToken);

        setItems(items);
        setLoading(false);

        hooksLogger.success(items);
      } catch (e) {
        if (cancelToken.reason) return;

        const message = logger.error(e);
        setLoading(false);
        setError(message);
        hooksLogger.error(message);
      }
    };

    query();
  }, [queryFunction, body, cancelToken]);

  return [items, loading, error] as const;
};
