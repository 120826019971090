import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { AdapterLuxon as Adapter } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import {
  BrowserRouter,
  MemoryRouter,
  MemoryRouterProps,
} from "react-router-dom";
import { theme } from "../styles/theme";

const locale = DateTime.local().locale;

const BaseWrappers: React.FC = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={Adapter} adapterLocale={locale}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

// component for Router, store, providers etc.
// will be used for main app as well as any unit tests
export const Wrappers: React.FC = (props) => {
  return (
    <BrowserRouter>
      <BaseWrappers {...props} />
    </BrowserRouter>
  );
};

export const MemoryRouterWrappers: React.FC<MemoryRouterProps> = ({
  children,
  ...props
}) => {
  return (
    <MemoryRouter {...props}>
      <BaseWrappers>{children}</BaseWrappers>
    </MemoryRouter>
  );
};
