import { ExitToApp as SignOutIcon } from "@mui/icons-material";
import { Button, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import logo from "../../assets/svgs/data-lab-logo.svg";
import { signOut } from "../../libs/auth";
import { appStrings as strings } from "../../resources/strings/app";
import { Permissions } from "../../types";
import { menuItems } from "./listItems";
import { MenuComponent } from "./MenuComponent";

interface Props {
  user: object | null;
  permissions: Permissions | null;
}
type Component = (props: Props) => JSX.Element;

export const AppLayout: Component = ({ permissions }) => {
  const handleSignOut = () => signOut();
  const additionalContent = (
    <Button color="inherit" onClick={handleSignOut}>
      {strings.labels.signOut}
      <SignOutIcon />
    </Button>
  );

  return (
    <>
      <CssBaseline />
      <MenuComponent
        menuItems={menuItems}
        permissions={permissions}
        logo={logo}
        additionalContent={additionalContent}
      >
        <Outlet />
      </MenuComponent>
    </>
  );
};
