export const paths = {
  landingPage: "/",
  notFound: "*",
  privacyAgreement: "/privacyAgreement",
  auth: {
    login: "/login",
    passwordReset: "/passwordReset",
    signUp: "/signUp",
  },
  dashboard: "/",
  help: "/help",
  users: {
    list: "/users",
    create: "/users/create",
    view: (id: string) => `/users/view/${id}`,
  },
  settings: {
    list: "/settings",
  },
  surveyResponses: {
    answer: "/answer",
    view: (id: string) => `/answers/view/${id}`,
    update: (id: string) => `/answers/update/${id}`,
    list: "/responses",
  },
  surveys: {
    adminPanel: `/admin`,
  },
  userFeedback: {
    list: "/feedback",
    view: (id: string) => `/feedback/view/${id}`,
    create: (id: string) => `/feedback/create/${id}`,
  },
};
