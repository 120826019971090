import type { DropdownOptions, RadioGroupOptions } from "../../types";
import { Rating } from "../../types/documents/UserFeedback";
import { userFeedbackStrings } from "../strings/userFeedback";

export const ratingOptions: DropdownOptions = [
  { value: Rating.Useful, label: userFeedbackStrings.rating.useful },
  {
    value: Rating.ThoughtProvoking,
    label: userFeedbackStrings.rating.thoughtProvoking,
  },
  { value: Rating.Unhelpful, label: userFeedbackStrings.rating.unhelpful },
];

export const contactOptions: RadioGroupOptions = [
  {
    value: userFeedbackStrings.contact.yesString,
    label: userFeedbackStrings.contact.yesString,
    required: true,
  },
  {
    value: userFeedbackStrings.contact.noString,
    label: userFeedbackStrings.contact.noString,
    required: true,
  },
];
