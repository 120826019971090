import { Add, Delete, Edit } from "@mui/icons-material";
import { IconButton as MuiIconButton, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IconButtonProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  name: string;
  colour: string;
  onClick?: () => void;
  stopPropagation?: boolean;
}

type Component = (props: IconButtonProps) => JSX.Element;

export const IconButton: Component = ({
  icon: Icon,
  name,
  colour: backgroundColor,
  onClick,
  stopPropagation,
}) => (
  <div>
    <MuiIconButton
      size="small"
      aria-label={name}
      sx={{
        color: "white",
        backgroundColor,
        "&:hover": { backgroundColor, transform: "scale(1.1)" },
      }}
      onClick={(event) => {
        stopPropagation && event.stopPropagation();
        onClick?.();
      }}
    >
      <Icon sx={[{ fontSize: "1rem" }]} />
    </MuiIconButton>
  </div>
);

interface Props {
  onClick?: () => void;
  stopPropagation?: boolean;
  entity: string;
}

export const IconButtonAdd = ({ entity, ...props }: Props) => (
  <IconButton
    name={`new ${entity}`}
    icon={Add}
    colour={"common.green"}
    {...props}
  />
);

export const IconButtonEdit = ({ entity, ...props }: Props) => (
  <IconButton
    name={`edit ${entity}`}
    icon={Edit}
    colour={"common.pink"}
    {...props}
  />
);

export const IconButtonDelete = ({ entity, ...props }: Props) => (
  <IconButton
    name={`delete ${entity}`}
    icon={Delete}
    colour="common.red"
    {...props}
  />
);
