import { Box, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { modalImageUploadStrings as strings } from "../../resources/strings/modalImageUploader";
import { api } from "../../services/surveys.service";
import { Button } from "./Button";

export type Props = {
  imageSrc: string;
  updateField: any;
  fieldId: string;
};

export const ModalImageUpload = ({ imageSrc, updateField, fieldId }: Props) => {
  const [imageFile, setImageFile] = useState<string>();
  const cancelToken = useCancelToken();

  const setImageById = useCallback(
    async (imageId: string) => {
      setImageFile(imageId);
      try {
        const imageUrl = await api.downloadImage(imageId, cancelToken);
        setImageFile(imageUrl);
      } catch (e) {
        logger.error(e);
      }
    },
    [cancelToken]
  );

  const handleUpload = (file: File) => {
    const data = api.uploadImage(file, cancelToken);
    return data;
  };

  const handleChange =
    (e: ChangeEvent<HTMLInputElement>) => async (fieldId: string) => {
      let imageId = "";
      if (e.target.files) {
        const { key } = await handleUpload(e.target.files[0]);
        imageId = key;
      }
      await setImageById(imageId);
      const event = { target: { value: `${imageId}` } };
      return updateField(fieldId)(event);
    };

  const handleReset = async () => {
    setImageFile("");
    const event = { target: { value: "" } };
    return updateField(fieldId)(event);
  };

  useEffect(() => {
    if (imageSrc) {
      setImageById(imageSrc);
    }
  }, [imageSrc, setImageById]);

  return (
    <>
      {!imageFile && (
        <Typography variant="body1" sx={[{ py: 4 }]}>
          {strings.noImage}
        </Typography>
      )}
      <Box
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 1,
            p: 2,
          },
        ]}
      >
        {imageFile && (
          <Box
            component="img"
            sx={[
              {
                aspectRatio: "4/3",
                maxWidth: 1 / 2,
                objectFit: "contain",
                py: 2,
              },
            ]}
            src={imageFile}
            alt={imageSrc.split(".")[0]}
          />
        )}
        <Box
          sx={[
            { display: "flex", flexDirection: "row", justifyItems: "center" },
          ]}
        >
          <label htmlFor="upload file">
            <input
              aria-label="image upload"
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => handleChange(e)(fieldId)}
              id="upload file"
            />
            <Button component="span" sx={[{ mx: 2 }]}>
              {strings.buttons.upload}
            </Button>
          </label>

          <Button
            component="label"
            onClick={handleReset}
            label={strings.ariaLabels.clearButton}
          >
            {strings.buttons.clear}
          </Button>
        </Box>
      </Box>
    </>
  );
};
