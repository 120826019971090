import { Grid, Stack, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { surveyResponseStrings } from "../../resources/strings/surveyResponses";
import { ControlType, QuestionOption } from "../../types/documents/Control";
import { QuestionModal, QuestionOptionModal } from "../editModals";
import type { ChangeHandler } from "./types/Modify";

export interface Props {
  controlId: string;
  label: string;
  description: string;
  options: QuestionOption[];
  handleChange: ChangeHandler;
  multiple?: boolean;
  skipped?: boolean;
  path: string;
  value?: any[];
  disabled?: boolean;
  editable?: boolean;
}
type Component = (props: Props) => JSX.Element;

const Input = styled("input")({});

const optionColour = "common.purple";
export const SurveyAnswer: Component = ({
  controlId,
  label,
  description,
  options,
  handleChange,
  multiple,
  skipped = false,
  path,
  value: initialValues,
  disabled,
  editable,
}) => {
  const [values, setValues] = useState<any[]>(initialValues ?? []);
  const [feedback, setFeedback] = useState<any[]>([]);

  useEffect(() => {
    !editable &&
      handleChange({
        target: { name: path, value: { controlId, values, feedback } },
      });
  }, [controlId, values, handleChange, path, feedback, editable]);

  const handleClick = (newValue: any, newFeedback?: any) => {
    setValues((currentValues) => {
      let updatedValues: any[] = [];

      if (currentValues.includes(newValue)) {
        updatedValues = currentValues.filter((val) => val !== newValue);
      } else if (multiple) {
        updatedValues = [...currentValues, newValue];
      } else {
        updatedValues = [newValue];
      }

      return updatedValues;
    });

    if (newFeedback) {
      setFeedback(newFeedback);
    }
  };

  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      role="listbox"
      aria-label="assessment answer"
    >
      <Stack direction="row" spacing={1}>
        <Typography
          component="label"
          variant="subtitle1"
          color="primary"
          fontWeight="bold"
          aria-label="question"
        >
          {label}
        </Typography>
        {editable && (
          <>
            <QuestionModal
              key={controlId}
              controlId={controlId}
              question={{
                controlId,
                type: ControlType.Question,
                path,
                description,
                label,
                options,
                multiple,
                skipped,
              }}
              path={path}
              handleChange={handleChange}
            />
            <QuestionOptionModal
              key={options.length}
              controlId={controlId}
              options={options}
              path={path}
              handleChange={handleChange}
              addNew
            />
          </>
        )}
      </Stack>
      <Grid item xs={12} md={8}>
        <Typography variant="body2" sx={[{ mb: 1 }]} aria-label="description">
          {description}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={[{ mb: 1, fontWeight: "bold" }]}
          aria-label={surveyResponseStrings.ariaLabels.descriptionSelect}
        >
          {multiple
            ? surveyResponseStrings.question.selectAll
            : surveyResponseStrings.question.selectOne}
        </Typography>
      </Grid>
      <Input
        role="combobox"
        value={values}
        sx={[{ display: "none" }]}
        readOnly
      />

      <Grid
        container
        direction="row"
        sx={{ width: "80%" }}
        rowSpacing={1}
        columnSpacing={3}
      >
        {options.map((option) => {
          return (
            <Grid item key={option.id} xs={options.length === 4 ? 6 : 4}>
              <Stack direction="row" spacing={-1.625} justifyContent="center">
                <Typography
                  role="option"
                  aria-label={option.id}
                  variant="body2"
                  sx={[
                    (theme) => ({
                      color: optionColour,
                      border: theme.spacing(0.3125, "solid"),
                      borderColor: optionColour,
                      my: 1.5,
                      borderRadius: theme.spacing(0.625),
                      px: 2.5,
                      py: 1.5,
                      textAlign: "left",
                      width: "100%",
                      fontWeight: "regular",
                      position: "relative",
                    }),
                    !disabled && {
                      transition: "0.2s",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    },
                    (theme) => ({
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "100%",
                        right: theme.spacing(2.5),
                        borderLeft: theme.spacing(
                          3.125,
                          "solid",
                          "transparent"
                        ),
                        borderTop: theme.spacing(1.75, "solid"),
                        borderTopColor: optionColour,
                        zIndex: 1,
                      },
                      ...(!values.includes(option.value) && {
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          top: "100%",
                          right: theme.spacing(2.75),
                          borderLeft: theme.spacing(
                            2.375,
                            "solid",
                            "transparent"
                          ),
                          borderTop: theme.spacing(1.375, "solid"),
                          borderTopColor: "common.white",
                          zIndex: 2,
                        },
                      }),
                    }),
                    values.includes(option.value) && {
                      backgroundColor: optionColour,
                      color: "common.white",
                    },
                  ]}
                  onClick={() =>
                    !disabled && handleClick(option.value, option.feedback)
                  }
                >
                  {option.label}
                </Typography>
                {editable && (
                  <QuestionOptionModal
                    key={option.label}
                    controlId={controlId}
                    options={options}
                    option={option}
                    path={path}
                    handleChange={handleChange}
                  />
                )}
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
