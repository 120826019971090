import { CreateNewFolder } from "@mui/icons-material";
import {
  validateId,
  validateLength,
  validateNotEmpty,
} from "../../helpers/validation-helpers";
import { subsectionModalStrings as strings } from "../../resources/strings/editModal";
import { SectionType } from "../../types/documents/BasePart";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { EditModal } from "../general/EditModal";
import { IconButton, IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

export type SubsectionFields = {
  name: string;
  subsectionId: string;
};

interface Props {
  path: string;
  handleChange: ChangeHandler;
  subsection?: SubsectionFields;
  addNew?: boolean;
  listOfPaths: string[];
}

type Component = (props: Props) => JSX.Element;

export const SubsectionModal: Component = ({
  path,
  subsection: initSubsection = {
    name: "",
    subsectionId: "",
  },
  handleChange,
  addNew,
  listOfPaths,
}) => {
  const modalTitle = addNew ? strings.modalTitle.add : strings.modalTitle.edit;
  const trigger = addNew ? (
    <IconButton
      name="new subsection"
      icon={CreateNewFolder}
      colour={"common.blue"}
    />
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const subsectionTemplate = ({ name, subsectionId }: SubsectionFields) => ({
    name,
    path: `${path}/${subsectionId}`,
    subsectionId,
    controls: [],
    order: -1,
    stage: -1,
    suggestions: [],
    type: SectionType.None,
    parts: [
      {
        type: SectionType.Introduction,
        name: `Introduction to ${name}`,
        order: 0,
        stage: -1,
        path: `${path}/${subsectionId}/intro`,
        partId: "intro",
        controls: [],
        suggestions: [],
      },
      {
        type: SectionType.Feedback,
        name: `${name} Feedback`,
        order: 1,
        stage: -1,
        path: `${path}/${subsectionId}/feedback`,
        partId: "feedback",
        controls: [],
        suggestions: [],
      },
    ],
  });

  const handleAdd = (fields: SubsectionFields) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Subsection,
        actionType: ActionType.Add,
        value: subsectionTemplate(fields),
      },
    });
  };

  const handleEdit = (fields: SubsectionFields) => {
    const newPath = path.replace(
      initSubsection.subsectionId,
      fields.subsectionId
    );

    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Subsection,
        actionType: ActionType.Update,
        value: {
          ...fields,
          path: newPath,
        },
      },
    });
  };

  const handleDelete = (_: SubsectionFields) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Subsection,
        actionType: ActionType.Delete,
        value: {},
      },
    });
  };

  return (
    <EditModal<SubsectionFields>
      title={modalTitle}
      handleConfirm={addNew ? handleAdd : handleEdit}
      handleDelete={!addNew ? handleDelete : undefined}
      controls={[
        {
          label: strings.name,
          id: "name",
          validationFunction: (value) =>
            validateNotEmpty(value) || validateLength(255)(value),
        },
        {
          label: strings.id,
          id: "subsectionId",
          validationFunction: addNew
            ? (id: string) =>
                validateId(id, listOfPaths) || validateLength(80)(id)
            : undefined,
          disabled: !addNew,
        },
      ]}
      data={initSubsection}
      trigger={trigger}
    />
  );
};
