export enum Permissions {
  Read = "read",
  Create = "create",
  Update = "update",
  Access = "access",
  History = "history",
  Admin = "admin",
  Standard = "standard",
  Send = "send",
}
