import type { AxiosRequestConfig, CancelToken } from "axios";
import defaultAxios from "axios";
import axios from "../libs/axios/axios-surveys";
import type {
  CreateFunction,
  QueryFunction,
  QueryResult,
  ReadFunction,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import { Survey } from "../types/documents/Survey";

const listSurveys: QueryFunction<Survey> = async (body, cancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const {
    data: { items = [], totalCount = 0 },
  } = await axios.post<QueryResult<Survey>>("/search", body, config);
  return {
    items,
    totalCount,
  };
};

const read: ReadFunction<Survey> = async (id, _childId, cancelToken) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const path = `/${id}`;
  const { data } = await axios.get<Survey>(path, config);
  return {
    item: data,
  };
};

const getLatestSurvey: ReadFunction<Survey> = async (
  _id,
  _childId,
  cancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.get<Survey>("/latest", config);
  return {
    item: data,
  };
};

const getUnpublishedSurvey: ReadFunction<Survey> = async (
  _id,
  _childId,
  cancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.get<Survey>("/unpublished", config);
  return {
    item: data,
  };
};

const publish: UpdateFunction<Survey> = async (_body, cancelToken?) => {
  const { data } = await axios.post<UpdateResult>(
    "/publish",
    {},
    { cancelToken }
  );
  return data;
};

const update: UpdateFunction<Survey> = async (body, cancelToken?) => {
  const { data } = await axios.post<UpdateResult>("/", body, { cancelToken });
  return data;
};

const getSignedUploadUrl = async (id: string, cancelToken: CancelToken) => {
  const path = `/getSignedUploadUrl/${id}`;
  const { data } = await axios.get(path, { cancelToken });
  return data;
};

const getSignedDownloadUrl = async (id: string, cancelToken: CancelToken) => {
  const path = `/getSignedDownloadUrl/${id}`;
  const { data } = await axios.get(path, { cancelToken });
  return data;
};

const uploadImage = async (body: any, cancelToken: CancelToken) => {
  const imageName = body.name.replace(/\s+/g, "_");
  const { url, key } = await getSignedUploadUrl(imageName, cancelToken);
  const { data } = await defaultAxios.put(url, body);
  return { data, key };
};

const downloadImage = async (imageId: string, cancelToken: CancelToken) => {
  const { url } = await getSignedDownloadUrl(imageId, cancelToken);
  return url;
};

const create: CreateFunction<Survey> = (_body, _cancelToken?) =>
  Promise.resolve({
    id: "",
  });

export const api = {
  listSurveys,
  read,
  update,
  getLatestSurvey,
  getUnpublishedSurvey,
  publish,
  uploadImage,
  downloadImage,
  create,
};
