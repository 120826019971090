import type { AxiosRequestConfig, CancelToken } from "axios";
import axios from "../libs/axios/axios-userfeedback";
import type {
  CreateFunction,
  ReadFunction,
  UpdateFunction,
} from "../types/API";
import {
  SendSupportOrFeedbackResponse,
  SupportOrFeedback,
} from "../types/documents/SupportOrFeedback";

const update: UpdateFunction<SupportOrFeedback> = async () =>
  Promise.resolve({
    id: "",
  });

const read: ReadFunction<SupportOrFeedback> = async () =>
  Promise.resolve({
    item: { area: "", details: "" },
  });

const create: CreateFunction<SupportOrFeedback> = async (
  body: SupportOrFeedback,
  cancelToken?: CancelToken
) => {
  const config: AxiosRequestConfig = {
    cancelToken,
  };
  const { data } = await axios.post<SendSupportOrFeedbackResponse>(
    "/send",
    body,
    config
  );
  return {
    item: data.result,
    id: data.id,
  };
};

export const api = {
  create,
  read,
  update,
};
