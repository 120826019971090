import { urls } from "../urls";

export const landingPageStrings = {
  headers: {
    surveyName: "Data Readiness Self-Assessment",
    mainHeading:
      "Explore how ready your organisation is to unlock the potential of its data and support your mission and purpose",
    subHeading:
      "This assessment is particularly suited to small/medium sized businesses and third-Sector organisations",
    body: [
      "It will guide you through the 4 aspects of your data journey: Data, Ambition, Ability to Execute, and Environment. In each section, you will be asked to select statement(s) that best describe to how things are currently done in your organisation.",
      "Once you have chosen your 'best match' statements for each area, the tool will offer your feedback in the form of a report; you can download this or receive by email as you prefer. This offers guidance and signposts some resources to explore as you take your organisation's plans forward so the downloaded or emailed copy lets you take the next steps in your own time, and with your co-workers if appropriate.",
      "To get the best out of this tool, we recommend you take this assessment on your PC, laptop, or tablet in landscape mode. If you need to pause to consider a particular topic and return to the assessment, your answers will be saved for you to do so.",
    ],
    adminBody: [
      "The Data Readiness Self-Assessment tool is managed by the Operations team at The Data Lab. We request that any content adjustments be made with prior approval from Manolo Perez, Director of Finance and Operations, as well as the Marketing team.",
      "If you require assistance with issuing user login credentials, please follow the guidelines outlined in the provided instructions: Select ‘Manage Users’ > Select ‘New User’ > Complete all requested fields > Select 'Save'. The tool will automatically send the user an e-mail providing their new login details.",
    ],
    feedbackAndSupportHeading:
      "Need help or want to provide feedback? Contact our team via the link below",
    surveyPurposeHeading: "This Data Readiness Self-Assessment will:",
    dataAndPrivacy: "Data and Privacy",
    dataAndPrivacyAgreement: "Data and Privacy Agreement",
    surveyHistory: "Self-Assessment History",
  },
  surveyHistory: {
    caption:
      "Continue your self-assessment journey. Pick up where you left off with any incomplete assessments or review your most recent completed evaluation to review and reflect on your progress.",
  },
  privacyAgreement: {
    section1: {
      title: "Data Readiness Tool Privacy Notice",
      part1: {
        text: "The information entered in the questionnaire will be processed by The Data Lab and its subcontractors for the purposes of providing you with data insights.",
      },
      part2: {
        text: "Data Readiness Tool Terms of Use and Acceptable Use Policy:",
      },
      part3: {
        text: "The Data Lab - Terms of Use",
        url: urls.termsAndConditions,
      },
      part4: {
        text: "Please note the data insights provided are for general information only and although we use reasonable guarantees to ensure any insights provided are accurate, we make no warranties or guarantees, whether express or implied that the information provided is complete or accurate.",
      },
      part5: {
        text: "Our privacy notice is available here:",
      },
      part6: {
        text: "The Data Lab - Privacy Policy",
        url: urls.privacyPolicy,
      },
    },
  },
  actions: {
    startSurvey: "Start new assessment",
    back: "Back",
    agreeAndContinue: "Continue",
    agreeToStatement: "I have read and agree to the Data and Privacy Statement",
    sendFeedbackOrSupport: "Feedback & Support",
  },
};
