import { FiberManualRecord as Dot } from "@mui/icons-material";
import { Box, Grid, SxProps, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NextPageIcon } from "../../assets/NextPageIcon";
import { PaginationIcon } from "../../assets/PaginationIcon";
import { PreviousPageIcon } from "../../assets/PreviousPageIcon";
import { feedbackStrings } from "../../resources/strings/feedback";

const imageStyles: SxProps = {
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  overflow: "hidden",
  "& img": {
    maxWidth: { xs: "40%", md: "100%" },
    height: "auto",
    objectFit: "contain",
  },
};

export type Props = {
  items: {
    title: string;
    content: {
      heading: string;
      body: string;
    }[];
    image: {
      src: string;
      alt: string;
    };
  }[];
  handleCurrentFeedback?: any;
};

type Component = (props: Props) => JSX.Element;

export const FeedbackComponent: Component = ({
  items,
  handleCurrentFeedback,
}) => {
  useEffect(() => {
    return () => setActiveIndex(0);
  }, [items]);

  const [activeIndex, setActiveIndex] = useState(0);
  const itemsSize = items.length;

  const changeActiveIndex = (activeIndex: number, increment: number) => {
    const newIdx = activeIndex + increment;
    setActiveIndex(newIdx);
  };

  useEffect(() => {
    if (!handleCurrentFeedback || items.length === 0 || !items[activeIndex])
      return;

    handleCurrentFeedback(items[activeIndex].title);
    return () => handleCurrentFeedback("");
  }, [activeIndex, items, handleCurrentFeedback]);

  return itemsSize > 0 && items[activeIndex] ? (
    <Grid container mb={2} role="tablist" aria-label="feedback">
      <Grid item xs={12}>
        <Box
          role="navigation"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          aria-label="feedback navigation"
        >
          <PaginationIcon
            aria-label="previous"
            onClick={() => changeActiveIndex(activeIndex, -1)}
            disabled={activeIndex === 0}
          >
            <PreviousPageIcon />
          </PaginationIcon>
          <Typography
            variant="h6"
            aria-label="title"
            sx={{ color: "primary.main", fontWeight: "bold", mx: 5 }}
          >
            {items[activeIndex].title}
          </Typography>
          <PaginationIcon
            aria-label="next"
            onClick={() => changeActiveIndex(activeIndex, 1)}
            disabled={activeIndex >= itemsSize - 1}
          >
            <NextPageIcon />
          </PaginationIcon>
        </Box>
      </Grid>
      <Grid item xs={12} py={2}>
        {items[activeIndex].content.length === 0 && (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", whiteSpace: "pre-line" }}
          >
            {feedbackStrings.noFeedback}
          </Typography>
        )}
        <Grid container role="tabpanel" aria-label="feedback container">
          <Grid
            item
            xs={12}
            md={items[activeIndex].image.src ? 7 : 12}
            role="list"
            aria-label="feedback content"
          >
            {items[activeIndex].content.length > 0 &&
              items[activeIndex].content.map((content, idx) => (
                <Box
                  key={idx}
                  role="listitem"
                  aria-label={content.heading}
                  sx={{ mb: 2, px: 3 }}
                >
                  <Typography
                    variant="subtitle1"
                    align="left"
                    sx={{
                      color: "primary.main",
                      whiteSpace: "pre-line",
                      fontWeight: "bold",
                    }}
                  >
                    {content.heading}
                  </Typography>
                  <Typography
                    variant="body2"
                    align="left"
                    sx={{ color: "text.secondary", whiteSpace: "pre-line" }}
                  >
                    {content.body}
                  </Typography>
                </Box>
              ))}
          </Grid>
          {items[activeIndex].image.src && (
            <Grid
              item
              xs={12}
              md={5}
              sx={[{ boxSizing: "border-box", alignSelf: "center" }]}
            >
              <Box p={3} sx={[{ ...imageStyles }]}>
                <img
                  src={items[activeIndex].image.src}
                  alt={items[activeIndex].image.alt}
                  aria-label={items[activeIndex].image.alt}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          role="tablist"
          aria-label="feedback progress"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          {items.map((_, idx) => (
            <div
              key={idx}
              role="tab"
              id={`tab-${idx}`}
              aria-controls={`feedbackpanel-${idx}`}
              onClick={() => setActiveIndex(idx)}
            >
              {items.length > 1 && (
                <Dot
                  sx={[
                    {
                      color: "common.midGrey",
                      fontSize: (theme) => theme.typography.fontSize,
                    },
                    activeIndex === idx && { color: "primary.main" },
                  ]}
                />
              )}
            </div>
          ))}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Typography
      variant="h6"
      aria-label="title"
      sx={{ color: "primary.main", fontWeight: "bold", mx: 5 }}
    >
      {feedbackStrings.noFeedback}
    </Typography>
  );
};
