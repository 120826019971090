import type { CircularProgressProps, SxProps, Theme } from "@mui/material";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { appStrings as strings } from "../../resources/strings/app";

interface Props {
  active: boolean | undefined;
  children?: JSX.Element;
  inline?: boolean;
  text?: string;
}
type Component = (props: Props) => JSX.Element | null;

const baseStyles: SxProps<Theme> = (theme) => ({
  display: "flex",
  backgroundColor: "inherit",
  color: theme.palette.primary.main,
});

const backdropStyles: SxProps<Theme> = {
  flexDirection: "column",
  alignItems: "center",
};

const inlineStyles: SxProps<Theme> = {
  alignContent: "center",
  flexWrap: "wrap",
  justifyContent: "center",
};

const contentStyles: SxProps<Theme> = {
  margin: "0.5rem",
};

export const Loader: Component = ({
  active = false,
  children = null,
  inline = false,
  text = strings.labels.loading,
}) => {
  const LoaderContent = (props: CircularProgressProps) => (
    <>
      <CircularProgress sx={[contentStyles]} color="secondary" {...props} />
      <Typography sx={[contentStyles]}>{text}</Typography>
    </>
  );

  const renderLoader = () => {
    return inline ? (
      <Grid container sx={[baseStyles, inlineStyles]}>
        <LoaderContent size="1.25rem" />
      </Grid>
    ) : (
      <Backdrop sx={[baseStyles, backdropStyles]} open>
        <LoaderContent />
      </Backdrop>
    );
  };

  return active ? <Box role="progressbar">{renderLoader()}</Box> : children;
};
