import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  styled,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import bgImage from "../../assets/pulsion-bg-image.png";
import logo from "../../assets/svgs/data-lab-logo.svg";
import { ShowIf } from "../../components/authentication/ShowIfAuthorised";
import { authStrings as strings } from "../../resources/strings/auth";
import { urls } from "../../resources/urls";

interface Props {}
type Component = (props: Props) => JSX.Element;

interface LayoutProps {
  children: JSX.Element;
  bgImage: string;
  logo: string;
  legalText: string;
}

const rootStyles: SxProps<Theme> = {
  flexGrow: 1,
  height: "100vh",
};

const imageStyles: SxProps<Theme> = {
  height: "100%",
  width: "auto",
};

const logoStyles: SxProps<Theme> = (theme) => ({
  height: "auto",
  width: "auto",
  padding: theme.spacing(5),
});

const container: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
  height: "100%",
};

const contentStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
};

const Div = styled("div")({});

const PageLayout = ({ children, bgImage, logo, legalText }: LayoutProps) => {
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <Div sx={[rootStyles]}>
      <Box display="flex" flexDirection="row" height="100vh" maxHeight="100vh">
        <Grid container>
          <Grid item sm={12} md={4} sx={[container]}>
            <div />
            <Grid item xs={9} sx={[contentStyles]}>
              <Avatar sx={[logoStyles]} src={logo} variant="square" />
              {children}
            </Grid>
            <div />
            <Typography variant="caption">
              <a href={urls.privacyPolicy} target="_blank" rel="noreferrer">
                {strings.labels.privacyPolicy}
              </a>
              {" | "}
              <a
                href={urls.termsAndConditions}
                target="_blank"
                rel="noreferrer"
              >
                {strings.labels.termsAndConditions}
              </a>
            </Typography>
            <Typography variant="caption">{legalText}</Typography>
          </Grid>
          {/* Hide background image on smaller screen sizes */}
          <ShowIf show={!mdDown}>
            <Grid item md>
              <CardMedia sx={[imageStyles]} image={bgImage} />
            </Grid>
          </ShowIf>
        </Grid>
      </Box>
    </Div>
  );
};

export const AuthWrapper: Component = () => {
  return (
    <PageLayout bgImage={bgImage} logo={logo} legalText={strings.text.legal}>
      <Box display="flex" flexDirection="column" justifyContent={"center"}>
        <Outlet />
      </Box>
    </PageLayout>
  );
};
