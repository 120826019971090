import type { SxProps, Theme } from "@mui/material";
import { Box, Grid, styled } from "@mui/material";
import { Fragment, useMemo } from "react";
import type { Permissions } from "../../types";
import { ControlType } from "../../types/documents/Control";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { BaseControl } from "./controls/BaseControl";
import { Button } from "./controls/Button";
import { Checkbox } from "./controls/Checkbox";
import { Dropdown } from "./controls/Dropdown";
import { Header } from "./controls/Header";
import { Input } from "./controls/Input";
import { Link } from "./controls/Link";
import { RadioControl } from "./controls/RadioControl";
import { EndPanel } from "./EndPanel";
import { FeedbackComponent } from "./FeedbackComponent";
import { Header as SectionHeader } from "./Header";
import { Loader } from "./Loader";
import { SurveyAnswer } from "./SurveyAnswer";
import type {
  ChangeHandler,
  Checkbox as TCheckbox,
  ComponentConfiguration,
  Content,
  Dropdown as TDropdown,
  FormInput,
  Mode,
  Section,
} from "./types/Modify";
import { VideoComponent } from "./VideoComponent";

export interface Props<D extends object> {
  data: D;
  componentConfiguration: ComponentConfiguration<D>;
  handleChange: ChangeHandler;
  handleReset: () => void;
  handleCurrentFeedback?: React.Dispatch<React.SetStateAction<string>>;
  mode: Mode;
  handleModeSwitch: () => void;
  validateForm: () => void;
  loading?: boolean;
  permissions: Permissions | null;
  setFormData: React.Dispatch<React.SetStateAction<D>>;
  validationResults: { [key: string]: string[] } | null;
  currentFeedbackTitle?: string;
}

const rootStyles: SxProps<Theme> = {
  flexGrow: 1,
};

const Div = styled("div")({});

export const ModifyComponent = <D extends object>(
  props: Props<D>
): JSX.Element => {
  const {
    data,
    componentConfiguration: inputCompConfig,
    handleChange,
    handleReset,
    handleCurrentFeedback,
    validateForm,
    loading,
    mode,
    permissions,
    validationResults,
    setFormData,
  } = props;

  const componentConfiguration =
    typeof inputCompConfig === "function"
      ? inputCompConfig({ data, mode })
      : inputCompConfig;
  const configuration = useMemo(
    () =>
      componentConfiguration.filter((input) => {
        let result = true;
        // not sure if there should be rules on these taking
        // precedence over each other (probably should be)

        if (input.modes) result = input.modes.includes(mode);
        // will be completely static, use with caution, won't be able to react
        // to state updates
        if (input.filters) result = input.filters.some((f) => f);

        return result;
      }),
    [componentConfiguration, mode]
  );

  const renderComponent = (config: Content<D>) => {
    if (config.modes) {
      const isCorrectMode = config.modes.includes(mode);
      if (!isCorrectMode) return null;
    }

    const disabled = mode === "view";
    let errorMessage = "";

    switch (config.controltype) {
      case ControlType.Input:
        const inputConfig: FormInput<D> = {
          value: data[config.name],
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          inputConfig.error = true;
          inputConfig.helperText = validationResults[config.name].join(", ");
        }

        return <Input<D> config={inputConfig} handleChange={handleChange} />;
      case ControlType.Header:
        return <Header config={config} />;
      case ControlType.Link:
        return <Link config={config} />;
      case ControlType.Dropdown:
        const dropdownConfig: TDropdown<D> = {
          value: data[config.name],
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          dropdownConfig.error = true;
          dropdownConfig.helperText = validationResults[config.name].join(", ");
        }

        return (
          <Dropdown<D> config={dropdownConfig} handleChange={handleChange} />
        );
      case ControlType.Button:
        return <Button config={config} />;
      case ControlType.Checkbox:
        errorMessage = "";
        const checkboxConfig: TCheckbox<D> = {
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          errorMessage = validationResults[config.name].join(", ");
        }

        return (
          <Checkbox
            config={checkboxConfig}
            setFormData={setFormData}
            //@ts-ignore
            checkData={data[config.name]}
            errorMessage={errorMessage}
          />
        );
      case ControlType.Video:
        return <VideoComponent {...config} handleChange={handleChange} />;
      case ControlType.Feedback:
        const feedbackProps = !handleCurrentFeedback
          ? config
          : { ...config, handleCurrentFeedback };
        return <FeedbackComponent {...feedbackProps} />;
      case ControlType.SectionHeader:
        return <SectionHeader {...config} />;
      case ControlType.EndPanel:
        return <EndPanel {...config} handleChange={handleChange} />;
      case ControlType.Question:
        return (
          <SurveyAnswer
            {...config}
            handleChange={handleChange}
            disabled={config.disabled || disabled}
            key={config.path}
          />
        );
      case ControlType.Custom:
        const { Component } = config;
        return (
          <Component
            {...props}
            disabled={disabled}
            handleChange={handleChange}
          />
        );
      case ControlType.RadioGroup:
        return (
          <RadioControl<D>
            config={{ ...config, disabled, value: data[config.name] }}
            handleChange={handleChange}
          />
        );
    }
  };

  const renderConfiguration = (
    { key, content, userAccess, direction = "row" }: Section<D>,
    index: number
  ) => {
    const section = (
      <Box key={key} display="flex" flexDirection={direction}>
        <Grid key={`${key}-${index}`} container gap={2} justifyContent="center">
          {content.map((config, subIndex) => {
            let { xs = 12, md = 6 } = config;
            const layoutProps = {
              sm: config.sm,
              xl: config.xl,
              lg: config.lg,
            };

            return (
              <Grid
                key={`${key}-${index}-${subIndex}`}
                item
                {...layoutProps}
                xs={xs}
                md={md}
              >
                <BaseControl control={config.control}>
                  {renderComponent(config)}
                </BaseControl>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );

    return userAccess ? (
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        {section}
      </ShowIfAuthorised>
    ) : (
      <>{section}</>
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    validateForm();
  };

  return (
    <Loader active={loading}>
      <Div sx={[rootStyles]}>
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          {configuration.map((section, index) => (
            <Fragment key={`configuration-${index}`}>
              {renderConfiguration(section, index)}
            </Fragment>
          ))}
        </form>
      </Div>
    </Loader>
  );
};
