import type { SxProps, Theme } from "@mui/material";
import { styled } from "@mui/material";
import type { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { FirstPageIcon } from "../../assets/FirstPageIcon";
import { LastPageIcon } from "../../assets/LastPageIcon";
import { NextPageIcon } from "../../assets/NextPageIcon";
import { PaginationIcon } from "../../assets/PaginationIcon";
import { PreviousPageIcon } from "../../assets/PreviousPageIcon";
import { appStrings as strings } from "../../resources/strings/app";

const Div = styled("div")({});

const rootStyles: SxProps<Theme> = (theme) => ({
  flexShrink: 0,
  marginLeft: theme.spacing(2.5),
});

export const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) => {
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Div sx={[rootStyles]}>
      <PaginationIcon
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={strings.ariaLabels.firstPage}
        title="first page"
        id="first page"
      >
        <FirstPageIcon />
      </PaginationIcon>
      <PaginationIcon
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={strings.ariaLabels.prevPage}
        title="previous page"
        id="previous page"
      >
        <PreviousPageIcon />
      </PaginationIcon>
      <PaginationIcon
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={strings.ariaLabels.nextPage}
        title="next page"
        id="next page"
      >
        <NextPageIcon />
      </PaginationIcon>
      <PaginationIcon
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={strings.ariaLabels.lastPage}
        title="last page"
        id="last page"
      >
        <LastPageIcon />
      </PaginationIcon>
    </Div>
  );
};
