import {
  Box,
  Grid,
  Paper,
  styled,
  SxProps,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import type { Row, SortingRule, TableOptions } from "react-table";
import { useFlexLayout, usePagination, useSortBy, useTable } from "react-table";
import { Permissions, UserAccess } from "../../types";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { Loader } from "../general/Loader";
import { Button } from "./Button";
import { HomeButton } from "./HomeButton";
import { TablePaginationActions } from "./TablePaginationActions";

/*
  The react table docs are useless when it comes to typescript

  Some useful resources:
    - Type declarations: 
        https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
    - For future projects, pay particular attention to the configuration 
      section of the readme in the above repo
    - React table example written in TypeScript from one of the React Table maintainers
        https://github.com/ggascoigne/react-table-example
*/

type TableEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | null;
type ChangeHandler = React.ChangeEventHandler<
  HTMLInputElement | HTMLTextAreaElement
>;

export interface Props<D extends Record<string, unknown>>
  extends TableOptions<D> {
  header: string;
  totalCount?: number;
  size?: number;
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  permissions: Permissions | null;
  loading?: boolean;
  clickHandler?: (data: D) => void;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  onSortChange: (sort: SortingRule<D>[]) => void;
  sortObject: SortingRule<D>[];
  children?: JSX.Element[];
  progressRow?: boolean;
  progressValueHeader?: string;
}

const Div = styled("div")({});

const rootStyles: SxProps<Theme> = {
  boxShadow: "none",
};

const headerStyles: SxProps<Theme> = {
  marginBottom: "1rem",
};

const bodyStyles: SxProps<Theme> = (theme) => ({
  "&:nth-of-type(even)": {
    background: theme.palette.common.lightGrey,
  },
});

const tableRowStyles: SxProps<Theme> = {
  "&:hover": {
    cursor: "pointer",
  },
};

const tableProgessRowStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.pink,
  padding: 0,
});

const headStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.lightGrey,
  color: theme.palette.common.grey,
  fontWeight: "bolder",
});

const borderStyles: SxProps<Theme> = (theme) => ({
  borderTop: theme.spacing(0.1, "solid", theme.palette.common.midGrey!),
});

const childrenStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  alignContent: "center",
});

export const Table = <T extends Record<string, unknown>>({
  header,
  data: tableData,
  columns: tableColumns,
  totalCount = tableData.length,
  size = 10,
  hasHomeButton,
  createButtonConfig,
  permissions,
  loading,
  ariaLabel,
  clickHandler,
  manualPagination,
  onPageChange,
  onSizeChange,
  onSortChange,
  sortObject,
  children,
  progressRow,
  progressValueHeader,
}: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const sort = useMemo(() => sortObject, [sortObject]);
  const pageCount = useMemo(
    () => Math.ceil(totalCount / size),
    [totalCount, size]
  );

  const hooks = [useSortBy, usePagination, useFlexLayout];

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: size,
        pageIndex: 0,
        sortBy: sort,
      },
      manualPagination,
      manualSortBy: manualPagination,
      pageCount,
      // this flag prevents weird behaviour when changing page
      // like fetching the next page then re-fetching the previous page immediately
      autoResetPage: false,
      autoResetSortBy: false,
    },
    ...hooks
  );

  // NOTE: if a new hook plugin is used, it may be necessary to update
  // `types/react-table-config.d.ts` to get the correct types
  // See `TableOptions` interface
  const {
    // basic table props
    getTableProps,
    getTableBodyProps,
    headerGroups = [],
    prepareRow,
    state: { pageIndex, pageSize: tablePageSize, sortBy },
    // pagination props
    page: pageRows = [],
    gotoPage,
    setPageSize: setTablePageSize,
  } = instance;

  // If manual pagination is set, we want to forward any changes in table state
  // to trigger a new data fetch
  useEffect(() => {
    if (manualPagination) {
      onPageChange(pageIndex);
      onSizeChange(tablePageSize);
      onSortChange(sortBy);
    }
  }, [
    manualPagination,
    pageIndex,
    tablePageSize,
    sortBy,
    onPageChange,
    onSizeChange,
    onSortChange,
  ]);

  const handleChangePage = (_event: TableEvent, newPage: number) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage: ChangeHandler = (event) => {
    if (!event) return;
    const { target } = event;

    setTablePageSize(+target.value);
    gotoPage(0);
  };

  const renderCreateButton = () => {
    if (!createButtonConfig) return <></>;
    const { userAccess, label, path } = createButtonConfig;

    return (
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={path}
        >
          {label}
        </Button>
      </ShowIfAuthorised>
    );
  };

  const renderHeader = (hasHomeButton: Boolean) => {
    return (
      <Grid
        sx={[headerStyles]}
        container
        direction="row"
        justifyContent="space-between"
      >
        {hasHomeButton && (
          <Grid item>
            <HomeButton />
          </Grid>
        )}
        <Grid item>
          <Typography variant="h5">{header}</Typography>
        </Grid>
        <Grid item>{renderCreateButton()}</Grid>
      </Grid>
    );
  };

  const handleClick = (row: Row<T>) => {
    if (clickHandler) clickHandler(row.original);
  };

  const renderTableChildren = () => {
    if (!children) return null;
    return (
      <Div data-testid="table-children" sx={[childrenStyles]}>
        <Grid container>{children}</Grid>
      </Div>
    );
  };

  const renderTable = () => {
    return (
      <>
        {renderTableChildren()}
        <TableContainer component={Paper} sx={[rootStyles]}>
          <TableComponent {...getTableProps()} aria-label={ariaLabel}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      sx={[headStyles, borderStyles]}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {pageRows.map((row) => {
                prepareRow(row);
                const progressValue = row.cells.find(
                  (cell) => cell.column.Header === progressValueHeader
                )?.value;
                return (
                  <>
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => handleClick(row)}
                      hover
                      sx={[bodyStyles, tableRowStyles]}
                    >
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()}>
                          <Typography noWrap variant="body2">
                            {cell.render("Cell")}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                    {progressRow && (
                      <TableRow>
                        <TableCell sx={[tableProgessRowStyles]}>
                          <LinearProgress
                            aria-label="progress bar"
                            variant="determinate"
                            value={progressValue}
                            color="inherit"
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </TableComponent>
        </TableContainer>
        <TablePagination
          role="navigation"
          component="div"
          page={pageIndex}
          count={totalCount}
          rowsPerPageOptions={[]}
          rowsPerPage={tablePageSize}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{ title: "page options" }}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} to ${to} of ${count}`
          }
          ActionsComponent={TablePaginationActions}
        />
      </>
    );
  };

  return (
    <Box role="grid" display="flex" flexDirection="column">
      {renderHeader(hasHomeButton)}
      <Loader active={loading}>{renderTable()}</Loader>
    </Box>
  );
};
