import { SurveyResponse } from "./SurveyResponse";
import { UserData } from "./User";

export type UserFeedback = {
  id?: string;
  created?: string;
  updated?: string;
  rating: string;
  wantToBeContacted: boolean;
  wantToBeContactedAnswer?: string;
  comments?: string;
  userId?: string;
  responseId?: string;
  user?: UserData;
  response?: SurveyResponse;
};

export type CreateUserFeedbackResponse = {
  id: string;
  payload: UserFeedback;
};

export enum Rating {
  Useful = "0daa4996-6ffe-4924-b410-d3e3d70bf3f4",
  ThoughtProvoking = "0ca0e0f6-dcf2-47a3-a1d7-feb872c9d82a",
  Unhelpful = "3b63a50b-2770-401f-a2cf-c1c25ab46b13",
}
