import axios from "../libs/axios/axios-surveyresponses";
import type { QueryFunction, QueryResult } from "../types/API";
import type { SurveySkipRatesChartData } from "../types/documents/SurveyResponse";

const query: QueryFunction<SurveySkipRatesChartData> = async (
  body,
  cancelToken?
) => {
  const { data } = await axios.post<QueryResult<SurveySkipRatesChartData>>(
    "/charts/skip",
    body,
    {
      cancelToken,
    }
  );
  return data;
};

export const api = {
  query,
};
