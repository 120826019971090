import { MenuItem } from "@mui/material";
import { DownIcon } from "../../../assets/DownIcon";
import { displayDropdownValue } from "../../../helpers/dropdown-helpers";
import type { ChangeHandler, Dropdown as TDropdown } from "../types/Modify";
import { Input } from "./Input";

interface Props<D extends object> {
  config: Omit<TDropdown<D>, "controltype">;
  handleChange: ChangeHandler;
}

export const Dropdown = <D extends object>({
  config: input,
  handleChange,
}: Props<D>): JSX.Element => {
  const { options = [], ...config } = input;

  const renderOptions = (dropdownOptions: typeof options) => {
    return dropdownOptions.map((option, index) => (
      <MenuItem
        key={`${option.value}-${index}`}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <Input<D>
      config={{
        ...config,
        select: true,
        SelectProps: {
          ...config.SelectProps,
          renderValue: (data) => displayDropdownValue(data, options),
          IconComponent: DownIcon,
        },
        children: renderOptions(options),
      }}
      handleChange={handleChange}
    />
  );
};
