import { FormHelperText, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/general/Button";
import { Loader } from "../../components/general/Loader";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Link } from "../../components/general/controls/Link";
import { useForm } from "../../hooks/general/useForm";
import { completeNewPassword, signInWithDetails } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import type { CognitoUser } from "../../types";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

const spacerStyles: SxProps<Theme> = {
  my: 2,
};

export const CustomLogin: Component = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState({ challengeName: "" });
  const [success, setSuccess] = useState(false);

  const [formData, handleChange] = useForm({
    username: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (user || success) navigate(paths.landingPage);
  }, [user, success, navigate]);

  const onLogin: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const result = await signInWithDetails(
        formData.username,
        formData.password
      );

      setLoading(false);
      setError("");

      if (result.challengeName) {
        setResult(result);
      } else {
        setSuccess(true);
      }
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.signIn);
    }
  };

  const onChangePassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await completeNewPassword(result, formData.newPassword);

      setLoading(false);
      setError("");
      setResult({ challengeName: "" });

      setSuccess(true);
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.changePassword);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return <BaseControl control={false}>{control}</BaseControl>;
  };

  const renderLoginForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.login}
        </Typography>
        <form noValidate onSubmit={onLogin} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.username,
                name: "username",
                label: strings.labels.username,
                fullWidth: true,
                autoComplete: "username",
                sx: [spacerStyles],
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.password,
                name: "password",
                label: strings.labels.password,
                type: "password",
                fullWidth: true,
                autoComplete: "password",
                sx: [spacerStyles],
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            label={strings.labels.login}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.username || !formData.password}
            sx={[spacerStyles]}
            aria-label="login"
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
        <Link
          key="passwordReset"
          config={{
            text: strings.labels.resetPassword,
            to: paths.auth.passwordReset,
            sx: [spacerStyles],
          }}
        />
      </>
    );
  };

  const renderNewPasswordForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.changePassword}
        </Typography>
        <form noValidate onSubmit={onChangePassword} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.newPassword,
                name: "newPassword",
                label: strings.labels.newPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "new-password",
                sx: [spacerStyles],
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.confirmNewPassword,
                name: "confirmNewPassword",
                label: strings.labels.confirmNewPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "confirm-password",
                sx: [spacerStyles],
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            label={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={formData.newPassword !== formData.confirmNewPassword}
            sx={[spacerStyles]}
            aria-label="confirm new password"
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline text={strings.labels.signingIn} />;
    }

    switch (result.challengeName) {
      case strings.challenges.newPassword:
        return renderNewPasswordForm();
      default:
        return renderLoginForm();
    }
  };

  return <>{renderContent()}</>;
};
