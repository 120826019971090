import { PlayCircleOutline } from "@mui/icons-material";

type MuiIconType = typeof PlayCircleOutline;

export type Props = {
  Active: MuiIconType;
  Inactive: MuiIconType;
  active: boolean;
  color: string;
};

type Component = (props: Props) => JSX.Element;

export const NavStepperIcon: Component = ({
  Active,
  Inactive,
  active,
  color,
}) => {
  if (active) {
    return <Active sx={{ color: color }} />;
  } else {
    return <Inactive sx={{ color: color }} />;
  }
};
