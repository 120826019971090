import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from "react";
import { editModalStrings as strings } from "../../resources/strings/editModal";
import { Button } from "./Button";
import { IconButtonDelete, IconButtonEdit } from "./IconButton";
import Modal from "./Modal";
import { ModalImageUpload } from "./ModalImageUpload";

export enum EditModalControl {
  Text,
  Checkbox,
  ImageUpload,
}

type Field = {
  label: string;
  id: string;
  type?: EditModalControl;
  multiline?: boolean;
  onClick?: () => void;
  validationFunction?: (value: string) => string;
  disabled?: boolean;
};

type FieldGroup = {
  title: string;
  baseId: string;
  newInstance?: any;
  deletable?: boolean;
  fields: Array<Field>;
};

type FormControls = Array<Field | FieldGroup>;

interface Props<T = { [key: string]: any }> {
  title: string;
  controls: FormControls;
  data: T;
  setData?: Dispatch<SetStateAction<T>>;
  handleConfirm: (data: T) => void;
  handleDelete?: (data: T) => void;
  trigger?: JSX.Element;
}

export const EditModal = <T extends { [key: string]: any }>({
  title,
  controls,
  data: initData,
  handleConfirm,
  handleDelete,
  trigger = <IconButtonEdit entity="entity" />,
}: Props<T>) => {
  const [expanded, setExpanded] = useState(-1);
  const [data, setData] = useState(initData);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

  const updateField =
    (key: string) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const newValue = target.hasOwnProperty("checked")
        ? target.checked
        : target.value;

      setData((prevState) => {
        const objectPath = key.split(".");

        return {
          ...prevState,
          ...objectPath.reduceRight((newObject, item, depth) => {
            // Get old object at current depth in path
            const oldObject = objectPath
              .slice(0, depth)
              .reduce((obj, prop) => obj[prop], prevState) as any;

            // If item is a number, build and return an array
            if (!isNaN(Number(item))) {
              const targetIndex = Number(item);

              const array = Object.values(oldObject);
              array[targetIndex] = newObject;

              return array;
            }

            // Otherwise, build and return an object
            return {
              ...oldObject,
              [item]: depth === objectPath.length - 1 ? newValue : newObject,
            };
          }, prevState),
        };
      });
    };

  const addGroup = (basePath: string, newInstance: any) => {
    setData((prevState) => {
      const array = prevState[basePath];
      return { ...prevState, [basePath]: [...array, newInstance] };
    });
  };

  const deleteGroup = (basePath: string, index: number) => {
    setData((prevState) => {
      const array = prevState[basePath];
      array.splice(index, 1);

      return { ...prevState, [basePath]: array };
    });
  };

  const renderField = (field: Field, id?: string) => {
    const targetPath = id ?? field.id;

    const value = targetPath
      .split(".")
      .reduce((p, prop) => p[prop] ?? "", data) as any;

    const helperText = field.validationFunction?.(value);

    if (helperText && !errors[targetPath])
      setErrors((prev: any) => ({ ...prev, [targetPath]: true }));

    if (!helperText && errors[targetPath])
      setErrors((prev: any) => ({ ...prev, [targetPath]: false }));

    switch (field.type) {
      case EditModalControl.Checkbox:
        return (
          <FormControlLabel
            key={field.label}
            label={field.label}
            sx={[{ width: 1, justifyContent: "end" }]}
            control={
              <Checkbox
                checked={!!value}
                onChange={updateField(targetPath)}
                inputProps={{ "aria-label": field.label }}
              />
            }
          />
        );

      case EditModalControl.ImageUpload:
        return (
          <Fragment key={field.label}>
            <Typography
              align="left"
              variant={"subtitle1"}
              sx={[{ color: "primary.main", fontWeight: "bold" }]}
            >
              {field.label}
            </Typography>
            <ModalImageUpload
              imageSrc={value}
              fieldId={targetPath}
              updateField={updateField}
            />
          </Fragment>
        );

      case EditModalControl.Text:
      default:
        return (
          <Fragment key={field.label}>
            <Typography
              align="left"
              variant={"subtitle1"}
              sx={[{ color: "primary.main", fontWeight: "bold" }]}
            >
              {field.label}
            </Typography>
            <TextField
              aria-label={field.label}
              inputProps={{ title: field.label }}
              size="small"
              onChange={updateField(targetPath)}
              value={value}
              multiline={field.multiline}
              minRows={3}
              sx={[{ p: 2, minWidth: 1 }]}
              fullWidth
              helperText={helperText}
              error={!!helperText}
              disabled={field.disabled}
            />
          </Fragment>
        );
    }
  };

  const renderGroup = (control: FieldGroup) => {
    const array = data[control.baseId];

    return (
      <Fragment key={control.title}>
        {array.map((_subData: any, index: number) => (
          <Accordion
            key={control.title + index}
            expanded={index === expanded}
            onChange={() => setExpanded(index !== expanded ? index : -1)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="subtitle1"
                  sx={[{ color: "primary.main", fontWeight: "bold" }]}
                >
                  {control.title} {index + 1}
                </Typography>
                {control.deletable && (
                  <IconButtonDelete
                    entity={control.title}
                    onClick={() => deleteGroup(control.baseId, index)}
                    stopPropagation
                  />
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {control.fields.map((field) =>
                renderField(field, `${control.baseId}.${index}.${field.id}`)
              )}
            </AccordionDetails>
          </Accordion>
        ))}
        {control.newInstance && (
          <Button
            sx={{ m: 2 }}
            onClick={() => addGroup(control.baseId, control.newInstance)}
          >
            Add {control.title}
          </Button>
        )}
      </Fragment>
    );
  };

  return (
    <Modal
      title={title}
      trigger={trigger}
      buttons={{
        confirmText: strings.buttons.save,
        declineText: strings.buttons.cancel,
        deleteText: strings.buttons.delete,
        props: { color: "secondary", variant: "outlined" },
      }}
      handleConfirm={() => handleConfirm(data)}
      handleDelete={handleDelete ? () => handleDelete(data) : undefined}
      fullScreen
      validationError={Object.values(errors).includes(true)}
    >
      {controls.map((control) => {
        return isGroup(control) ? renderGroup(control) : renderField(control);
      })}
    </Modal>
  );
};

const isGroup = (field: FieldGroup | Field): field is FieldGroup => {
  return (field as FieldGroup).fields !== undefined;
};
