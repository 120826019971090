import { Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { TikTok } from "../../assets/TikTokIcon";
import { authStrings } from "../../resources/strings/auth";
import { urls } from "../../resources/urls";

const footerStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: "common.purple",
  minHeight: theme.spacing(8),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  px: 2,
  [theme.breakpoints.up("md")]: {
    px: 38.75,
  },
  zIndex: 2,
});

const iconGroupStyles: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
});

const iconStyles: SxProps<Theme> = (_theme) => ({
  backgroundColor: "white",
  color: "common.pink",
  ":hover": {
    backgroundColor: "white",
    transform: "scale(1.1)",
  },
});

const icons = [
  {
    Icon: Twitter,
    url: "https://twitter.com/DataLabScotland",
  },
  {
    Icon: YouTube,
    url: "https://www.youtube.com/channel/UCtdjx3YzkJdR494SSlEPoZw",
  },
  {
    Icon: Instagram,
    url: "https://www.instagram.com/thedatalabscotland",
  },
  {
    Icon: LinkedIn,
    url: "https://www.linkedin.com/company/3778330",
  },
  {
    Icon: TikTok,
    url: "https://www.tiktok.com/@thedatalabscotland",
  },
];

export const Footer = () => {
  return (
    <Box sx={footerStyles}>
      <Typography
        component={Link}
        target="_blank"
        href="https://thedatalab.com"
        sx={{ color: "white" }}
      >
        thedatalab.com
      </Typography>
      <Typography variant="caption">
        <a
          style={{ color: "white" }}
          href={urls.privacyPolicy}
          target="_blank"
          rel="noreferrer"
        >
          {authStrings.labels.privacyPolicy}
        </a>
        {" | "}
        <a
          style={{ color: "white" }}
          href={urls.termsAndConditions}
          target="_blank"
          rel="noreferrer"
        >
          {authStrings.labels.termsAndConditions}
        </a>
      </Typography>
      <Typography variant="caption">{authStrings.text.legal}</Typography>
      <Box sx={iconGroupStyles}>
        <Typography>Follow us on social:</Typography>
        {icons.map(({ url, Icon }) => (
          <IconButton
            key={url}
            target="_blank"
            href={url}
            size="medium"
            sx={iconStyles}
          >
            <Icon fontSize="small" />
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};
