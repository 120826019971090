import { Navigate } from "react-router-dom";
import { ShowIfAuthorised } from "../../components/authentication/ShowIfAuthorised";
import { paths } from "../../navigation/paths";
import { RouteProps as AppRouteProps, UserAccess } from "../../types";

export interface Props extends AppRouteProps {
  userAccess?: UserAccess;
  children: JSX.Element;
}
type Component = (props: Props) => JSX.Element;

export const PrivateRoute: Component = ({
  children,
  userAccess,
  permissions,
  user,
}) => {
  // the main app component will ensure push to login when user is logged out
  if (!user) {
    return <Navigate to={paths.auth.login} />;
  }
  if (!userAccess) return children;

  // now validate user has the relevant permission
  return (
    <>
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        {children}
      </ShowIfAuthorised>
    </>
  );
};
