import type { EventType } from "../components/general/types/Modify";
import {
  Control,
  Part,
  Section,
  Subsection,
  Suggestion,
  Survey,
} from "../types/documents";
import { SectionType } from "../types/documents/BasePart";
import { ActionTarget, ActionType } from "../types/enums/EditModalAction";
import { deepClone } from "./object-helpers";

const parsePath = (path: string) => {
  const [sectionId, subsectionId, partId] = path.split("/");
  return { sectionId, subsectionId, partId };
};

const findSection = (survey: Survey, sectionId: string) => {
  return survey.sections.find((section) => section.sectionId === sectionId);
};

const findSubsection = (
  survey: Survey,
  sectionId: string,
  subsectionId: string
) => {
  const section = findSection(survey, sectionId);
  if (!section) return;

  return section.subSections.find(
    (subsection) => subsection.subsectionId === subsectionId
  );
};

const findPart = (subsection: Subsection, partId: string) => {
  if (!subsection.parts) return;

  return subsection.parts.find((part) => part.partId === partId);
};

interface Ordered {
  order: number;
}
const sortOrdered = <T extends Ordered>(a: T, b: T) => a.order - b.order;

const changeControl = (state: Survey, e: EventType): Survey => {
  const { sectionId, subsectionId, partId } = parsePath(e.target.name ?? "");

  const targetControl = e.target.value as Control;

  const newState = deepClone(state);

  const subsection = findSubsection(newState, sectionId, subsectionId);
  if (!subsection) return state;

  const part = findPart(subsection, partId);
  const target = part ?? subsection;

  switch (e.target.actionType) {
    case ActionType.Add:
      target.controls.push(targetControl);
      break;

    case ActionType.Update:
      target.controls = target.controls.map((control) => {
        if (control.controlId !== targetControl.controlId) return control;
        return { ...control, ...targetControl };
      });
      break;

    case ActionType.Delete:
      target.controls = target.controls.filter(
        (control) => control.controlId !== targetControl.controlId
      );
      break;
  }

  return newState;
};

const changePart = (state: Survey, e: EventType): [Survey, string?] => {
  const { name: eventName = "" } = e.target;
  const { sectionId, subsectionId, partId } = parsePath(eventName);

  const updatedPart = e.target.value as Part;

  const newState = deepClone(state);
  let redirect;

  const section = findSection(newState, sectionId);
  if (!section) return [state];

  const subsection = findSubsection(newState, sectionId, subsectionId);
  if (!subsection) return [state];

  switch (e.target.actionType) {
    case ActionType.Add:
      subsection.parts
        ?.sort(sortOrdered)
        .splice(subsection.parts.length - 1, 0, updatedPart);

      subsection.parts = updatePartOrdering(subsection);
      newState.sections = updateStages(newState.sections);

      redirect = updatedPart.path;
      break;

    case ActionType.Update:
      subsection.parts = subsection.parts?.map((part) => {
        if (part.partId !== partId) return part;
        return { ...part, ...updatedPart };
      });

      redirect = updatedPart.path;
      break;

    case ActionType.Delete:
      subsection.parts = subsection.parts?.filter(
        (part) => part.partId !== partId
      );

      subsection.parts = updatePartOrdering(subsection);
      redirect = subsection.parts ? subsection.parts[0].path : subsection.path;
      break;
  }

  return [newState, redirect];
};

const changeSubsection = (state: Survey, e: EventType): [Survey, string?] => {
  const { name: eventName = "" } = e.target;
  const { sectionId, subsectionId } = parsePath(eventName);

  const updatedSubsection = e.target.value as Subsection;

  const newState = deepClone(state);
  let redirect;

  const section = findSection(newState, sectionId);
  if (!section) return [state];

  const subsection = findSubsection(newState, sectionId, subsectionId);
  if (!subsection && e.target.actionType !== ActionType.Add) return [state];

  switch (e.target.actionType) {
    case ActionType.Add:
      section.subSections
        .sort(sortOrdered)
        .splice(section.subSections.length - 1, 0, updatedSubsection);

      section.subSections = updateSubsectionOrdering(section);
      newState.sections = updateStages(newState.sections);

      redirect = updatedSubsection.parts
        ? updatedSubsection.parts[0].path
        : updatedSubsection.path;
      break;

    case ActionType.Update:
      section.subSections = section.subSections.map((subsection) => {
        if (subsection.subsectionId !== subsectionId) return subsection;

        const updatedParts = subsection.parts?.map((part) => ({
          ...part,
          path: part.path.replace(subsectionId, updatedSubsection.subsectionId),
        }));

        redirect = updatedParts ? updatedParts[0].path : updatedSubsection.path;
        return { ...subsection, ...updatedSubsection, parts: updatedParts };
      });
      break;

    case ActionType.Delete:
      section.subSections = section.subSections.filter(
        (subsection) => subsection.subsectionId !== subsectionId
      );

      section.subSections = updateSubsectionOrdering(section);
      redirect = section.subSections[0].path;
      break;
  }

  return [newState, redirect];
};

const changeSection = (state: Survey, e: EventType): [Survey, string?] => {
  const { sectionId } = parsePath(e.target.name ?? "");

  const targetSection = e.target.value as Section;

  const newState = deepClone(state);
  let redirect;

  switch (e.target.actionType) {
    case ActionType.Add:
      newState.sections
        .sort(sortOrdered)
        .splice(newState.sections.length - 1, 0, targetSection);

      newState.sections = updateStages(newState.sections);

      redirect = targetSection.subSections[0].path;
      break;

    case ActionType.Update:
      newState.sections = newState.sections.map((section) => {
        if (section.sectionId !== sectionId) return section;

        const newSection = { ...section, ...targetSection };

        newSection.subSections = newSection.subSections.map((subsection) => ({
          ...subsection,
          path: subsection.path.replace(sectionId, newSection.path),
          parts: subsection.parts?.map((part) => ({
            ...part,
            path: part.path.replace(sectionId, newSection.path),
          })),
        }));

        redirect = newSection.subSections[0].path;
        return newSection;
      });
      break;

    case ActionType.Delete:
      newState.sections = newState.sections
        .filter((section) => section.sectionId !== sectionId)
        .sort(sortOrdered);
      newState.sections = updateStages(newState.sections);

      redirect = newState.sections[0].subSections[0].path;
      break;
  }

  return [newState, redirect];
};

const changeSuggestion = (state: Survey, e: EventType): Survey => {
  const { sectionId, subsectionId, partId } = parsePath(e.target.name ?? "");

  const { index, ...newSuggestion } = e.target.value as Suggestion & {
    index: number;
  };

  const newState = deepClone(state);

  const subsection = findSubsection(newState, sectionId, subsectionId);
  if (!subsection) return state;

  const part = findPart(subsection, partId);
  const target = part ?? subsection;

  switch (e.target.actionType) {
    case ActionType.Add:
      target.suggestions.push(newSuggestion);
      break;

    case ActionType.Update:
      target.suggestions[index] = newSuggestion;
      break;

    case ActionType.Delete:
      target.suggestions.splice(index, 1);
      break;
  }

  return newState;
};

const updateStages = (sections: Section[]) => {
  return sections.map((section, sectionIndex) => {
    const newSubsections = section.subSections.map((subsection) => {
      let stage = sectionIndex * 2;
      if (subsection.type === SectionType.Feedback) stage++;

      return {
        ...subsection,
        stage,
        parts: subsection.parts?.map((part) => ({ ...part, stage })),
      };
    });

    return {
      ...section,
      order: sectionIndex,
      subSections: newSubsections,
    };
  });
};

const updateSubsectionOrdering = (section: Section) => {
  return section.subSections.map((subsection, index) => ({
    ...subsection,
    order: index,
    parts: updatePartOrdering(subsection),
  }));
};

const updatePartOrdering = (subsection: Subsection) => {
  if (!subsection.parts) return;

  return subsection.parts.map((part, index) => ({
    ...part,
    order: index,
  }));
};

export const changeState = (state: Survey, e: EventType): [Survey, string?] => {
  switch (e.target.editType) {
    case ActionTarget.Control:
      return [changeControl(state, e)];

    case ActionTarget.Part:
      return changePart(state, e);

    case ActionTarget.Subsection:
      return changeSubsection(state, e);

    case ActionTarget.Suggestion:
      return [changeSuggestion(state, e)];

    case ActionTarget.Section:
      return changeSection(state, e);

    default:
      return [state];
  }
};
