import {
  Card,
  CardContent,
  CardMedia,
  Link,
  SxProps,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { api } from "../../services/surveys.service";
import { SuggestionModal } from "../editModals";
import { ChangeHandler } from "./types/Modify";

export type ActionCardType = {
  title: string;
  description: string;
  image: {
    src: string;
    alt: string;
  };
  url: string;
};

type ActionCardProps = ActionCardType & {
  index: number;
  path?: string;
  editable?: boolean;
  handleChange?: ChangeHandler;
};

type Component = (props: ActionCardProps) => JSX.Element;

const cardStyles: SxProps = {
  backgroundColor: "transparent",
  px: 2,
};
const cardContentStyles: SxProps = {
  textAlign: "left",
  p: 0,
  m: 0,
  boxSizing: "border-box",
};
const cardMediaStyles: SxProps = {
  maxWidth: "100%",
  aspectRatio: "16/9",
  objectFit: "cover",
};

const cardHeadingStyles: SxProps = {
  color: "primary.main",
  fontWeight: "bold",
  pt: 1,
};

const cardDescriptionStyles: SxProps = {
  color: "text.secondary",
  mt: 2,
};

const ActionCard: Component = ({
  title,
  description,
  image,
  url,
  editable,
  index,
  path,
  handleChange,
}) => {
  const [currentImage, setCurrentImage] = useState<string>();
  const cancelToken = useCancelToken();

  const setImage = useCallback(
    async (id: string) => {
      if (!id) return;
      setCurrentImage(id);
      try {
        const url = await api.downloadImage(id, cancelToken);
        setCurrentImage(url);
      } catch (e) {
        logger.error(e);
      }
    },
    [cancelToken]
  );

  useEffect(() => {
    setImage(image.src);
  }, [image.src, setImage]);

  return (
    <Card
      role="figure"
      aria-label="action card"
      elevation={0}
      sx={[cardStyles]}
    >
      {editable && handleChange && (
        <SuggestionModal
          key={title || description}
          path={path}
          suggestion={{
            title: title ?? "",
            description: description ?? "",
            image: image?.src ?? "",
            url: url ?? "",
          }}
          index={index}
          handleChange={handleChange}
        />
      )}

      {currentImage && (
        <CardMedia
          component={"img"}
          image={currentImage}
          alt={image.alt}
          aria-label={image.alt}
          sx={[cardMediaStyles]}
        />
      )}
      <CardContent
        sx={[cardContentStyles]}
        aria-labelledby={title ? "cardtitle" : ""}
      >
        {title && (
          <Link
            href={url ?? ""}
            underline="hover"
            target="_blank"
            rel="noreferrer"
          >
            <Typography
              variant="subtitle1"
              sx={[cardHeadingStyles]}
              id="cardtitle"
            >
              {title}
            </Typography>
          </Link>
        )}
        <Typography
          variant="body2"
          id="cardcontent"
          sx={[cardDescriptionStyles]}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ActionCard;
