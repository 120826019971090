import { Box } from "@mui/material";

import type { SxProps, Theme } from "@mui/material";

const rootStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: "common.white",
  color: "text.secondary",
  py: 2.5,
  px: 3.75,
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("md")]: {
    overflowY: "auto",
  },
});

interface Props {
  children: JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>;
}
type Component = (props: Props) => JSX.Element;

export const PageLayoutBox: Component = ({ children, sx }) => {
  return (
    <Box sx={[rootStyles, ...(Array.isArray(sx) ? sx : [sx])]}>{children}</Box>
  );
};
