import type { ButtonProps } from "@mui/material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { confirmDeleteModalStrings } from "../../resources/strings/editModal";
import { Button } from "./Button";

export type ModalProps = {
  title: string;
  buttons: {
    confirmText: string;
    declineText: string;
    deleteText?: string;
    props: ButtonProps;
  };
  trigger: JSX.Element;
  handleConfirm: () => void;
  handleDelete?: () => void;
  description?: string;
  children?: React.ReactNode;
  fullScreen?: boolean;
  validationError?: boolean;
};

type Component = (props: ModalProps) => JSX.Element;

const Modal: Component = ({
  title,
  description,
  buttons,
  trigger,
  handleConfirm,
  handleDelete,
  children,
  fullScreen = false,
  validationError = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);
  const handleConfirmDeleteModalClose = () => setConfirmDeleteModalOpen(false);
  const handleOpen = () => setModalOpen(true);
  const onConfirm = () => {
    setModalOpen(false);
    handleConfirm();
  };

  const onDelete = () => {
    setConfirmDeleteModalOpen(true);
  };

  const onConfirmDelete = () => {
    setConfirmDeleteModalOpen(false);
    setModalOpen(false);
    handleDelete?.();
  };

  const dialogStyles: SxProps<Theme> = (theme) => {
    if (fullScreen) {
      return {
        height: `90%`,
        width: `90%`,
        [theme.breakpoints.up("md")]: {
          // these are the same values as the overall theme to maintain consistent spacing
          px: 38.75,
          py: 6.25,
          overflowY: "auto",
        },
        textAlign: "center",
        display: "flex",
      };
    } else {
      return { textAlign: "center" };
    }
  };

  const confirmDeleteDialog = (
    <Dialog
      open={confirmDeleteModalOpen}
      onClose={handleConfirmDeleteModalClose}
      aria-labelledby="confirm-delete-modal-title"
      aria-describedby="confirm-delete-modal-content"
      key={"confirmDeleteModal"}
    >
      <DialogTitle
        id="confirm-delete-modal-title"
        sx={[{ fontWeight: "bold", color: "primary.main" }]}
      >
        {confirmDeleteModalStrings.title}
      </DialogTitle>
      <DialogContent
        sx={[
          {
            p: 3.75,
            width: 1,
            justifyItems: "center",
            mx: "auto",
          },
        ]}
      >
        <Typography
          variant="body2"
          color={"text.secondary"}
          id="confirm-delete-modal-content"
        >
          {confirmDeleteModalStrings.description}
        </Typography>
      </DialogContent>
      <DialogActions sx={[{ m: 2, justifyContent: "center" }]}>
        <Button
          aria-label={confirmDeleteModalStrings.declineText}
          label={confirmDeleteModalStrings.declineText}
          key={`confirm-delete-${confirmDeleteModalStrings.declineText}`}
          onClick={handleConfirmDeleteModalClose}
          color="secondary"
          variant="outlined"
        />
        <Button
          aria-label={confirmDeleteModalStrings.confirmText}
          label={confirmDeleteModalStrings.confirmText}
          key={`confirm-delete-${confirmDeleteModalStrings.confirmText}`}
          onClick={onConfirmDelete}
          autoFocus
          color="secondary"
          variant="outlined"
        />
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-content"
        key={"modal"}
        fullScreen={fullScreen}
        PaperProps={{
          sx: dialogStyles,
        }}
      >
        <DialogTitle
          id="modal-title"
          sx={[{ fontWeight: "bold", color: "primary.main" }]}
        >
          {title}
        </DialogTitle>
        <DialogContent
          sx={[{ p: 3.75, width: 1, justifyItems: "center", mx: "auto" }]}
        >
          <Typography
            variant="body2"
            color={"text.secondary"}
            id="modal-content"
          >
            {description}
          </Typography>
          {children}
        </DialogContent>
        <DialogActions sx={[{ m: 2, justifyContent: "center" }]}>
          {handleDelete && (
            <>
              {confirmDeleteDialog}
              <Button
                aria-label={buttons.deleteText}
                label={buttons.deleteText}
                key={buttons.deleteText}
                onClick={onDelete}
                color="error"
              />
              <Box sx={{ flexGrow: 1 }} />
            </>
          )}
          <Button
            aria-label={buttons.declineText}
            label={buttons.declineText}
            key={buttons.declineText}
            onClick={handleClose}
            {...buttons.props}
          />
          <Button
            aria-label={buttons.confirmText}
            label={buttons.confirmText}
            key={buttons.confirmText}
            onClick={onConfirm}
            disabled={validationError}
            autoFocus
            {...buttons.props}
          />
        </DialogActions>
      </Dialog>
      <Box onClick={handleOpen} sx={[{ display: "inline-flex" }]}>
        {trigger}
      </Box>
    </>
  );
};

export default Modal;
