import { validateNotEmpty } from "../../helpers/validation-helpers";
import { endPanelModalStrings as strings } from "../../resources/strings/editModal";
import { EndControl } from "../../types/documents/Control";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { EditModal } from "../general/EditModal";
import { ChangeHandler } from "../general/types/Modify";

interface Props {
  path: string;
  endPanel: EndControl;
  handleChange: ChangeHandler;
}

type Component = (props: Props) => JSX.Element;

export const EndPanelModal: Component = ({
  path,
  endPanel: initEndPanel,
  handleChange,
}) => {
  const handleEdit = (endPanel: EndControl) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Control,
        actionType: ActionType.Update,
        value: endPanel,
      },
    });
  };

  return (
    <EditModal<EndControl>
      title={strings.modalTitle}
      handleConfirm={handleEdit}
      controls={[
        {
          label: strings.heading,
          id: "heading",
        },
        {
          label: strings.body,
          id: "body",
          multiline: true,
        },
        {
          label: strings.emailButtonLabel,
          id: "emailButtonLabel",
          validationFunction: validateNotEmpty,
        },
        {
          label: strings.downloadButtonLabel,
          id: "downloadButtonLabel",
          validationFunction: validateNotEmpty,
        },
        {
          label: strings.successMessage,
          id: "emailSuccess",
          multiline: true,
        },
      ]}
      data={initEndPanel}
    />
  );
};
