import { useIsAuthorised } from "../../hooks/authentication/useIsAuthorised";
import { paths } from "../../navigation/paths";
import { appStrings as strings } from "../../resources/strings/app";
import type { Permissions } from "../../types";
import { Entities } from "../../types/enums/Entities";
import { Permissions as PermissionsEnum } from "../../types/enums/Permissions";
import type { CardItem } from "./CardList";
import { CardList } from "./CardList";
import { Header } from "./Header";

export interface Props {
  permissions: Permissions | null;
}
type Component = (props: Props) => JSX.Element;

export const AdministrationContent: Component = ({ permissions }) => {
  const canViewAdminPanel = useIsAuthorised(
    permissions,
    Entities.Surveys,
    PermissionsEnum.Read
  );
  const canViewResponses = useIsAuthorised(
    permissions,
    Entities.Responses,
    PermissionsEnum.Read
  );
  const canViewUsers = useIsAuthorised(
    permissions,
    Entities.Users,
    PermissionsEnum.Read
  );
  const canViewFeedback = useIsAuthorised(
    permissions,
    Entities.Feedback,
    PermissionsEnum.Read
  );

  const cards: CardItem[] = [];

  if (canViewAdminPanel) {
    cards.push({
      id: "adminPanel",
      text: strings.labels.adminPanel,
      path: paths.surveys.adminPanel,
    });
  }

  if (canViewResponses) {
    cards.push({
      id: "responses",
      text: strings.labels.surveyResponses,
      path: paths.surveyResponses.list,
    });
  }

  if (canViewUsers) {
    cards.push({
      id: "users",
      text: strings.labels.users,
      path: paths.users.list,
    });
  }

  if (canViewFeedback) {
    cards.push({
      id: "feedback",
      text: strings.labels.feedback,
      path: paths.userFeedback.list,
    });
  }

  return (
    <>
      <Header sectionHeader={strings.headers.administration} />
      <CardList items={cards} />
    </>
  );
};
