import { List } from "@mui/material";
import { SurveyResponseStatus } from "../../types/documents/SurveyResponse";
import { Card } from "./Card";

export type CardItem = {
  id: string;
  text: string;
  path: string;
  status?: SurveyResponseStatus;
};

interface Props {
  items: Array<CardItem>;
}
type Component = (props: Props) => JSX.Element;

export const CardList: Component = ({ items }) => {
  return (
    <List
      disablePadding
      sx={[{ display: "flex", flexDirection: "column", gap: 3 }]}
      aria-label="list"
    >
      {items.map(({ id, ...item }) => (
        <Card key={id} {...item} />
      ))}
    </List>
  );
};
