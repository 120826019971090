import { validateNotEmpty } from "../../helpers/validation-helpers";
import { videoModalStrings as strings } from "../../resources/strings/editModal";
import { ControlType, VideoControl } from "../../types/documents/Control";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { Button } from "../general/Button";
import { EditModal } from "../general/EditModal";
import { IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

interface Props {
  path: string;
  controlId: string;
  video?: VideoControl;
  addNew?: boolean;
  handleChange: ChangeHandler;
}

type Component = (props: Props) => JSX.Element;

export const VideoModal: Component = ({
  path,
  controlId,
  video: initVideo = {
    controlId,
    type: ControlType.Video,
    videoId: "",
  },
  handleChange,
  addNew,
}) => {
  const title = addNew ? strings.modalTitle.add : strings.modalTitle.edit;

  const trigger = addNew ? (
    <Button>{strings.modalTitle.add}</Button>
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const handleEdit = (video: VideoControl) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Control,
        actionType: addNew ? ActionType.Add : ActionType.Update,
        value: video,
      },
    });
  };

  const handleDelete = (video: VideoControl) => {
    handleChange({
      target: {
        name: path,
        editType: ActionTarget.Control,
        actionType: ActionType.Delete,
        value: { controlId: video.controlId },
      },
    });
  };

  return (
    <EditModal<VideoControl>
      title={title}
      handleConfirm={handleEdit}
      handleDelete={!addNew ? handleDelete : undefined}
      controls={[
        {
          label: strings.videoId,
          id: "videoId",
          validationFunction: validateNotEmpty,
        },
      ]}
      data={initVideo}
      trigger={trigger}
    />
  );
};
