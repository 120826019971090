import {
  ChevronLeft as ArrowBackIcon,
  ChevronRight as ArrowForwardIcon,
} from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { paths } from "../../navigation/paths";
import { landingPageStrings as strings } from "../../resources/strings/landingPage";
import { api } from "../../services/surveyresponses.service";
import { SurveyResponse } from "../../types/documents/SurveyResponse";
import { ShowIf } from "../authentication/ShowIfAuthorised";
import { Button } from "../general/Button";
import { Header } from "../general/Header";
import { Loader } from "../general/Loader";
import { PageLayoutBox } from "../layout/PageLayoutBox";

interface Props {}
type Component = (props: Props) => JSX.Element;

const { headers, privacyAgreement, actions } = strings;

const agreementContainerStyles: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up("md")]: {
    height: "65%",
    overflowY: "auto",
    p: 3,
    my: 1,
  },
  textAlign: "left",
});

const actionsContainerStyles: SxProps<Theme> = {
  height: "10%",
  justifyContent: "space-between",
  alignItems: "flex-end",
};

type Content = {
  key: string;
  title: string;
  content: { text: string; url?: string }[];
};

const dataAgreementContent: Content[] = [
  {
    key: "section 1",
    title: privacyAgreement.section1.title,
    content: [
      privacyAgreement.section1.part1,
      privacyAgreement.section1.part2,
      privacyAgreement.section1.part3,
      privacyAgreement.section1.part4,
      privacyAgreement.section1.part5,
      privacyAgreement.section1.part6,
    ],
  },
];

const AgreementContent = () => (
  <Box
    component="aside"
    aria-label="agreement content"
    sx={[agreementContainerStyles]}
  >
    {dataAgreementContent.map((section) => (
      <Fragment key={section.key}>
        <Typography
          aria-label={section.key}
          variant="subtitle2"
          color="primary"
          fontWeight="bold"
          my={2}
        >
          {section.title}
        </Typography>
        <div aria-label={`${section.key} body`} role="region">
          {section.content.map((content, index) => (
            <Fragment key={`${section.key}-content-${index}`}>
              <Typography variant="body2" my={2}>
                {content.url ? (
                  <a href={content.url} target="_blank" rel="noreferrer">
                    {content.text}
                  </a>
                ) : (
                  <>{content.text}</>
                )}
              </Typography>
            </Fragment>
          ))}
        </div>
      </Fragment>
    ))}
  </Box>
);

interface ActionsProps {
  onClickContinue: MouseEventHandler;
}

const Actions = ({ onClickContinue }: ActionsProps) => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const handleAgreeChange = () => setAgreed((prev) => !prev);
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const checkboxContent = (
    <FormControlLabel
      control={
        <Checkbox
          aria-label="agreed to data and privacy statement"
          color="secondary"
          value={agreed}
          onChange={handleAgreeChange}
        />
      }
      label={actions.agreeToStatement}
      componentsProps={{
        typography: {
          textAlign: "left",
        },
      }}
    />
  );
  return (
    <Grid container sx={[actionsContainerStyles]} gap={2}>
      <ShowIf show={!mdUp}>
        <Grid item>{checkboxContent}</Grid>
      </ShowIf>
      <Grid item>
        <Button
          color="secondary"
          startIcon={<ArrowBackIcon />}
          aria-label="back"
          label={actions.back}
          onClick={() => navigate(paths.landingPage)}
        />
      </Grid>
      <Grid item>
        <ShowIf show={mdUp}>{checkboxContent}</ShowIf>
        <Button
          endIcon={<ArrowForwardIcon />}
          disabled={!agreed}
          aria-label="agree and continue"
          label={actions.agreeAndContinue}
          onClick={onClickContinue}
        />
      </Grid>
    </Grid>
  );
};

export const DataAndPrivacyAgreement: Component = () => {
  const cancelToken = useCancelToken();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onClickContinue = async () => {
    setLoading(true);
    await api.create({} as SurveyResponse, cancelToken);

    navigate(paths.surveyResponses.answer);
  };

  return (
    <PageLayoutBox
      sx={[
        (theme) => ({
          [theme.breakpoints.up("md")]: {
            overflowY: "initial",
          },
        }),
      ]}
    >
      <Loader active={loading}>
        <>
          <Box height="20%">
            <Header
              sectionHeader={headers.dataAndPrivacy}
              pageHeader={headers.dataAndPrivacyAgreement}
            />
          </Box>
          <AgreementContent />
          <Actions onClickContinue={onClickContinue} />
        </>
      </Loader>
    </PageLayoutBox>
  );
};
