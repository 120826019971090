export type SupportOrFeedback = {
  area: string;
  details: string;
};

export type SendSupportOrFeedbackResponse = {
  result: SupportOrFeedback;
  id: string;
};

export enum Area {
  Feedback = "feedback",
  Support = "support",
}
