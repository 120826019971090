import { Typography } from "@mui/material";
import type { Header as THeader } from "../types/Modify";

interface Props {
  config: Omit<THeader, "controltype">;
}
type Component = (props: Props) => JSX.Element;

export const Header: Component = ({ config }) => {
  const { text } = config;
  return <Typography {...config}>{text}</Typography>;
};
