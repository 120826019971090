export const supportOrFeedbackStrings = {
  shortTitle: "Help",
  longTitle: "Help",
  pageDescription:
    "Please fill in the form below to send your request to The Data Lab.",
  area: "Area",
  details: "Details",
  send: "Send",
  back: "Back",
  feedback: "Feedback",
  support: "Support",
  ariaLabels: {
    shortTitle: "help",
    area: "area",
    details: "details",
    send: "send",
    back: "back to landing page",
  },
};
