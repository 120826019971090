import {
  validateId,
  validateLength,
  validateNotEmpty,
} from "../../helpers/validation-helpers";
import { pageModalStrings as strings } from "../../resources/strings/editModal";
import { SectionType } from "../../types/documents/BasePart";
import { ActionTarget, ActionType } from "../../types/enums/EditModalAction";
import { EditModal } from "../general/EditModal";
import { IconButtonAdd, IconButtonEdit } from "../general/IconButton";
import { ChangeHandler } from "../general/types/Modify";

export type PageFields = {
  name: string;
  description: string;
  pageId: string;
};

interface Props {
  path: string;
  handleChange: ChangeHandler;
  page?: PageFields;
  addNew?: boolean;
  type?: SectionType;
  isSubsection?: boolean;
  listOfPaths: string[];
}

type Component = (props: Props) => JSX.Element;

export const PageModal: Component = ({
  path,
  page: initPage = {
    name: "",
    description: "",
    pageId: "",
  },
  handleChange,
  type,
  isSubsection,
  addNew,
  listOfPaths,
}) => {
  const title = addNew ? strings.modalTitle.add : strings.modalTitle.edit;
  const trigger = addNew ? (
    <IconButtonAdd entity={strings.entity} />
  ) : (
    <IconButtonEdit entity={strings.entity} />
  );

  const partTemplate = ({ name, pageId, description }: PageFields) => ({
    name,
    path: `${path}/${pageId}`,
    description,
    suggestions: [],
    controls: [],
    order: -1,
    stage: -1,
    type: SectionType.Question,
    ...(isSubsection ? { subsectionId: pageId } : { partId: pageId }),
  });

  const handleAdd = (page: PageFields) => {
    handleChange({
      target: {
        name: path,
        editType: isSubsection ? ActionTarget.Subsection : ActionTarget.Part,
        actionType: ActionType.Add,
        value: partTemplate(page),
      },
    });
  };

  const handleEdit = ({ name, description, pageId }: PageFields) => {
    const pageUpdate = {
      name,
      description,
      path: path.replace(initPage.pageId, pageId),
      ...(isSubsection ? { subsectionId: pageId } : { partId: pageId }),
    };

    handleChange({
      target: {
        name: path,
        editType: isSubsection ? ActionTarget.Subsection : ActionTarget.Part,
        actionType: ActionType.Update,
        value: pageUpdate,
      },
    });
  };

  const handleDelete = (page: PageFields) => {
    handleChange({
      target: {
        name: path,
        editType: isSubsection ? ActionTarget.Subsection : ActionTarget.Part,
        actionType: ActionType.Delete,
        value: page,
      },
    });
  };

  return (
    <EditModal<PageFields>
      title={title}
      handleConfirm={addNew ? handleAdd : handleEdit}
      controls={[
        {
          label: strings.title,
          id: "name",
          validationFunction: (value) =>
            validateNotEmpty(value) || validateLength(255)(value),
        },
        {
          label: strings.description,
          id: "description",
          multiline: true,
          validationFunction: validateLength(255),
        },
        {
          label: strings.id,
          id: "pageId",
          validationFunction: addNew
            ? (id: string) =>
                validateId(id, listOfPaths) || validateLength(80)(id)
            : undefined,
          disabled: !addNew,
        },
      ]}
      handleDelete={
        !addNew && type === SectionType.Question ? handleDelete : undefined
      }
      data={initPage}
      trigger={trigger}
    />
  );
};
