export const userFeedbackStrings = {
  headers: { userFeedback: "User Feedback" },
  shortTitle: "User Feedback",
  longTitle: "User Feedback",
  pageDescription: "Please submit feedback on your Self-Assessment experience.",
  contact: {
    question:
      "Please click here if you would like to talk with The Data Lab about your data journey. If so, we will contact you via email to set up a call at a convenient time for you.",
    yesString: "Yes",
    noString: "No",
  },
  rating: {
    question: "How did you find this assessment?",
    useful: "Useful",
    thoughtProvoking: "Thought provoking",
    unhelpful: "Unhelpful",
  },
  furtherComments: "Any further comments",
  submitButtonText: "Submit Feedback",
  ariaLabels: {
    container: "user feedback container",
    commentsField: "any further comments",
    submitButton: "submit feedback",
    user: "user",
    created: "created",
    back: "back",
    userFeedbackTable: "user feedback table",
  },
  labels: {
    wantToBeContacted: "Consent to contact",
    user: "User Email",
    created: "Created",
    rating: "Rating",
    view: "View",
    from: "From",
    to: "To",
    reset: "Reset",
    search: "Search",
    back: "Back",
  },
};
