import { Grid, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../components/general/Loader";
import { ModifyComponent } from "../../components/general/ModifyComponent";
import type {
  ChangeHandler,
  ComponentConfiguration,
  EventType,
  Mode,
} from "../../components/general/types/Modify";
import { ModifyContextProvider } from "../../context/ModifyContext";
import { logger } from "../../helpers/log-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { HooksLogger } from "../../hooks/hooks-logger";
import { RouteProps } from "../../types";
import type {
  CreateFunction,
  ReadFunction,
  UpdateFunction,
} from "../../types/API";
import validate from "../../validation";

const hooksLogger = new HooksLogger("ModifyContainer/submitData");

export interface Props<D extends object> extends RouteProps {
  children?: JSX.Element;
  api: {
    read: ReadFunction<D>;
    update: UpdateFunction<D>;
    create: CreateFunction<D>;
  };
  initialData: D;
  componentConfiguration: ComponentConfiguration<D>;
  mode: Mode;
  constraints: object;
  redirectPath: string | ((id: string) => string);
  loading?: boolean;
  id?: string;
  changeState?: (prevState: D, event: EventType) => D;
  parentControlled?: boolean;
}

export const ModifyContainer = <D extends { [key: string]: unknown }>({
  children,
  api,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  permissions,
  constraints,
  redirectPath,
  loading: propsLoading = false,
  id: inputId,
  changeState: propsChangeState,
  parentControlled,
}: Props<D>) => {
  const navigate = useNavigate();
  const { id = inputId, childId } = useParams<{
    id?: string;
    childId?: string;
  }>();
  const cancelToken = useCancelToken();
  const [mode, setMode] = useState<Mode>(() => inputMode);

  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);
  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");

  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [success, setSuccess] = useState(() => false);
  const [message, setMessage] = useState("");
  const [currentFeedbackTitle, setCurrentFeedbackTitle] = useState("");

  const [redirectId, setRedirectId] = useState("");

  useEffect(() => {
    parentControlled && setFormData(_initialData);
  }, [_initialData, parentControlled]);

  useEffect(() => {
    const query = async (inputId: string) => {
      hooksLogger.request("Getting item data");

      setLoading(true);
      try {
        const { item } = await api.read(inputId, childId, cancelToken);
        setFormData(item);
        setInitialData(item);
        setLoading(false);
        hooksLogger.success(item);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = logger.error(e);
        setError(error);
        setLoading(false);
        hooksLogger.error(error);
      }
    };

    // pass the ID into the query function to avoid type issues when using
    // `id` inside query since technically it can be undefined
    // (should only be undefined in create mode when this query isnt required)
    if (id && inputMode !== "create") query(id);
  }, [id, childId, inputMode, cancelToken, api]);

  const handleModeSwitch = useCallback(() => {
    switch (mode) {
      case "update":
        setMode("view");
        break;
      case "view":
        setMode("update");
        break;
      case "create":
        let path = "";
        if (typeof redirectPath === "string") {
          path = redirectPath;
        } else {
          path = redirectPath(redirectId);
        }

        if (success && path) navigate(path);
        break;
      default:
        break;
    }
  }, [mode, navigate, redirectPath, redirectId, success]);

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  useEffect(() => {
    if (success) {
      handleModeSwitch();
      setMessage("Data submitted successfully");
      setSuccess(false);
    }
  }, [success, handleModeSwitch]);

  const changeState = useCallback(
    (prev: D, e: EventType): D => {
      if (propsChangeState) return propsChangeState(prev, e);

      const { target } = e;
      const { name, value, checked } = target;
      if (!name) return prev;
      return {
        ...prev,
        [name]: target.hasOwnProperty("checked") ? checked : value,
      };
    },
    [propsChangeState]
  );

  const handleChange: ChangeHandler = useCallback(
    (e) => {
      setFormData((prev) => changeState(prev, e));
    },
    [changeState]
  );

  const updateData = async () => {
    hooksLogger.request("Submitting form data");

    setLoading(true);
    setValidationResults(null);
    try {
      const { id } = await api.update(formData, cancelToken);
      setRedirectId(id);
      setSuccess(true);
      setLoading(false);
      hooksLogger.success();
    } catch (e) {
      const error = logger.error(e);
      setError(error);
      setLoading(false);
      hooksLogger.error(error);
    }
  };

  const createData = async () => {
    hooksLogger.request("Submitting form data");

    setLoading(true);
    setValidationResults(null);
    try {
      Object.keys(formData).forEach(
        (k) => formData[k] === "" && delete formData[k]
      );
      const { id } = await api.create(formData, cancelToken);
      setRedirectId(id);
      setSuccess(true);
      setLoading(false);
      hooksLogger.success();
    } catch (e) {
      const error = logger.error(e);
      setError(error);
      setLoading(false);
      hooksLogger.error(error);
    }
  };

  const validateForm = () => {
    const results = validate(formData, constraints) ?? {};
    const isValid = !Object.keys(results).length;
    if (!isValid) {
      setValidationResults(results);
      return;
    }
    if (mode === "create") {
      createData();
    } else {
      updateData();
    }
  };

  const renderChildren = () => {
    if (!children) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const isLoading = [loading, propsLoading].some((l) => l);

  return (
    <ModifyContextProvider value={{ handleModeSwitch }}>
      <Loader active={isLoading}>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={!!message}
            onClose={() => setMessage("")}
            autoHideDuration={6000}
            message={message}
          />
          <ModifyComponent<D>
            permissions={permissions}
            mode={mode}
            handleModeSwitch={handleModeSwitch}
            validateForm={validateForm}
            data={formData}
            currentFeedbackTitle={currentFeedbackTitle}
            handleChange={handleChange}
            handleReset={handleReset}
            handleCurrentFeedback={setCurrentFeedbackTitle}
            componentConfiguration={componentConfiguration}
            loading={isLoading}
            setFormData={setFormData}
            validationResults={validationResults}
          />
          {renderChildren()}
        </div>
      </Loader>
    </ModifyContextProvider>
  );
};
