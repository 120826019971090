import { validationStrings } from "../resources/strings/editModal";

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!urlPattern.test(urlString);
};

export const validateId = (id: string, listOfPaths: string[]) => {
  if (!id) return validationStrings.required;
  if (id && listOfPaths.includes(id))
    return validationStrings.unique + listOfPaths.join(", ");
  return "";
};

export const validateNotEmpty = (value: string) =>
  !value ? validationStrings.required : "";

export const validateLength = (max: number) => (value: string) => {
  if (value.length > max) return validationStrings.length(max);
  return "";
};
