import { useState, useEffect } from "react";
import type { HubCallback } from "../../libs/amplify";
import { Hub } from "../../libs/amplify";
import { getIdToken } from "../../libs/auth";
import { CognitoUser } from "../../types";
import { HooksLogger } from "../hooks-logger";

const logger = new HooksLogger("useAuthUser.ts");

type IdToken = { [key: string]: any };

const buildAuthUser = (userDetails: IdToken): CognitoUser => ({
  username: userDetails["cognito:username"],
  roleId: userDetails["custom:roleId"],
  email: userDetails["email"],
  userId: userDetails["sub"],
  firstName: userDetails["given_name"],
  surname: userDetails["family_name"],
});

export const useAuthUser = () => {
  const [user, setUser] = useState<null | CognitoUser>(null);
  const [checkedAuth, setCheckedAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    const callback: HubCallback = (authEvent) => {
      switch (authEvent.payload.event) {
        case "signIn":
          let { attributes = {} } = authEvent.payload.data;
          const { username, challengeParam } = authEvent.payload.data;
          // if a challenge is present, the attributes are nested inside
          // that object instead of the payload data directly
          if (challengeParam?.userAttributes) {
            attributes = challengeParam.userAttributes;
          }

          const authUser = buildAuthUser({
            ...attributes,
            "cognito:username": username,
          });
          if (mounted) setUser(authUser);
          break;
        case "signOut":
          if (mounted) setUser(null);
          break;
      }
    };

    Hub.listen("auth", callback);

    return () => {
      mounted = false;
      Hub.remove("auth", callback);
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    logger.info("Calling auth user");
    const query = async () => {
      logger.info("Getting user");
      if (mounted) setLoading(true);

      try {
        const userDetails = await getIdToken();
        const authUser = buildAuthUser(userDetails);
        logger.success(userDetails);

        if (mounted) setUser(authUser);
      } catch (e) {}
      if (mounted) {
        setCheckedAuth(true);
        setLoading(false);
      }
    };
    query();

    return () => {
      mounted = false;
    };
  }, []);

  return { user, checkedAuth, loading };
};
