import { useCallback, useState } from "react";
import { ChangeHandler } from "../../components/general/types/Modify";

export const useForm = <T extends object>(formData: T) => {
  const [data, setData] = useState<T>(() => formData);

  const handleChange = useCallback<ChangeHandler>((e) => {
    const { name, value } = e.target;
    if (!name) return;

    setData((currentData) => ({
      ...currentData,
      [name]: value,
    }));
  }, []);

  const handleReset = useCallback(() => {
    setData(() => formData);
  }, [formData]);

  return [data, handleChange, handleReset] as const;
};
