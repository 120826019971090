import { api } from "../../services/surveyresponses.service";
import { SurveyHistory } from "../../types/documents/SurveyResponse";
import { useQuery } from "../general/useQuery";

const body = { size: 2 };

const defaultSurveyHistory: typeof api.userHistory = async () => ({
  items: [],
  totalCount: 0,
});

export const useSurveyHistory = (canViewHistory: boolean) =>
  useQuery<SurveyHistory, typeof body>(
    [],
    canViewHistory ? api.userHistory : defaultSurveyHistory,
    body
  );
